import * as React from 'react';
import classnames from 'classnames';
import './styles.scss';

type Props = {
  children?: React.ReactNode,
  iconLeft?: string,
  iconRight?: string,
  label?: string,
  type?: string,
}

export const Field = (props: Props) => {
  return (
  	<div className={ classnames('field-wrapper', props.type) }>
  		{ props.iconLeft && ( <i className={classnames('field-wrapper__onLeft', 'lnr', 'lnr-' + props.iconLeft)}></i> )}
      { props.label && ( <label>{props.label}</label>)}
  		{ props.children }
  		{ props.iconRight && ( <i className="field-wrapper__onRight lnr lnr-magnifier"></i> )}
    </div>
  );
};

export default Field;

import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { Action, State as AppState } from 'stores';
import Web3Service from 'services/web3';
import { ITask } from 'services/pheme';
import * as LocalStorageService from 'services/local-storage';
import { modifyTask } from '@pheme-kit/core/lib/task';
import { createSelector } from 'reselect';
import shallowEqual from 'fbjs/lib/shallowEqual';

export type TransactionState = 'SUBMITTED' | 'PROCESSING' | 'FAILED' | 'REJECTED' | 'PROCESSED';
export type TransactionType =
  | 'REGISTER_HANDLE'
  | 'UPDATE_PROFILE'
  | 'PUBLISH_POST'
  | 'UPDATE_POST'
  | 'UNPUBLISH_POST'
  | 'ENDORSE_CONTENT';

interface TransactionProps {
  type: TransactionType;
  path: string;
}

export type Transaction = TransactionProps & {
  state: TransactionState;
  txHash: string;
  uuid: string;
  createdAt: number;
};

export interface Notification {
  id: string;
  transaction: Transaction;
}

const TRANSACTION_START_LISTENING = 'transaction/startListening';
const TRANSACTION_TRACK = 'transaction/track';
export const TRANSACTION_UPDATE = 'transaction/update';
const TRANSACTION_LOAD = 'transaction/load';
const TRANSACTION_TOGGLE = 'transaction/toggle';
const TRANSACTION_DISMISS_NOTIFICATION = 'transaction/dismissNotification';

interface IndexedTranscations { [uuid: string]: Transaction }
interface IndexedNotifications { [id: string]: Notification }

export interface State {
  identifier: string;
  isListening: boolean;
  isExpanded: boolean;
  notifications: IndexedNotifications;
  latestNotificationId: string;
  archivedUuids: string[];
  queue: string[];
  transactions: IndexedTranscations;
}

const getDefaultState = (): State => ({
  identifier: '',
  isListening: false,
  isExpanded: false,
  archivedUuids: [],
  queue: [],
  notifications: {},
  latestNotificationId: '',
  transactions: {},
});

const indexTransactions = (transactions: Transaction[]) =>
  transactions.reduce((index, transaction) => ({ ...index, [transaction.uuid]: transaction }), {});

const generateLocalStorageKey = (identifier: string) => `transactions:${identifier}`;

const writeToStorage = (identifier: string, transactions: Transaction[]) =>
  LocalStorageService.set(generateLocalStorageKey(identifier), transactions);

const readFromStorage = (identifier: string): Transaction[] => {
  try {
    const transactions: Transaction[] = LocalStorageService.get(
      generateLocalStorageKey(identifier)
    );
    transactions.sort((a, b) => a.createdAt - b.createdAt);
    return transactions;
  } catch (e) {
    return [];
  }
};

const loadState = (identifier: string): Partial<State> => {
  const rejectStates = ['FAILED', 'REJECTED', 'PROCESSED'] as TransactionState[];
  const rejectBefore = moment()
    .subtract(2, 'weeks')
    .valueOf();

  const transactions = readFromStorage(identifier).filter(
    ({ createdAt, state }) => !(rejectStates.includes(state) && createdAt < rejectBefore)
  );

  const queue = transactions.map((transaction) => transaction.uuid);

  return { transactions: indexTransactions(transactions), queue };
};

const persistState = (identifier: string, state: State): State => {
  writeToStorage(identifier, Object.keys(state.transactions).map((key) => state.transactions[key]));
  return state;
};

export function reducer(state: State = getDefaultState(), action: any): State {
  switch (action.type) {
    case TRANSACTION_DISMISS_NOTIFICATION:
      const notifications = { ...state.notifications };
      delete notifications[action.payload];
      return { ...state, notifications };
    case TRANSACTION_START_LISTENING:
      return { ...state, ...action.payload, isListening: true };
    case TRANSACTION_TRACK: {
      const transaction: Transaction = action.payload;
      return persistState(state.identifier, {
        ...state,
        queue: [...state.queue, transaction.uuid],
        transactions: { ...state.transactions, [transaction.uuid]: transaction },
      });
    }
    case TRANSACTION_UPDATE:
      const transaction: Transaction = action.payload;
      return persistState(state.identifier, {
        ...state,
        transactions: { ...state.transactions, [transaction.uuid]: { ...transaction } },
      });
    case TRANSACTION_TOGGLE:
      return { ...state, isExpanded: !state.isExpanded };
    case TRANSACTION_LOAD:
      return { ...state };
    default:
      return state;
  }
}

export default function reducerWithNotificationFilter(
  state: State = getDefaultState(),
  action: any
): State {
  const { isListening } = state;
  const newState = reducer(state, action);
  if (!isListening) return newState;
  if (
    state.transactions === newState.transactions &&
    state.archivedUuids === newState.archivedUuids
  ) {
    return newState;
  }

  let latestNotificationId = newState.latestNotificationId;
  const notifications = Object.keys(newState.transactions)
    .filter((uuid) => {
      if (!state.transactions[uuid]) return true;
      return !shallowEqual(newState.transactions[uuid], state.transactions[uuid]);
    })
    .reduce((acc, uuid) => {
      const transaction = newState.transactions[uuid];
      const id = `${transaction.uuid}:${transaction.state}`;
      const notification = { id, transaction };
      latestNotificationId = id;
      return { ...acc, [id]: notification };
    }, newState.notifications);

  return { ...newState, notifications, latestNotificationId };
}

export const trackTaskTransaction = <T>(
  task: ITask<T>,
  props: { type: TransactionType; path: string },
  afterExecute?: (T) => Partial<Transaction>
) => (dispatch, getState): ITask<T> => {
  return modifyTask(task, {
    execute: () => {
      const transaction: Transaction = {
        ...props,
        state: 'SUBMITTED',
        txHash: '',
        uuid: uuid(),
        createdAt: Date.now(),
      };

      dispatch(track(transaction));
      return task
        .execute()
        .then((returns: T) => {
          const updates = afterExecute ? afterExecute(returns) : {};
          dispatch(
            update({ ...transaction, ...updates, state: 'PROCESSING', txHash: task.context.txHash })
          );
          return returns;
        })
        .catch((e: Error) => {
          const state = e.message.includes('User denied') ? 'REJECTED' : 'FAILED';
          dispatch(update({ ...transaction, state }));
        });
    },
  });
};

const track = (transaction: Transaction): Action => async (dispatch, getState) => {
  await dispatch({ type: TRANSACTION_TRACK, payload: transaction });
};

const update = (transaction: Transaction) => ({ type: TRANSACTION_UPDATE, payload: transaction });

export const toggleTransactions = (): Action => (dispatch, getState) => {
  dispatch({ type: TRANSACTION_TOGGLE });
};

export const syncTransactionChanges = (): Action => async (dispatch, getState) => {
  const {
    app: { networkId },
    user: { address },
    transaction: { isListening },
  } = getState();
  const web3 = Web3Service.getInstance();
  const identifier = `${networkId}:${address}`;

  if (isListening) return;

  const refresh = async () => {
    const {
      transaction: { transactions, queue },
    } = getState();

    await Promise.all(
      queue.map(async (uuid) => {
        const transaction = transactions[uuid];
        if (transaction.state !== 'PROCESSING') return;

        const transactionDetails = await web3.provider.getTransaction(transaction.txHash);
        if (!transactionDetails.blockNumber) return;

        dispatch(update({ ...transaction, state: 'PROCESSED' }));
      })
    );
  };

  dispatch({
    type: TRANSACTION_START_LISTENING,
    payload: { ...loadState(identifier), identifier },
  });

  await refresh();
  web3.provider.on('block', refresh);
};

export const dismissNotification = (notification: Notification): Action => (dispatch) =>
  dispatch({ type: TRANSACTION_DISMISS_NOTIFICATION, payload: notification.id });

export const transactionQueueSelector = createSelector(
  (state: AppState) => state.transaction.queue,
  (state: AppState) => state.transaction.transactions,
  (queue, transactions) => queue.map((uuid) => transactions[uuid])
);

export const notificationsSelector = (state: AppState) =>
  Object.keys(state.transaction.notifications).map((uuid) => state.transaction.notifications[uuid]);

export const latestNotificationSelector = (state: AppState) =>
  state.transaction.notifications[state.transaction.latestNotificationId];

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { setTitle, resetTitle } from 'services/document-title';

import { State as AppState } from 'stores';
import { HandleDetails, loadHandle, cachedHandleSelector } from 'stores/content';

import Section from 'components/section';
import Button from 'components/button';
import Card from 'components/card';
import Footer from 'components/footer';
import Avatar from 'components/avatar';
import RelativeTimestamp from 'components/relative-timestamp';
import PostCard from 'components/post-card';

interface State {}
type Props = RouteComponentProps<{ handle: string }> &
  HandleDetails & {
    goTo: typeof push;
    isOwner: boolean;
    canClaim: boolean;
    onLoadHandle: typeof loadHandle;
  };

export class HandlesShowScene extends React.PureComponent<Props, State> {
  getHandle = () => this.props.match.params.handle;

  componentDidMount() {
    this.props.onLoadHandle(this.getHandle());
    if (!this.props.isLoading) this.onHandleLoaded();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoading !== prevProps.isLoading) {
      this.onHandleLoaded();
    }
  }

  componentWillUnmount() {
    resetTitle();
  }

  onHandleLoaded = () => {
    const handle = `@${this.getHandle()}`;
    setTitle(handle);
  }

  render() {
    const handle = this.getHandle();
    const { isLoading, isOwner, chain = [], profile, canClaim } = this.props;

    const icons = ['feather', 'toilet-paper', 'magic-wand', 'pencil-ruler', 'heart'];
    const randomIcon = icons[Math.round(Math.random() * 4)];

    return (
      <React.Fragment>
        <Section type="header" size="narrow">
          {profile.avatar ? (
            <Avatar size="huge" image={profile.avatar.large} />
          ) : (
            <i className="lnr lnr-user lnr-3x" />
          )}

          <h1>
            <span style={{ opacity: 0.25 }}>@</span>
            {this.getHandle()}
          </h1>
          <p>{profile.description}</p>

          <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
            {isOwner && (
              <React.Fragment>
                <Button
                  type="outline"
                  text="Edit handle info"
                  iconRight="cog"
                  onClick={() => this.props.goTo(`/@${handle}/edit`)}
                />
                <Button
                  text="New story"
                  iconRight="register"
                  onClick={() => this.props.goTo(`/@${handle}/story/new`)}
                />
                <Button
                  text="New image"
                  iconRight="camera2"
                  onClick={() => this.props.goTo(`/@${handle}/image/new`)}
                />
              </React.Fragment>
            )}
            {canClaim && (
              <Button
                text="Register this alias"
                iconRight="chevron-right"
                onClick={() => this.props.goTo(`/create-alias?handle=${handle}`)}
              />
            )}
          </div>
        </Section>

        <Section size="full">
          <div className="row" style={{ justifyContent: 'center', maxWidth: '48rem' }}>
            {chain.map(({ uuid, meta }) => (
              <div className="col-xs-12 col-sm-6" key={uuid} style={{ display: 'flex' }}>
                <PostCard snippet={{ handleDetails: this.props, ...meta, uuid, handle }} />
              </div>
            ))}
          </div>
        </Section>

        {chain.length < 1 && (
          <Section>
            <div style={{ padding: '3rem 0', textAlign: 'center' }}>
              <i
                className={classnames('lnr', ['lnr-' + randomIcon], 'lnr-3x')}
                style={{ opacity: 0.25 }}
              />
              <h3>No stories yet</h3>
            </div>
          </Section>
        )}

        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => {
  const { handle } = props.match.params;
  const cachedDetails: HandleDetails = cachedHandleSelector(state.content, props.match.params);

  return {
    isOwner: handle === state.user.handle,
    canClaim: !state.user.handle && cachedDetails.isCached && !cachedDetails.owner,
    ...cachedDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo: push,
      onLoadHandle: loadHandle,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HandlesShowScene);

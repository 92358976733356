import * as React from 'react';
import Footer from 'components/footer';
import Section from 'components/section';
import Card from 'components/card';

export default function PagePrivacyScene() {
  return (
    <React.Fragment>

    	<Section type="static-page">

        <div className="nav-spacer"></div>

    		<div className="content">
      		<div>
      			<h1>Privacy Policy</h1>
      			<p>The small print.</p>
    			</div>
    			<div>
    				<i className="lnr lnr-3x lnr-key-hole"></i>
    			</div>
  			</div>

    	</Section>

    	<Section>

      	<Card type="comfortable">
	      	<h3>Effective: June 29th, 2018</h3>

	      	<br/>

	      	<p>This policy explains what information dcntrlzd ("we", "us", "our") collect when you use Pheme Aloha located at https://aloha.pheme.app ("Services"). It also provides information about how we store, use, transfer, and delete that information.</p>
          <p>Our Privacy Policy can be summarized in three words;</p>

          <br/>
          <h4>TRUST THROUGH TRANSPARENCY</h4>
          <br/>

          <p>Any action you take using our Services, which are currently limited to;</p>
          <p>
            i. Creating an alias<br/>
            ii. Updating you alias profile<br/>
            iii. Publishing content<br/>
            iv. Editing ("delisting & re-listing") a publication<br/>
            v. Deleting ("delisting") a publication
          </p>
          <p>are recorded on a public distributed ledger ("Ethereum"), which allows any person with access to internet to view any action you took or any content you published on the Pheme network.</p>
          <br/>

          <h3>Data Storage</h3>
          <br/>
          <p>Pheme uses Inter Planetary File System ("IPFS") to store data. By using our Services, you authorize IPFS to transfer and store your information in any country where an IPFS node is operating. Any information you upload will be fully encrypted by IPFS, and distributed to one or more peers supporting the network.</p>
          <p>Pheme also uses localStorage to cache user progress. This data is stored to make sure you find your work in progress when you come back to Pheme. This data is stored in your computer and is saved across browser sessions. It can not be distributed or tracked by any 3rd Party, including us.</p>
          <br/>

          <h3>Third-Party Embeds</h3>
          <br/>
          <p>All content that you see displayed on the Service is hosted by IPFS and Ethereum. There are no third-party content embedded in our Service.</p>
          <br/>

          <h3>Modifying or Deleting Content</h3>
          <br/>
          <p>Since Ethereum is an immutable ledger, you can not fully delete any content published from Pheme or fully edit any content published on Pheme. When you remove content, your content is only delisted from the Service. When you edit content, the old version of your content is delisted from the Service and the new version is listed on the Service. Delisting makes it more difficult for your deleted or old content to be found for others. The outdated or removed content persist without any time limitation and may still be available to others. If you do not agree to this usage, do not create an account or use any of our Services.</p>
          <br/>

          <h3>Modifying or Deleting Your Personal Information</h3>
          <br/>
          <p>We do not ask for any personal information to create an account ("Alias") within Pheme. Your public Ethereum Address is the only personal information you require to interact with the Service. The alias you create can not be fully modified or removed. If you do not agree to this usage, do not create an account or use any of our Services.</p>
          <br/>

          <h3>Information We Collect & How We Use It</h3>
          <br/>
          <p>Our Service does not collect any other information other than your public Ethereum Address. We collect this data to recognize you when you return to our Services.</p>
          <br/>

          <h3>Tracking & Cookies</h3>
          <br/>
          <p>We do not use any cookies or similar technologies to recognize you when you return to our Services. We only use your public Ethereum Address to identify you. We do not track you across the Internet. We do not track your interactions within the Service. Be aware that Ethereum is a public network and any action related to your public account can be traced by anyone with access to internet.</p>
          <br/>

          <h3>Changes to this Policy</h3>
          <br/>
          <p>dcntrlzd may periodically update this Policy. You may always access the most current version and former versions of the policy under the alias (@pheme-privacy)</p>
          <br/>

          <h3>Data Protection Statement for European Union Users</h3>
          <br/>
          <p>dcntrlzd or this Service does not collect or store any personal information about its users in a permissioned (private) server. All actions and content taken on the platform will be stored on Ethereum, a public distributed ledger, and can be accessed by anyone. Therefore we encourage our users not to share any personal information with us or the Service. If you do not agree to this public usage, do not create an account or use any of our Services.</p>
          <p>Only personal data we will collect is the public Ethereum Address of our users to recognize them when they return to this Service.</p>
          <br/>
          
          <h3>Contact information</h3>
          <br/>
          <p>
            Pheme<br/>
            P.O. Box 310690<br/>
            Brooklyn, NY 11231<br/>
            United States of America<br/>
          </p>

      	</Card>

    	</Section>

      <Footer />
    </React.Fragment>
  );
};

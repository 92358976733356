import * as React from 'react';
import classnames from 'classnames';
import './styles.scss';

type Props = {
  children: React.ReactNode,
  type?: string,
  size?: string,
  state?: string,
}

const SIZE_CLASS_MAP = {
  'narrow': classnames('col-xs-12', 'col-sm-10', 'col-sm-offset-1', 'col-lg-6', 'col-lg-offset-3'),
  'wide': classnames('col-xs-12', 'col-lg-10', 'col-lg-offset-1'),
  'full': classnames('col-xs-12'),
}

export const Section = (props: Props) => {

  return (
    <section className={classnames('section', { ['section--' + props.type]: props.type, 'isLoading': props.state == 'isLoading' })}>
      {(props.type == 'header') && (
        <div className="nav-spacer"></div>
      )}
      <div className="row">
        <div className={ SIZE_CLASS_MAP[props.size] || classnames('col-xs-12', 'col-sm-10', 'col-sm-offset-1', 'col-lg-8', 'col-lg-offset-2') }>

          { props.children }

        </div>
      </div>
    </section>
  );
};

export default Section;

import MESSAGES from 'constants/messages';

import { Transaction } from 'stores/transaction';

export default function getTransactionMessage(transaction: Transaction) {
  const { state, type } = transaction;
  const messages = MESSAGES[type] || {
    pending: undefined,
    failed: undefined,
    completed: undefined,
  };

  let msg;

  if (state === 'SUBMITTED' || state === 'PROCESSING') {
    msg = messages.pending;
  } else if(state === 'FAILED' || state === 'REJECTED') {
    msg = messages.failed;
  } else if(state === 'PROCESSED') {
    msg = messages.completed;
  }

  return msg || 'Transaction (of some sort)';
}

import * as React from 'react';

export const SVGIconMainnet = () => {

  return (
    <svg version="1.1" width='14' height='14' viewBox='0 0 14 14'>
        <g id='Page-1' fill='none' fillRule='evenodd'>
            <circle id='Oval' fill='#29b4ae' fillRule='nonzero' cx='7'
            cy='7' r='6' />
        </g>
    </svg>
  );
};

export default SVGIconMainnet;

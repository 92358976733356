import * as React from 'react';
import classnames from 'classnames';
import StoredImage from 'components/stored-image';
import './styles.scss';

export type Props = {
  image?: string;
  size?: string;
  title?: string;
  placement?: string;
  icon?: string;
};

export const Avatar = ({ image, size, title, placement, icon }: Props) => (
  <figure
    className={classnames('avatar', {
      ['avatar--' + size]: size,
      ['avatar--' + placement]: placement,
    })}
    title={title}
  >
    {image ? (
      <StoredImage address={image} alt={title} />
    ) : (
      <React.Fragment>{icon && <i className={classnames('lnr', 'lnr-' + icon)} />}</React.Fragment>
    )}
  </figure>
);

export default Avatar;

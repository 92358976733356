import * as React from 'react';

type Props = {
  size?: number,
}

export const SVGIconMetamask = (props: Props) => {
	
	const size = props.size ? props.size : '16';
	const colours = {
		st0: "#E57F23",
		st1: "#D86F20",
		st2: "#C6B5A9",
		st3: "#DAC7B9",
		st4: "#211F1F",
		st5: "#F28A26",
		st6: "#81481E",
		st7: "#263E51",
	};

  return (
		<svg version="1.1" width={size+"px"} height={size+"px"} x="0px" y="0px" viewBox="0 0 20 20">
			<polygon fill={colours.st0} points="2.3,19.9 8.5,18 6.8,15.8 0.7,15.8 	"/>
			<polygon fill={colours.st1} points="8.5,18 10.3,14.8 6.8,15.8 	"/>
			<polygon fill={colours.st2} points="8.5,18 14.6,20 17.5,19.8 18.7,18.4 17.2,18.6 15.4,19.6 	"/>
			<polygon fill={colours.st3} points="8.5,18 15.4,19.6 17.2,18.6 17.2,17.1 	"/>
			<polygon fill={colours.st4} points="17.2,18.6 18.7,18.4 19.3,17.1 18.5,16.7 17.4,16.9 17.2,17.1 	"/>
			<polygon fill={colours.st0} points="8.5,18 17.2,17.1 10.3,14.8 	"/>
			<polygon fill={colours.st5} points="0.7,15.8 6.8,15.8 10.3,14.8 7.4,11.7 6.5,10.1 3,10.1 	"/>
			<polygon fill={colours.st5} points="10.3,14.8 17.2,17.1 12.4,13.4 9.7,12.9 	"/>
			<polygon fill={colours.st0} points="10.3,14.8 9.7,12.9 7.4,11.7 	"/>
			<polygon fill={colours.st1} points="9.7,12.9 11.5,11.9 12.4,13.4 12.8,11.5 7.4,11.7 	"/>
			<polygon fill={colours.st0} points="12.4,13.4 17.2,17.1 13.4,13.4 12.8,11.5 	"/>
			<polygon fill={colours.st5} points="17.2,17.1 17.4,16.9 18.5,16.7 15.4,13.2 13.3,5.7 12.6,2.7 8.7,2.7 10.6,5.7 12.8,11.5 13.4,13.4 	
				"/>
			<polygon fill={colours.st0} points="7.4,11.7 12.8,11.5 10.6,5.7 6.5,10.1 	"/>
			<polygon fill={colours.st1} points="16.9,14.9 15.7,13 15.9,11.8 16.5,12.6 16.7,11.4 14.9,11.5 15.4,13.2 	"/>
			<polygon fill={colours.st5} points="15.7,13 16.9,14.9 16.5,12.6 	"/>
			<polygon fill={colours.st0} points="16.9,14.9 17.1,12.9 16.7,11.4 16.5,12.6 	"/>
			<polygon fill={colours.st0} points="14.9,11.5 16.7,11.4 16.9,9.5 13.3,5.7 	"/>
			<polygon fill={colours.st6} points="13.3,5.7 16.9,9.5 17.6,8.7 17.4,7.9 17.9,7.4 17.3,7.1 17.7,6.7 17.1,6.1 19,1.1 	"/>
			<polygon fill={colours.st0} points="13.3,5.7 19,1.1 12.6,2.7 	"/>
			<polygon fill={colours.st6} points="8.7,2.7 6.6,0 3.4,2.9 2.2,5.8 2.1,7 1.4,7.3 2.6,8 1.6,8.2 2.7,8.8 2.1,9.2 3,10.1 6.5,10.1 
				10.6,5.7 	"/>
			<polygon fill={colours.st7} points="9.7,12.9 12.4,13.4 11.5,11.9 "/>
			<polygon fill={colours.st7} points="15.7,13 16.5,12.6 15.9,11.8 "/>
		</svg>
  );
};

export default SVGIconMetamask;

import * as React from 'react';
import { connect } from 'react-redux';
import Footer from 'components/footer';
import Section from 'components/section';
import Card from 'components/card';
import QRCode from 'qrcode.react';
import { UserPlatform } from 'stores/user';

interface Props {
  isReadOnly: boolean;
  currentUserAddress: string;
  platform: UserPlatform,
}

const MobileInstructions = ({ platform }: Props) => {
  return (
      <p>
        It seems like you are using an {platform} device.
        The following web browsers have a built-in Ethereum wallet and in our experience provide the best user experience.
        Please install one of them, follow the setup instructions and revisit this page using it.
        <br/>
        <br/>
        {platform === 'IOS' ? (
          <React.Fragment>
            <a href="itms-appss://apps.apple.com/us/app/coinbase-wallet/id1278383455?mt=8">Coinbase Wallet</a>
            <br /><br />
            <a href="itms-appss://apps.apple.com/us/app/opera-touch-web-browser/id1411869974?mt=8">Opera Touch</a>
            <br /><br />
            <a href="itms-appss://apps.apple.com/us/app/trust-crypto-bitcoin-wallet/id1288339409?mt=8">Trust Wallet</a>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <a href="https://play.google.com/store/apps/details?id=org.toshi&hl=en_US">Coinbase Wallet</a>
            <br /><br />
            <a href="https://play.google.com/store/apps/details?id=com.opera.touch&hl=en_US">Opera Touch</a>
            <br /><br />
            <a href="https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp&hl=en_US">Trust Wallet</a>
          </React.Fragment>
        )}
      </p>
  );
};

const MetamaskInstructions = ({ platform }: Props) => {
  return (
      <p>
        It seems like you are using a {platform} based web browser.
        In our experience,{' '}
        {platform === 'CHROME' && (
          <a href="https://chrome.google.com/webstore/detail/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank" style={{ textDecoration: 'none' }}>Metamask Wallet Extension</a>
        )}

        {platform === 'FIREFOX' && (
          <a href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/" target="_blank">Metamask Wallet Extension</a>
        )}

        {platform === 'OPERA' && (
          <a href="https://addons.opera.com/en/extensions/details/metamask/" target="_blank">Metamask Wallet Extension</a>
        )}{' '}
        provides the best user experience with this browser.<br /><br />

        Above link will open up a new tab where you can install this extension.
        After installing it, please follow the setup instructions and revisit this page with the extension installed.
      </p>
  );
};

const DesktopInstructions = ({ platform }: Props) => {
  return (
      <p>
        We were not able to detect the web browser you are using.
        We recommend using one of the following browsers with Pheme.
        <br/>
        <br/>
        <a href="https://brave.com/" target="_blank">Brave</a><br/>
        <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a><br/>
        <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a><br/>
        <a href="https://www.opera.com/" target="_blank">Opera browser</a><br/>
      </p>
  );
};

const UnknownInstructions = ({ platform }: Props) => {
  return (
    <p>
      We were not able to detect the web browser you are using.
      We recommend using one of the following browsers with Pheme.
      <br/>
      <br/>
      <a href="https://brave.com/" target="_blank">Brave</a><br/>
      <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a><br/>
      <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a><br/>
      <a href="https://www.opera.com/" target="_blank">Opera browser</a><br/>
    </p>
  );
};

export class PageHowToScene extends React.PureComponent<Props> {
  constructor(props: Props){
    super(props);
  }

  renderInstructions() {
    const { platform } = this.props;

    switch (platform) {
      case 'IOS':
      case 'ANDROID':
        return <MobileInstructions {...this.props} />;
      case 'CHROME':
      case 'FIREFOX':
      case 'OPERA':
        return <MetamaskInstructions {...this.props} />;
      case 'UNKNOWN_DESKTOP':
        return <DesktopInstructions {...this.props} />;
      default:
        return <UnknownInstructions {...this.props} />
    }
  }

  render() {
    const { isReadOnly, currentUserAddress } = this.props;

    return (
      <React.Fragment>

      	<Section type="static-page">

          <div className="nav-spacer"></div>


          { isReadOnly ? (
            <Card type="comfortable" title="How to install a wallet?">
              <p>
                Pheme is built on the <strong>Ethereum Network</strong>.
                This network has a built-in digital currency called <strong>Ether</strong>, which is also known as <strong>ETH</strong>.
                To interact with Pheme, you will first need to install an Ethereum wallet capable of handling Ether transactions.
              </p>
              {this.renderInstructions()}
            </Card>
          ) : (
            <Card type="comfortable" title="How to fund your account?">
              <p>
                Pheme is built on the <strong>Ethereum Network</strong>.
                This network has a built-in digital currency called <strong>Ether</strong>, which is also known as <strong>ETH</strong>.
                To interact with Pheme, you will first need to fund your wallet with Ether, so that you can pay for certain actions.
              </p>
              <p>
                You are visiting this site with personal Ethereum wallet connected. Your address is:
              </p>
              <p style={{ textAlign: "center" }}>
                <QRCode size={100} value={currentUserAddress} />
                <br/>
                <small><strong>{currentUserAddress}</strong></small>
              </p>

              <p><strong>1. Purchase Ether</strong></p>
              <p>
                If you wanted to purchase Euros for your upcoming Europe trip, you would have visited a foreign currency exchange office.
                Purchasing Ether works exactly the same, with one difference. Instead of visiting a foreign currency exchange, you will need to visit a cryptocurrency exchange.
              </p>
              <p>
                There are many cryptocurrency exchanges available online. Doing your own research and picking the best exchange for you is essential, but in our experience
                {' '}<a href="https://www.coinbase.com" target="_blank">Coinbase</a>{' '}provides the best user experience, and operates in many countries.
              </p>
              <p>
                To complete this first step, sign up to the cryptocurrency exchange of your choice, and purchase some Ether.
                Pheme requires a minimal amount of ETH to operate, so as a test run we recommend purchasing a very small amount.
              </p>

              <p><strong>2. Transfer ETH to your personal wallet</strong></p>
              <p>
                When you sign up with a cryptocurrency exchange, they will create a wallet in their systems on your behalf.
                Although quite secure, these exchange wallets do not allow you to experience the Ethereum Network to its fullest.
                In order to interact with "decentralized applications" such as Pheme, you will need to transfer your ETH to your personal wallet.
              </p>
              <p>
                In the cryptocurrency exchange you picked, find the functionality to transfer funds to an external address. Oftentimes this functionality is called "withdraw".
                Using this functionality, transfer funds from your exchange wallet to your personal wallet. When asked, enter your personal Ethereum wallet address - <small><strong>{currentUserAddress}</strong></small> - and transfer as much as ETH you want.
              </p>

            </Card>
          )}

      	</Section>

        <Footer />
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state: AppState) => ({
  currentUserAddress: state.user.address,
  isReadOnly: state.app.isReadOnly,
  platform: state.user.platform,
});

export default connect(
  mapStateToProps
)(PageHowToScene)

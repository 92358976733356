import { push as goTo } from 'connected-react-router';
import { createSelector } from 'reselect';
import * as ethers from 'ethers';
import moment from 'moment';

import { IBlock } from '@pheme-kit/core/lib';
import { modifyTask } from '@pheme-kit/core/lib/task';

import PhemeService, { ITask } from 'services/pheme';
import * as LocalStorageService from 'services/local-storage';

import {
  trackTaskTransaction,
  Transaction,
  TransactionType,
  TRANSACTION_UPDATE,
} from 'stores/transaction';
import { State as AppState, Action } from 'stores';
import { refreshEndorsementsByContent } from 'stores/endorsements';
import waitUntil from 'lib/wait-until';

import Remarkable from 'remarkable';

export interface HandleAvatar {
  original: string;
  small: string;
  large: string;
}

export interface HandleProfile {
  description: string;
  avatarUrl?: string;
  avatar: HandleAvatar;
}

export interface HandleDetails {
  isLoading: boolean;
  isCached: boolean;
  owner: string;
  profile: HandleProfile;
  chain: IBlock[];
}

export interface Post {
  coverImageUrl?: string;
  coverImage: PostCoverImage;
  article: string;
  date: string;
  title: string;
  type: string;
}

export interface PostCoverImage {
  original: string;
  small: string;
  large: string;
}

export interface PostMeta {
  coverImageUrl?: string;
  coverImage: PostCoverImage;
  date: string;
  title: string;
  type: string;
  excerpt: string;
}

export type PostDetails = Post & {
  address: string;
  isCached: boolean;
  isLoading: boolean;
};

export type PostSnippet = PostMeta & {
  uuid: string;
  handle: string;
  handleDetails: HandleDetails;
};

interface AddressToHandleMap {
  [handle: string]: string;
}

export interface State {
  handleCache: { [handle: string]: HandleDetails };
  postCache: { [postId: string]: PostDetails };
  isLoadingLatestPosts: boolean;
  latestPosts: PostSnippet[];
  addressToHandleMap: AddressToHandleMap;
  latestPostsTimestamp: number;
}

const HANDLE_LOAD_BEGIN = 'handle/loadBegin';
const HANDLE_LOAD_END = 'handle/loadEnd';

const POST_PRE_CACHE = 'post/preCache';
const POST_LOAD_BEGIN = 'post/loadBegin';
const POST_LOAD_END = 'post/loadEnd';

const LOAD_LATEST_POSTS_BEGIN = 'handle/loadLatestPostsBegin';
const LOAD_LATEST_POSTS_END = 'handle/loadLatestPostsEnd';

export const getBlankHandleProfile = (): HandleProfile => ({
  description: '',
  avatar: undefined,
});

export const getBlankHandleDetails = (): HandleDetails => ({
  isLoading: true,
  isCached: false,
  owner: '',
  profile: getBlankHandleProfile(),
  chain: [],
});

export const getBlankPostDetails = (): PostDetails => ({
  isCached: false,
  isLoading: true,
  title: '',
  date: '',
  type: '',
  article: '',
  address: '',
  coverImage: undefined,
});

const getDefaultState = (): State => ({
  handleCache: {},
  postCache: {},
  addressToHandleMap: {},
  isLoadingLatestPosts: false,
  latestPosts: LocalStorageService.get('latestPosts') || [],
  latestPostsTimestamp: undefined,
});

export default function reducer(state: State = getDefaultState(), action: any): State {
  switch (action.type) {
    case HANDLE_LOAD_BEGIN: {
      const { handle } = action.payload;
      const { handleCache } = state;
      const details = handleCache[handle] || getBlankHandleDetails();
      return {
        ...state,
        handleCache: {
          ...handleCache,
          [handle]: { ...details, isLoading: true },
        },
      };
    }
    case HANDLE_LOAD_END: {
      const { handle, details } = action.payload;
      const { handleCache, addressToHandleMap } = state;

      return {
        ...state,
        addressToHandleMap: details.owner
          ? {
              ...addressToHandleMap,
              [details.owner]: handle,
            }
          : addressToHandleMap,
        handleCache: {
          ...handleCache,
          [handle]: { ...details, isLoading: false, isCached: true },
        },
      };
    }
    case POST_LOAD_BEGIN: {
      const { postId } = action.payload;
      const { postCache } = state;
      const details = postCache[postId] || getBlankPostDetails();
      return {
        ...state,
        postCache: {
          ...postCache,
          [postId]: { ...details, isLoading: true },
        },
      };
    }
    case POST_PRE_CACHE:
    case POST_LOAD_END: {
      const { postId, details } = action.payload;
      const { postCache } = state;

      return {
        ...state,
        postCache: {
          ...postCache,
          [postId]: { ...details, isLoading: false, isCached: true },
        },
      };
    }
    case LOAD_LATEST_POSTS_BEGIN:
      return { ...state, isLoadingLatestPosts: true };
    case LOAD_LATEST_POSTS_END:
      return { ...state, ...action.payload, isLoadingLatestPosts: false };
    default:
      return state;
  }
}

export const buildPostCacheKey = (handle: string, uuid: string) => `@${handle}/${uuid}`;

export const loadLatestPosts = (handleLimit: number = 100) => async (dispatch, getState) => {
  const {
    content: { latestPostsTimestamp },
  } = getState();
  const timestamp = Date.now();
  if (!latestPostsTimestamp && timestamp - latestPostsTimestamp < 10000) return;

  dispatch({ type: LOAD_LATEST_POSTS_BEGIN });

  const postLoaders = [];
  const pheme = PhemeService.getInstance();
  const handleCount = await pheme.registry.getHandleCount().execute();

  if (handleCount > 0) {
    const fetchFrom = handleCount - 1;
    const fetchUntil = Math.max(fetchFrom - handleLimit, 0);

    for (let i = fetchFrom; i >= fetchUntil; i -= 1) {
      const handle = await pheme.registry.getHandleAt(i).execute();
      const handleLoader = dispatch(loadHandle(handle));

      postLoaders.push(
        handleLoader.then(() => {
          const handleDetails = cachedHandleSelector(getState().content, { handle });
          return handleDetails.chain.map((block) => ({
            ...block.meta,
            uuid: block.uuid,
            handle,
            handleDetails,
          }));
        })
      );
    }
  }

  const latestPosts = await Promise.all(postLoaders).then((loaders) =>
    loaders.reduce((list, snippets) => [...list, ...snippets], [])
  );

  latestPosts.sort((a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1));

  LocalStorageService.set('latestPosts', latestPosts);
  dispatch({
    type: LOAD_LATEST_POSTS_END,
    payload: { latestPosts, latestPostsTimestamp: timestamp },
  });
};

export const buildClaimHandle = (handle: string): Action => async (dispatch, getState) => {
  const pheme = PhemeService.getInstance();
  const task = pheme.registerHandle(handle) as ITask;

  return dispatch(trackTaskTransaction(task, { type: 'REGISTER_HANDLE', path: `/@${handle}` }));
};

export const buildUpdateProfile = (handle: string, profile: HandleProfile): Action => async (
  dispatch,
  getState
) => {
  const pheme = PhemeService.getInstance();
  const task = pheme.updateHandleProfile(handle, profile) as ITask<string>;

  return dispatch(trackTaskTransaction(task, { type: 'UPDATE_PROFILE', path: `/@${handle}` }));
};

export const buildUnpublishPost = (handle: string, uuid: string): Action => async (
  dispatch,
  getState
) => {
  const pheme = PhemeService.getInstance();
  const task = (pheme.removeFromHandle(handle, uuid) as any) as ITask<string>;

  return dispatch(trackTaskTransaction(task, { type: 'UNPUBLISH_POST', path: `/@${handle}` }));
};

export const buildPostMetaFromPost = (
  { title, date, type, coverImage, article }: Post,
  limit = 160
): PostMeta => {
  let excerpt = '';

  try {
    const parser = new Remarkable();

    excerpt = parser
      .render(article)
      .split('<p>')[1]
      .split('</p>')[0] // Pick the first paragraph
      .replace(/<[^>]*>/g, '') // Remove all html tags
      .replace(/\s+/g, ' '); // Squeeze the extra spaces

    if (excerpt.length > limit) excerpt = excerpt.substring(0, limit) + '…';
  } finally {
    return { title, date, type, coverImage, excerpt };
  }
};

export const buildReplacePost = (handle: string, uuid: string, post: Post): Action => async (
  dispatch,
  getState
) => {
  const pheme = PhemeService.getInstance();

  const meta = buildPostMetaFromPost(post);
  const content = { ...post, ...meta };

  const data = new Buffer(JSON.stringify(content));

  const task = (pheme.replaceFromHandle(handle, uuid, data, meta) as any) as ITask<
    [string, IBlock[]]
  >;

  // TODO: loading post invalidates the precaching therefore the block below is not working well
  // const taskWithCaching = modifyTask(task, {
  //   execute: () => task.execute().then(([pointer, blocks]) => {
  //     const postId = buildPostCacheKey(handle, uuid);
  //     dispatch({ type: POST_PRE_CACHE, payload: { postId, details: post }});
  //     return [pointer, blocks];
  //   })
  // });

  return dispatch(trackTaskTransaction(task, { type: 'UPDATE_POST', path: `/@${handle}/${uuid}` }));
};

export const buildPublishPost = (handle: string, post: Post): Action => async (
  dispatch,
  getState
) => {
  const pheme = PhemeService.getInstance();

  const meta = buildPostMetaFromPost(post);
  const content = { ...post, ...meta };

  const data = new Buffer(JSON.stringify(content));
  const task: ITask<[string, IBlock]> = pheme.pushToHandle(handle, data, meta) as any;

  const taskWithCaching = modifyTask(task, {
    execute: () =>
      task.execute().then(([address, block]) => {
        const postId = buildPostCacheKey(handle, block.uuid);
        dispatch({ type: POST_PRE_CACHE, payload: { postId, details: { ...post, address } } });
        return [address, block];
      }),
  });

  return dispatch(
    trackTaskTransaction(
      taskWithCaching,
      {
        type: 'PUBLISH_POST',
        path: '',
      },
      ([address, block]) => ({
        path: `/@${handle}/${block.uuid}`,
      })
    )
  );
};

const backfillPostCoverImage = <T extends PostMeta | Post>(post: T): T => {
  if (post.coverImageUrl && !post.coverImage) {
    return {
      ...(post as any),
      coverImage: {
        original: post.coverImageUrl,
        small: post.coverImageUrl,
        large: post.coverImageUrl,
      },
    };
  }

  return post;
};

export const loadPost = (handle: string, uuid: string): Action => async (dispatch, getState) => {
  const postId = buildPostCacheKey(handle, uuid);
  await dispatch(loadHandle(handle));

  const {
    postCache: { [postId]: cachedPost },
    handleCache: { [handle]: cachedHandle },
  } = getState().content;

  if (cachedPost && cachedPost.isLoading) return;

  dispatch({ type: POST_LOAD_BEGIN, payload: { postId } });

  const postBlock = cachedHandle.chain.find((block) => block.uuid === uuid);

  if (!postBlock) {
    dispatch({ type: POST_LOAD_END, payload: { postId, details: cachedPost } });
    return;
  }
  const { address } = postBlock;
  const post = ((await PhemeService.getInstance().storage.readObject(address)) as any) as Post;
  const details = { address, ...backfillPostCoverImage(post) };

  dispatch({ type: POST_LOAD_END, payload: { postId, ...postBlock.meta, details } });
};

const backfillHandleAvatar = (profile: HandleProfile): HandleProfile => {
  if (profile.avatarUrl && !profile.avatar) {
    return {
      ...profile,
      avatar: {
        original: profile.avatarUrl,
        small: profile.avatarUrl,
        large: profile.avatarUrl,
      },
    };
  }

  return profile;
};

export const loadHandle = (handle: string): Action<Promise<void>> => async (dispatch, getState) => {
  const cachedHandle = getState().content.handleCache[handle];
  if (cachedHandle && cachedHandle.isLoading) return;

  try {
    dispatch({ type: HANDLE_LOAD_BEGIN, payload: { handle } });

    const [owner, profile, [address, chain]] = await Promise.all([
      PhemeService.getInstance()
        .registry.getOwner(handle)
        .execute(),
      PhemeService.getInstance()
        .getHandleProfile(handle)
        .execute(),
      PhemeService.getInstance()
        .loadHandle(handle)
        .execute(),
    ]);

    chain.forEach((block) => (block.meta = backfillPostCoverImage(block.meta)));

    const details = {
      isLoading: false,
      owner,
      profile: backfillHandleAvatar(profile || getBlankHandleProfile()),
      chain,
    };

    dispatch({ type: HANDLE_LOAD_END, payload: { handle, details } });
  } catch (e) {
    dispatch({ type: HANDLE_LOAD_END, payload: { handle, details: getBlankHandleDetails() } });
  }
};

export const loadHandleByAddress = (address: string): Action<Promise<void>> => async (
  dispatch,
  getState
) => {
  const { content: addressToHandleMap } = getState();
  if (addressToHandleMap[address]) return;
  const handle = await PhemeService.getInstance()
    .registry.getHandleByOwner(address)
    .execute();
  // TODO: should be cacheable
  if (handle) dispatch(loadHandle(handle));
};

export const cachedPostSelector = (state: State, props: { handle: string; uuid: string }) =>
  state.postCache[buildPostCacheKey(props.handle, props.uuid)] || getBlankPostDetails();

export const cachedHandleSelector = (state: State, props: { handle: string }) =>
  state.handleCache[props.handle] || getBlankHandleDetails();

const SYNC_TABLE: { [P in TransactionType]?: (...args: any[]) => any } = {
  REGISTER_HANDLE: loadHandle,
  UPDATE_PROFILE: loadHandle,
  UNPUBLISH_POST: loadHandle,
  UPDATE_POST: loadPost,
  PUBLISH_POST: loadPost,
  ENDORSE_CONTENT: refreshEndorsementsByContent,
};

export const syncMiddleware = (store) => (next) => (action) => {
  const returns = next(action);

  if (action.type === TRANSACTION_UPDATE) {
    const { uuid }: Transaction = action.payload;
    const transaction: Transaction = store.getState().transaction.transactions[uuid];
    if (transaction.state === 'PROCESSED') {
      const syncAction = SYNC_TABLE[transaction.type];
      if (syncAction) {
        const [_, ...unfilteredArgs] = transaction.path.match(/\/@([^\/]+)\/?([^\/]+)?/);
        const args = unfilteredArgs.filter((match) => !!match) as any[];
        if (args.length) {
          store.dispatch(syncAction(...args));
        }
      }
    }
  }

  return returns;
};

import * as React from 'react';
import moment from 'moment';
import areEqual from 'fbjs/lib/areEqual';

import PhemeService from 'services/pheme';

import { PostCoverImage } from 'stores/content';

import Section from 'components/section';
import Editor from 'components/editor';
import Field from 'components/field';
import Button from 'components/button';
import InfoBox from 'components/info-box';
import ImageUploader from 'components/image-uploader';
import { FormValues } from 'components/post-form';

type State = FormValues;

type Props = FormValues & {
  formTitle: string,
  handle: string,
  contentType: string,
  children?: React.ReactNode,
  onChange?: (values: FormValues) => any,
};

export default class PostFormEditStage extends React.PureComponent<Props, State>{
  titleInput: HTMLInputElement;
  dateInput: HTMLInputElement;
  formValues: FormValues;

  constructor(props: Props) {
    super(props);

    this.state = PostFormEditStage.buildFormValuesFromProps(this.props);
  }

  static buildFormValuesFromProps = (props: Props) => {
    return {
      article: props.article,
      title: props.title,
      date: props.date,
      coverImage: props.coverImage,
      type: props.type,
    };
  }

  componentDidMount() {
    this.onChange();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!areEqual(prevState, this.state)) this.onChange();
  }

  onChange = () => {
    const { onChange } = this.props;
    if (onChange) onChange({ ...this.state });
  };

  onArticleChange = (value: string) => {
    this.setState({ article: value });
  };

  onDateChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ date: e.currentTarget.value });
  };

  onTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ title: e.currentTarget.value });
  }

  onCoverImageReady = (coverImage: PostCoverImage) => {
    this.setState({ coverImage })
  }

  onRemoveCoverImage = () => {
    this.setState({ coverImage: undefined });
  }

  render() {
    const { children, handle, formTitle, contentType } = this.props;
    const { date, article, title, coverImage } = this.state;
    const isArticle = contentType === "article"

    return (
      <Section>

        <div className="nav-spacer"></div>

        <h1 style={{ margin: 0 }}>{formTitle}</h1>
        <p style={{ opacity: 0.25 }}>as <strong>@{handle}</strong></p>

        <br />
        <React.Fragment>
          <div className="postForm__headerPanel">
            <Field type="writer-photo">
              <ImageUploader
                versions={{
                  small: { method: 'FIT', width: 720, height: 720 },
                  large: { method: 'FIT', width: 1440, height: 1440 },
                }}
                image={coverImage}
                onReady={this.onCoverImageReady}
                onRemove={this.onRemoveCoverImage}
                label="Upload image"
                isImagePost={true}
              />
            </Field>
          </div>

          <div className="postForm__headerPanel-title">
            <Field type="writer-title">
              <input defaultValue={title} onChange={this.onTitleChange} className="field--fullwidth" type="text" placeholder="Title" />
            </Field>
          </div>

          <Field type="writer">
            <div className="field field--fullwidth">
              <Editor value={article} contentType={contentType} onChange={this.onArticleChange} />
            </div>
          </Field>
        </React.Fragment>
        <input className="field--fullwidth" readOnly value={date} type="hidden" ref={dateInput => { this.dateInput = dateInput }} />

        {children}

      </Section>
    );
  }
};

import * as React from 'react';
import classnames from 'classnames';
import './styles.scss';

type Props = {
  children: React.ReactNode,
  type?: string,
  title?: string,
}

type State = {
  isExpanded: boolean
}

export default class Card extends React.PureComponent<Props,State> {

  state = { isExpanded: false }

  render() {
    return (
      <div className={classnames('card', {
        ['card--' + this.props.type]: this.props.type,
        'isExpanded': this.state.isExpanded
      })}>

        { this.props.title && (
          <div className="card__header" onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}>
            <h3>{ this.props.title }</h3>

            { this.props.type == 'expandable' && (
              <React.Fragment>
                { this.state.isExpanded ? (
                  <i className="lnr lnr-chevron-up"></i>
                ) : (
                  <i className="lnr lnr-chevron-down"></i>
                )}
              </React.Fragment>
            )}

          </div>
        )}

        { this.props.title ? (
          <div className="card__content">
            { this.props.children }
          </div>
        ) : (
          <React.Fragment>
            { this.props.children }
          </React.Fragment>
        )}

      </div>
    );
  }

}

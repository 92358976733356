import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { State as AppState } from 'stores';
import { ProviderName } from 'services/web3';
import * as clipboard from 'services/clipboard';

import OnboardingMenu from 'components/onboarding-menu';
import SVGIconSettings from 'components/svg-icon-settings';
import SVGIconDapps from 'components/svg-icon-dapps';
import SVGIconMetamask from 'components/svg-icon-metamask';
import SVGIconMainnet from 'components/svg-icon-mainnet';
import SVGIconRopsten from 'components/svg-icon-ropsten';
import CopyLink from 'components/copy-link';
import Button from 'components/button';
import Dialog from 'components/dialog';

type Props = { providerName: ProviderName };

type State = { isExplainerDialogActive: boolean };

const MetamaskInstructions = () => {
  return (
    <React.Fragment>
      <ol>
        <li>Open Metamask by clicking the <SVGIconMetamask size={16} /> icon next to your address bar.</li>
        <li>Click on <SVGIconMainnet /> <strong>Main Ethereum Network</strong> to view the list of networks.</li>
        <li>Select <SVGIconRopsten /> <strong>Ropsten Test Network</strong>.</li>
        <li>Refresh this page</li>
      </ol>
    </React.Fragment>
  );
};

const CoinbaseWalletInstructions = () => {
  return (
    <React.Fragment>
      <ol>
        <li>Go to <SVGIconSettings /> tab within Coinbase Wallet</li>
        <li>Advanced Settings <i className="lnr lnr-chevron-right lnr-075x"></i> Active Network <i className="lnr lnr-chevron-right lnr-075x"></i> Select <strong>Ropsten</strong> under Ethereum</li>
        <li>Come back here to <SVGIconDapps /> tab and refresh this page.</li>
      </ol>
    </React.Fragment>
  );
};

const GenericInstructions = () => {
  return (
    <React.Fragment>
      <ol>
        <li>Locate the advanced settings tab within your wallet application</li>
        <li>Switch to <strong>Ropsten Test Network</strong></li>
      </ol>
    </React.Fragment>
  );
};

export class WrongNetworkStepROPSTEN extends React.PureComponent<Props>{

  state = {
    isExplainerDialogActive: false,
  };

   onExplainerDialogOpen = () => {
    this.setState({ isExplainerDialogActive: true });
  }

   onExplainerDialogClose = () => {
    this.setState({ isExplainerDialogActive: false });
  }

  renderExplainerDialog = () => (
    <Dialog active={this.state.isExplainerDialogActive} onBlur={this.onExplainerDialogClose}>
      <h3>We are playing safe.</h3>
      <br/>
      <p>This is Pheme Aloha, a clone of Pheme, which we use for alpha testing our new features and there might be bugs around. We don't want you to loose real Ethers because of a detail we missed.</p>
      <br/>
      <p>This step will help you switch to a test version of Ethereum called <strong>Ropsten</strong>, where Ethers have no value.</p>
      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        <Button
          type="outline"
          text="Close"
          iconRight="cross lnr-075x"
          onClick={this.onExplainerDialogClose}
        />
        <Link to="/faq">
          <Button type="reveal" text="Learn more" />
        </Link>
      </div>
    </Dialog>
  );

  renderInstructions() {
    switch (this.props.providerName) {
      case 'metamask':
        return <MetamaskInstructions />;
      case 'toshi':
        return <CoinbaseWalletInstructions />;
      default:
        return <GenericInstructions />;
    }
  }

  render() {
    const { isExplainerDialogActive } = this.state;

    return (
      <div className="onboarding">

        <OnboardingMenu active={2} />

        <div className="onboarding--message">
          <p className="centre"><big>
            <strong>Welcome!</strong><br/>
            <span className="subtle">This is Pheme Aloha. For the actual platform, visit <strong><a href="https://pheme.app">pheme.app</a></strong></span>
          </big></p>

          <br/>

          <p>Please switch to Ropsten Test Network to continue in Aloha.</p>

          <br/>

          {this.renderInstructions()}

          <Button type="white-outline" text="Why?" onClick={this.onExplainerDialogOpen} />

          {this.renderExplainerDialog()}
        </div>

      </div>
    );
  }
};

const mapStateToProps = (state: AppState): Partial<Props> => ({
  providerName: state.app.providerName
})

export default connect(mapStateToProps)(WrongNetworkStepROPSTEN);

export default function makeCancelable<T>(promise: Promise<T>) {
  let isCanceled = false;

  const wrappedPromise: Promise<T> = new Promise((resolve, reject) => {
    promise.then(
      val => isCanceled ? reject({ isCanceled: true }) : resolve(val),
      error => isCanceled ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => { isCanceled = true },
  };
}
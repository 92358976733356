import * as React from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import { State as AppState } from 'stores';
import makeCancelable from 'lib/make-cancelable';
import debounce from 'lib/debounce';
import {
  HandleAvatar,
  HandleProfile,
  HandleDetails,
  loadHandle,
  buildUpdateProfile,
  cachedHandleSelector,
} from 'stores/content';
import PhemeService, { ITask } from 'services/pheme';
import { setTitle, resetTitle } from 'services/document-title';

import StoredImage from 'components/stored-image';
import Section from 'components/section';
import InfoBox from 'components/info-box';
import Field from 'components/field';
import Button from 'components/button';
import SceneLoader from 'components/scene-loader';
import FileInput from 'components/file-input';
import ImageInput from 'components/image-input';
import WithTaskEstimation from 'components/with-task-estimation';
import Form, { FormSnapshot } from 'components/form';
import LoadingIcon from 'components/loading-icon';

import ImageUploader from 'components/image-uploader';

type Props = RouteComponentProps<{ handle: string }> &
  HandleDetails & {
    isOwner: boolean;
    isCached: boolean;
    onLoadHandle: typeof loadHandle;
    goTo: typeof push;
    redirect: typeof replace;
    buildUpdateProfile: (handle: string, profile: HandleProfile) => any;
  };

interface State {
  isSubmitting: boolean;
  isLoading: boolean;
  profile: HandleProfile;
  task: ITask;
}

export class HandlesEditScene extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps = (nextProps: Props, state: State) => {
    const profile =
      !nextProps.isLoading && nextProps.isLoading !== state.isLoading
        ? nextProps.profile
        : state.profile;
    return { ...state, isLoading: nextProps.isLoading, profile };
  }
  cancelRedirect: () => void;

  profile: HandleProfile;

  state = {
    isSubmitting: false,
    isLoading: true,
    task: undefined,
    profile: { avatar: undefined, description: '' },
  };

  onFormPrepare = debounce(async () => {
    const handle = this.getHandle();
    const { buildUpdateProfile } = this.props;

    if (!handle) return undefined;

    const task = await buildUpdateProfile(handle, this.state.profile);
    return task.estimate().then(() => task);
  }, 250);
  getHandle = () => this.props.match.params.handle;

  componentDidMount() {
    if (!this.props.isOwner) {
      this.onIntrusion();
      return;
    }
    setTitle('Edit alias info');
    this.onLoad();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!this.props.isOwner) {
      this.onIntrusion();
      return;
    }

    if (prevProps.match.params.handle !== this.getHandle()) {
      this.onLoad();
    }
  }

  componentWillUnmount() {
    if (this.cancelRedirect) this.cancelRedirect();
    resetTitle();
  }

  onLoad() {
    this.props.onLoadHandle(this.getHandle());
  }

  onIntrusion() {
    this.props.redirect(`/@${this.getHandle()}`);
  }

  onSubmit = async () => {
    const { task } = this.state;
    if (!task) return;
    const handle = this.getHandle();

    this.setState({ isSubmitting: true });
    const { promise, cancel } = makeCancelable(
      task
        .execute()
        .then(() => {
          this.props.redirect(`/@${handle}`);
        })
        .catch(() => {
          this.setState({ isSubmitting: false });
        })
    );

    this.cancelRedirect = cancel;
  }

  onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.currentTarget;

    const { profile } = this.state;
    this.setState({ profile: { ...profile, description: value } });
  }

  onAvatarReady = (avatar: HandleAvatar) => {
    const { profile } = this.state;
    this.setState({ profile: { ...profile, avatar } });
  }

  onAvatarRemove = () => {
    const { profile } = this.state;
    this.setState({ profile: { ...profile, avatar: undefined } });
  }

  onFormUpdate = (e: FormSnapshot) => {
    this.setState({ task: e.preparation });
  }

  render() {
    const { isLoading } = this.props;
    const { isSubmitting, profile, task } = this.state;
    const { avatar, description } = profile;

    if (isLoading) {
      return (
        <Section>
          <SceneLoader />
        </Section>
      );
    }

    return (
      <Section state={isSubmitting && 'isLoading'}>
        <div className="nav-spacer" />

        <h1>Edit handle info</h1>

        <br />
        <br />

        <Field label="DESCRIPTION" iconLeft="pencil">
          <input
            defaultValue={description}
            className="field--fullwidth"
            type="text"
            onChange={this.onDescriptionChange}
            placeholder="Handle description..."
          />
        </Field>

        <br />

        <Field type="writer-photo">
          <ImageUploader
            versions={{
              small: { method: 'CROP', width: 128, height: 128 },
              large: { method: 'CROP', width: 320, height: 320 },
            }}
            image={avatar}
            onReady={this.onAvatarReady}
            onRemove={this.onAvatarRemove}
            label="Add avatar image"
          />
        </Field>

        <Form
          values={profile}
          prepare={this.onFormPrepare}
          onChange={this.onFormUpdate}
          render={({ status }) => (
            <React.Fragment>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '1.5rem 1.5rem -3.25rem 0',
                }}
              >
                <Button
                  state={Form.buttonState(isSubmitting ? 'LOADING' : status)}
                  text="SAVE"
                  onClick={this.onSubmit}
                  iconRight="cloud-sync lnr-15x"
                />
              </div>

              {status !== 'SAME' && (
                <WithTaskEstimation
                  task={task}
                  render={({ gasCost }) => (
                    <InfoBox
                      isEstimating={status === 'PREPARING'}
                      action="Updating your handle info"
                      gasCost={gasCost}
                    />
                  )}
                />
              )}
            </React.Fragment>
          )}
        />
      </Section>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => {
  const { handle } = props.match.params;
  const cachedDetails: HandleDetails = cachedHandleSelector(state.content, props.match.params);

  return {
    isOwner: state.user.handle === handle,
    ...cachedDetails,
    isLoading: !cachedDetails.isCached || cachedDetails.isLoading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo: push,
      redirect: replace,
      buildUpdateProfile,
      onLoadHandle: loadHandle,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HandlesEditScene);

import * as React from 'react';

import { connect } from 'react-redux';
import classnames from 'classnames';
import { State as AppState } from 'stores';
import { Transaction, transactionQueueSelector } from 'stores/transaction';
import { RouteComponentProps } from 'react-router';
import './styles.scss';

type Props = {
  transactions: Transaction[],
 } & RouteComponentProps<{}>;

export const PageStateMessage = (props: Props) => {
  if (!props.transactions.length) return null;

  return (
    <div className="page-state-message">
      <i className="lnr lnr-sync" />
      <span>There is a newer version of this page available; content will be shown automatically once it's processed</span>
   </div>
  );
};

const mapStateToProps = (state: AppState, props: Partial<Props>) => ({
  transactions: transactionQueueSelector(state)
    .filter(tx => tx.state === 'PROCESSING')
    .filter(tx => tx.path === props.location.pathname.replace(/\/$/,''))
});

export default connect(mapStateToProps)(PageStateMessage);
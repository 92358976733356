import * as React from 'react';
import classnames from 'classnames';
import './styles.scss';

type Props = {
  children?: React.ReactNode;
  active?: boolean;
  isNarrow?: boolean;
  onBlur?: () => void;
};

export default class Dialog extends React.PureComponent<Props> {
  render() {
    const { children, onBlur, active, isNarrow } = this.props;

    return (
      <div className={classnames('dialog-wrapper', { isActive: active })}>
        <div className="dialog-close" onClick={onBlur}></div>
        <div className={classnames('dialog', { 'dialog--narrow': isNarrow })}>{children}</div>
      </div>
    );
  }
}

import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import './styles.scss';

type Props = { active?: number };

const Section = (props: Props) => (
  <ul className="onboarding--menu">
    <li>
      <div className={classnames('onboarding--step', { 'active': props.active === 1 })}>
        <i className="lnr lnr-cloud-download lnr-2x"></i>
        {props.active > 1 && <span className="onboarding--checkmark">
          <i className="lnr lnr-check lnr-075x"></i>
        </span>}
        WALLET
      </div>
    </li>
    { props.active === 2 &&
      <li>
        <div className={classnames('onboarding--step', { 'active': props.active === 2 })}>
          <i className="lnr lnr-network lnr-2x"></i>
          {props.active > 2 && <span className="onboarding--checkmark">
            <i className="lnr lnr-check lnr-075x"></i>
          </span>}
          NETWORK
        </div>
      </li>
    }
    <li>
      <div className={classnames('onboarding--step', { 'active': props.active === 3 })}>
        <i className="lnr lnr-wallet lnr-2x"></i>
        {props.active > 3 && <span className="onboarding--checkmark">
          <i className="lnr lnr-check lnr-075x"></i>
        </span>}
        CREDITS
      </div>
    </li>
  </ul>
);

export default Section;

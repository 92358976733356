import * as React from 'react';
import { Link } from 'react-router-dom';

import LOGO_URL from 'static/images/app-icons/app-icon.png';

import OnboardingMenu from 'components/onboarding-menu';
import Button from 'components/button';
import Dialog from 'components/dialog';

interface State {
  isExplainerDialogActive?: boolean;
}

interface Props {
  onClick?: () => void;
}

export default class StartStep extends React.PureComponent<Props, State> {
  state = {
    isExplainerDialogActive: false,
  };

  renderExplainerDialog = () => (
    <Dialog active={this.state.isExplainerDialogActive} onBlur={this.onExplainerDialogClose}>
      <h3>Let’s talk about the magic of web3.</h3>
      <br />
      <p>
        Pheme is built on the <strong>Ethereum</strong> network, so that you can;
      </p>
      <br />
      <ul>
        <li>
          <p>Gift and receive gifts globally.</p>
        </li>
        <br />
        <li>
          <p>Have true ownership over your publications.</p>
        </li>
        <br />
        <li>
          <p>Access all your memories even if this website shuts down one day.</p>
        </li>
        <br />
      </ul>
      <p>
        You need a digital wallet capable of handling Ethereum transactions. We will help you find
        one and put some Ether in it.
      </p>
      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        <Button
          type="outline"
          text="Close"
          iconRight="cross lnr-075x"
          onClick={this.onExplainerDialogClose}
        />
        <Link to="/faq">
          <Button type="reveal" text="Learn more" />
        </Link>
      </div>
    </Dialog>
  );

  onExplainerDialogClose = () => {
    this.setState({ isExplainerDialogActive: false });
  };

  onExplainerDialogOpen = () => {
    this.setState({ isExplainerDialogActive: true });
  };

  render() {
    const { onClick } = this.props;

    return (
      <div className="onboarding">
        <OnboardingMenu />

        <div className="onboarding--message">
          <img src={LOGO_URL} alt="" style={{ width: '2rem' }} />

          <p className="centre" style={{ margin: '0.5rem 0' }}>
            <big>If you want to post something, you have to connect a wallet.</big>
          </p>

          <p className="centre subtle">
            <big>Don’t worry, it’s an easy process.</big>
          </p>

          <br />

          <div>
            <Button type="white" onClick={onClick} text="OK!" />
            <Button type="white-outline" text="Why?" onClick={this.onExplainerDialogOpen} />
          </div>

          {this.renderExplainerDialog()}
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import classnames from 'classnames';
import './styles.scss';

import getTransactionMessage from 'lib/get-transation-message';

import { State as AppState } from 'stores';
import { toggleTransactions, transactionQueueSelector, Transaction } from 'stores/transaction';

import { Link } from 'react-router-dom';
import RelativeTimestamp from 'components/relative-timestamp';

type Props = {
  onToggleTransactions: typeof toggleTransactions,
  transactions: Transaction[],
  isExpanded: boolean,
};

export function MessageBar(props: Props) {
  const { isExpanded, transactions, onToggleTransactions } = props;

  const messageBarClassName = classnames('message-bar', { 'message-bar__expanded': isExpanded });

  return (
    <div className={messageBarClassName}>
      <div className="message-bar-list">
        {transactions.slice(0).reverse().map(transaction => (
          <Link to={transaction.path} key={transaction.uuid} className={`message-bar-item message-bar-item__${transaction.state.toLowerCase()}`}>

            <i className="lnr lnr-broadcast lnr-15x"></i>

            <div className="middle">
              <h4 title={transaction.uuid }>{getTransactionMessage(transaction)}</h4>
              <small><RelativeTimestamp date={moment(transaction.createdAt).format()} /></small>
            </div>

            <div className="steps">
             <div className="steps-step steps-step0" />
             <div className="steps-step steps-step1" />
             <div className="steps-step steps-step2" />
            </div>

          </Link>
        ))}
      </div>
      <div onClick={onToggleTransactions} className="message-bar-toggler">
        <i className="lnr lnr-inbox"></i>
        <i className="lnr lnr-cross"></i>
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  transactions: transactionQueueSelector(state),
  isExpanded: state.transaction.isExpanded,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onToggleTransactions: toggleTransactions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MessageBar);
import * as React from 'react';
import { Link } from 'react-router-dom';
import LOGO_URL from 'static/images/app-icons/app-icon.png';

import OnboardingMenu from 'components/onboarding-menu';
import Button from 'components/button';

export default function MissingWeb3Step() {
  return (
    <div className="onboarding">

      <OnboardingMenu active={4} />

      <div className="onboarding--message">

        <img src={LOGO_URL} alt="" style={{ width: '2rem' }} />

        <br/>

        <p className="centre"><big>
          Well done!<br/>
          <span className="subtle">Now go post something.</span>
        </big></p>

        <br/>

        <a href={window.location.href} className="button button--white">Continue</a>
      </div>

    </div>
  );
}

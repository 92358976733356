import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { State as AppState } from 'stores';
import { ProviderName } from 'services/web3';
import * as clipboard from 'services/clipboard';

import OnboardingMenu from 'components/onboarding-menu';
import SVGIconSettings from 'components/svg-icon-settings';
import SVGIconDapps from 'components/svg-icon-dapps';
import SVGIconMetamask from 'components/svg-icon-metamask';
import SVGIconMainnet from 'components/svg-icon-mainnet';
import SVGIconRopsten from 'components/svg-icon-ropsten';
import CopyLink from 'components/copy-link';
import Button from 'components/button';
import Dialog from 'components/dialog';

type Props = { providerName: ProviderName };

type State = { isExplainerDialogActive: boolean };

const MetamaskInstructions = () => {
  return (
    <React.Fragment>
      <ol>
        <li>Open Metamask by clicking the <SVGIconMetamask size={16} /> icon next to your address bar.</li>
        {/* TODO (Nice to have) detect which network the user is actually on */}
        <li>Click on <SVGIconRopsten /> <strong>Ropsten Test Network</strong> to view the list of networks.</li>
        <li>Select <SVGIconMainnet /> <strong>Main Ethereum Network</strong>.</li>
        <li>Refresh this page.</li>
      </ol>
    </React.Fragment>
  );
};

const CoinbaseWalletInstructions = () => {
  return (
    <React.Fragment>
      <ol>
        <li>Go to <SVGIconSettings /> tab within Coinbase Wallet</li>
        <li>Advanced Settings <i className="lnr lnr-chevron-right lnr-075x"></i> Active Network <i className="lnr lnr-chevron-right lnr-075x"></i> Select <strong>Mainnet</strong> under Ethereum</li>
        <li>Come back here to <SVGIconDapps /> tab and refresh this page.</li>
      </ol>
    </React.Fragment>
  );
};

const GenericInstructions = () => {
  return (
    <React.Fragment>
      <ol>
        <li>Locate the advanced settings tab within your wallet application</li>
        <li>Switch to <strong>Mainnet</strong></li>
      </ol>
    </React.Fragment>
  );
};

export class WrongNetworkStep extends React.PureComponent<Props>{

  renderInstructions() {
    switch (this.props.providerName) {
      case 'metamask':
        return <MetamaskInstructions />;
      case 'toshi':
        return <CoinbaseWalletInstructions />;
      default:
        return <GenericInstructions />;
    }
  }

  render() {

    return (
      <div className="onboarding">

        <OnboardingMenu active={2} />

        <div className="onboarding--message">
          <p className="centre"><big>
            <strong>Welcome!</strong><br/>
            <span className="subtle">It seems like you are on an incorrect Ethereum network.</span>
          </big></p>

          <br/>

          <p>Please switch back to Mainnet to continue.</p>

          <br/>

          {this.renderInstructions()}

        </div>

      </div>
    );
  }
};

const mapStateToProps = (state: AppState): Partial<Props> => ({
  providerName: state.app.providerName
})

export default connect(mapStateToProps)(WrongNetworkStep);

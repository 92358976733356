import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { replace } from 'connected-react-router';
import PhemeService, { ITask } from 'services/pheme';
import { setTitle, resetTitle } from 'services/document-title';

import { State as AppState } from 'stores';
import {
  PostDetails,
  HandleDetails,
  buildPublishPost,
  loadHandle,
  cachedHandleSelector,
} from 'stores/content';

import SceneLoader from 'components/scene-loader';
import PostForm, { FormValues } from 'components/post-form';

import './styles.scss';

type Props = RouteComponentProps<{ handle: string }> &
  PostDetails & {
    handle: string;
    isNotAuthorized: boolean;
    isLoading: boolean;
    handleDetails: HandleDetails;
    redirect: typeof replace;
    onLoadHandle: typeof loadHandle;
    buildPublishPost: (handle: string, post: any) => any;
  };

interface State {}

export class PostsNewScene extends React.PureComponent<Props, State> {
  defaultTitle = 'Pheme';

  componentDidMount() {
    if (this.props.isNotAuthorized) {
      this.onIntrusion();
      return;
    }
    setTitle('New post');
    this.onLoad();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.isNotAuthorized) {
      this.onIntrusion();
      return;
    }

    if (prevProps.match.params.handle !== this.props.handle) {
      this.onLoad();
    }
  }

  componentWillUnmount() {
    resetTitle();
  }

  onLoad() {
    this.props.onLoadHandle(this.props.handle);
  }

  onIntrusion() {
    this.props.redirect(`/@${this.props.handle}`);
  }

  onFormPrepare = async (post: FormValues) => {
    const { buildPublishPost, handle } = this.props;
    const task = await buildPublishPost(handle, post);
    return task.estimate().then(() => task);
  }

  render() {
    const { isLoading, contentType } = this.props;
    if (isLoading) return <SceneLoader />;
    return (
      <PostForm
        formTitle={"Publish a new " + contentType}
        actionDescription="Publishing this post"
        actionVerb="Publish"
        {...this.props as any}
        prepare={this.onFormPrepare}
      />
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => {
  const { handle, contentType } = props.match.params;
  const handleDetails = cachedHandleSelector(state.content, props.match.params);
  const isNotAuthorized = handleDetails.isCached && state.user.handle !== handle;

  return {
    isLoading: !handleDetails.isCached && handleDetails.isLoading,
    isNotAuthorized,
    handle,
    handleDetails,
    contentType,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      redirect: replace,
      buildPublishPost,
      onLoadHandle: loadHandle,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostsNewScene);

import * as React from 'react';
import Footer from 'components/footer';
import Section from 'components/section';
import Card from 'components/card';

export default function PageFaqScene() {
  return (
    <React.Fragment>

    	<Section type="static-page">

        <div className="nav-spacer"></div>

    		<div className="content">
      		<div>
      			<h1>Frequently Asked Questions</h1>
      			<p>... And their answers, preferably.</p>
    			</div>
    			<div>
    				<i className="lnr lnr-3x lnr-question-circle"></i>
    			</div>
  			</div>

    	</Section>

    	<Section>


        <Card type="expandable" title="Why do I pay?">
          <p>To better explain the fees, we would love to briefly explain how Pheme works.</p>
          <p>When you post a story, <strong>IPFS</strong> encrypts your stories and images, divides them into tiny pieces, and distributes these pieces to many computers around the world. At the end of this process, <strong>IPFS</strong> provides Pheme with a tracking number, looking something like;</p>
          <p><em>QmYwAPJzv5CZsnA625s3Xf2nemtYgPpHdWEz79ojWnPbdG</em></p>
          <p>After receiving the tracking number to your post, Pheme stores it on the <strong>Ethereum</strong> blockchain to have 24/7 public access.</p>
          <p>First part of this process, <strong>IPFS</strong> encrypting and distributing your post, has no cost. But the second part, <strong>Ethereum</strong> storing your tracking number, requires thousands of computers to validate your tracking address and store it as securely as possible, hence requiring you to make a micro-payment using <strong>Ether</strong>. Since making a payment to a group of computers to store something for you is similar to putting gas into a car to take you places, this is commonly referred as <em>burning gas</em>. In this scenario the type of gas you burn is <strong>Ether</strong>, the fuel of web 3.0.</p>
        </Card>

        <Card type="comfortable">
					<h3>How much does Pheme collect from each payment?</h3>
	      	<br/>
					<p>Nothing. Pheme does not collect a share from your payments or any of your data, because it does not have to. It is a self-sustainable platform since all of it users pay for their own actions.</p>
				</Card>

        <Card type="comfortable">
					<h3>Why do I have to wait after every action?</h3>
	      	<br/>
					<p>Ethereum is not only a blockchain but also a global super computer, which receives hundreds of requests per second. On average, it processes 200-225 request every 15 seconds. This ritual of processing requests in batches is also referred as 'Mining a Block'. </p>
          <p>As soon as you make a request, it gets queued along with others and waits to be 'Mined'. Depending on the congestion of the network this might take anywhere between 15 seconds to (although unlikely) many hours.</p>
				</Card>

      	<Card type="comfortable">
		    	<h3>What is an Alias?</h3>
	      	<br/>
		    	<p>In Pheme, aliases are usernames.</p>
      	</Card>

        <Card type="comfortable">
		    	<h3>How can I get in touch?</h3>
	      	<br/>
		    	<p>Send us an e-mail! We would love to hear from you! <a href="mailto:aloha@pheme.app">aloha@pheme.app</a></p>
      	</Card>


    	</Section>

      <Footer />
    </React.Fragment>
  );
};

import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import './styles.scss';

type Props = {
  children?: React.ReactNode,
}

export const Footer = (props: Props) => {
  return (
    <footer className="footer">

      <ul className="footer__menu">
        <li><Link to="/">
          <i className="lnr lnr-15x lnr-news"></i>
          Feed
         </Link></li>
        <li><Link to="/faq">
          <i className="lnr lnr-15x lnr-question-circle"></i>
          FAQ
         </Link></li>
        <li><Link to="/terms">
          <i className="lnr lnr-15x lnr-document"></i>
          Terms
         </Link></li>
        <li><Link to="/privacy">
          <i className="lnr lnr-15x lnr-key-hole"></i>
          Privacy
         </Link></li>
      </ul>

      { props.children }

      <div className="footer__colophon">
        <a href="https://dcntrlzd.io/" target="_blank">Crafted by dcntrlzd</a>
        <span className="separator">|</span>
        <a href="mailto:aloha@pheme.app" target="_blank">
          <i className="lnr lnr-envelope" style={{ marginRight: '0.5rem'}}></i>
          Feedback? Let us know!
        </a>
      </div>

    </footer>
  );
};

export default Footer;

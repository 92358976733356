import * as React from 'react';
import { Editor as DraftEditor, EditorState, RichUtils, convertFromRaw, convertToRaw, getVisibleSelectionRect } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

import classnames from 'classnames';
import debounce from 'lib/debounce';
import './styles.scss';

type Position = { top: string, left: string };
type Props = { value: string, contentType: string, onChange: (string) => void };
type State = { editorState: any, isMenuVisible: boolean, menuPosition: Position };

const createEditorStateFromMarkdown = (markdown: string = '') =>
  EditorState.createWithContent(convertFromRaw(markdownToDraft(markdown)));

const createMarkdownFromEditorState = (editorState: any) =>
  draftToMarkdown(convertToRaw(editorState.getCurrentContent()));

export default class Editor extends React.PureComponent<Props, State> {
  editor: HTMLDivElement;
  menu: HTMLDivElement;

  state = {
    editorState: createEditorStateFromMarkdown(this.props.value),
    isMenuVisible: false,
    menuPosition: undefined,
  }

  componentDidMount() {
    this.notify();
  }

  componentDidUpdate() {
    this.notify();
  }

  notify = debounce(() => {
    const markdown = createMarkdownFromEditorState(this.state.editorState).replace(/\s*$/m,'');
    this.props.onChange(markdown);
  }, 250);

  onChange = (editorState) => {
    this.setState({ editorState })
  }

  buildInlineStyleToggler(type: string) {
    return (e) => {
      e.preventDefault();
      this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, type));
    }
  }

  buildBlockTypeToggler(type: string) {
    return (e) => {
      e.preventDefault();
      this.onChange(RichUtils.toggleBlockType(this.state.editorState, type));
    }
  }

  onEditorSelect = () => {
    const { editorState } = this.state;

    const selectionRect: ClientRect = getVisibleSelectionRect(window);

    if (!selectionRect || selectionRect.width <= 1) {
      this.onMenuHide();
      return;
    }

    const editorRect: ClientRect = this.editor.getBoundingClientRect();
    const menuRect: ClientRect = this.menu.getBoundingClientRect();

    this.onMenuShow({
      left: `${selectionRect.left - editorRect.left}px`,
      top: `${selectionRect.top - editorRect.top - menuRect.height}px`,
    });
  }

  onEditorBlur = (e: React.FormEvent<HTMLInputElement>) => {
    this.onMenuHide();
  }

  onMenuHide = () => {
    this.setState({ isMenuVisible: false, menuPosition: { top: undefined, left: undefined } });
  }

  onMenuShow = (menuPosition: { top: string, left: string}) => {
    this.setState({ isMenuVisible: true, menuPosition });
  }

  render() {
    const { isMenuVisible, menuPosition, editorState } = this.state;
    const { contentType } = this.props;
    const inlineStyle = editorState.getCurrentInlineStyle();
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <React.Fragment>
      { contentType === "story" ? (
        <div ref={(editor) => { this.editor = editor}} className="editor" onKeyUp={this.onEditorSelect} onMouseUp={this.onEditorSelect}>
          <div style={menuPosition} className={classnames('editor-menu', { 'editor-menu--visible': isMenuVisible })} ref={(menu) => { this.menu = menu}}>
            <button className={inlineStyle.has('BOLD') ? 'active' : ''} onMouseDown={this.buildInlineStyleToggler('BOLD')}><i className="lnr lnr-bold"/></button>
            <button className={inlineStyle.has('ITALIC') ? 'active' : ''} onMouseDown={this.buildInlineStyleToggler('ITALIC')}><i className="lnr lnr-italic"/></button>
            <button className={inlineStyle.has('UNDERLINE') ? 'active' : ''} onMouseDown={this.buildInlineStyleToggler('UNDERLINE')}><i className="lnr lnr-underline"/></button>
            <button className={inlineStyle.has('STRIKETHROUGH') ? 'active' : ''} onMouseDown={this.buildInlineStyleToggler('STRIKETHROUGH')}><i className="lnr lnr-strikethrough"/></button>
            <button className={inlineStyle.has('CODE') ? 'active' : ''} onMouseDown={this.buildInlineStyleToggler('CODE')}><i className="lnr lnr-code"/></button>
            <span className="separator"></span>
            <button className={blockType === 'header-one' ? 'active' : ''} onMouseDown={this.buildBlockTypeToggler('header-one')}>h1</button>
            <button className={blockType === 'header-two' ? 'active' : ''} onMouseDown={this.buildBlockTypeToggler('header-two')}>h2</button>
            <button className={blockType === 'header-three' ? 'active' : ''} onMouseDown={this.buildBlockTypeToggler('header-three')}>h3</button>
            <button className={blockType === 'paragraph' ? 'active' : ''} onMouseDown={this.buildBlockTypeToggler('paragraph')}><i className="lnr lnr-pilcrow"/></button>
            <span className="separator"></span>
            <button className={blockType === 'ordered-list-item' ? 'active' : ''} onMouseDown={this.buildBlockTypeToggler('ordered-list-item')}><i className="lnr lnr-list2"/></button>
            <button className={blockType === 'unordered-list-item' ? 'active' : ''} onMouseDown={this.buildBlockTypeToggler('unordered-list-item')}><i className="lnr lnr-list3"/></button>
            <button className={blockType === 'blockquote' ? 'active' : ''} onMouseDown={this.buildBlockTypeToggler('blockquote')}><i className="lnr lnr-quote-open"/></button>
          </div>
          <DraftEditor editorState={editorState} onChange={this.onChange} onBlur={this.onEditorBlur} placeholder="Start writing your story here..." />
        </div>
      ) : (
          <DraftEditor editorState={editorState} onChange={this.onChange} onBlur={this.onEditorBlur} placeholder="Description" />
      ) }

      </React.Fragment>
    )
  }
}

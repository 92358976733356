import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import * as ethers from 'ethers';
import QRCode from 'qrcode.react';

import shallowEqual from 'fbjs/lib/shallowEqual';
import InfoBox from 'components/info-box';
import WithTaskEstimation, { TaskEstimationStatus } from 'components/with-task-estimation';
import Button from 'components/button';
import TipBox, { TipMode, TipKey } from 'components/tip-box';
import Dialog from 'components/dialog';

import { ITask } from 'services/pheme';
import EndorsementsService, { Endorsement } from 'services/endorsements';
import ethToUsdService from 'services/eth-to-usd';

import { loadPost } from 'stores/content';
import { buildEndorse } from 'stores/endorsements';

import { Action, State as AppState } from 'stores';

interface Props {
  uuid: string;
  handle: string;
  currentUserHandle: string;
  currentUserAddress: string;
  onCancel?: () => any;
  onLoadPost: (handle: string, uuid: string) => any;
  onBuildEndorse: (handle: string, uuid: string, value: ethers.utils.BigNumber) => any;
  givenEndorsement?: Endorsement;
  isReadOnly: boolean;
  userHasNoBalance: boolean;
}

interface State {
  task: ITask;
  amount: number;
  amountInEther: number;
  mode: TipMode;
  key: TipKey;
  rate: number;
  isExplainerDialogActive: boolean;
  isHowDialogActive: boolean;
  isAmountValid: boolean;
}

const buttonStateFromTaskEstimationStatus = (status: TaskEstimationStatus) =>
  ({
    IDLE: 'isDisabled',
    ESTIMATING: 'isLoading',
  }[status]);

const TIP_LAYOUT = [[0.01, 0.1, 1], ['custom' as TipKey]];

const validateTipAmount = (input: number): number => {
  if (Number.isNaN(input)) throw new Error('Invalid input');
  if (input < 0) throw new Error('Invalid input');
  return input;
};

export class EndorseModal extends React.PureComponent<Props, State> {
  state: State = {
    rate: undefined,
    task: undefined,
    amount: undefined,
    mode: undefined,
    key: undefined,
    amountInEther: undefined,
    isExplainerDialogActive: false,
    isHowDialogActive: false,
    isAmountValid: true,
  };

  constructor(props: Props) {
    super(props);
    ethToUsdService().then(rate => this.setState({ rate }));
  }

  onTipChange = async (mode, unsafeAmount, key) => {
    const {
      state,
      props: { onBuildEndorse, handle, uuid },
    } = this;

    const oldTipState = { mode: state.mode, amount: state.amount, key: state.key };
    try {
      const amount = validateTipAmount(unsafeAmount);
      const newTipState = { mode, amount, key };

      if (shallowEqual(newTipState, oldTipState)) return;

      const amountInEther = Number(amount.toFixed(18));
      const etherAmount = amountInEther.toString();
      const amountInWei = ethers.utils.parseEther(etherAmount);

      const task: ITask = await onBuildEndorse(handle, uuid, amountInWei);

      this.setState({ ...newTipState, task, amountInEther, isAmountValid: true });
    } catch (e) {
      this.setState({ mode, key, task: undefined, isAmountValid: false });
    }
  };

  onSubmit = async () => {
    const { task } = this.state;
    const { onLoadPost, handle, uuid, onCancel } = this.props;

    await task.execute();
    await onLoadPost(handle, uuid);
    if (onCancel) onCancel();
  };

  onExplainerDialogClose = () => {
    this.setState({ isExplainerDialogActive: false });
  };

  onExplainerDialogOpen = () => {
    this.setState({ isExplainerDialogActive: true });
  };

  onHowDialogClose = () => {
    this.setState({ isHowDialogActive: false });
  };

  onHowDialogOpen = () => {
    this.setState({ isHowDialogActive: true });
  };

  renderDisabledHeader = () => (
    <React.Fragment>
      <h3>{this.props.isReadOnly ? 'No ' : 'Empty '} Wallet Detected</h3>

      <p style={{ margin: '1rem 0' }}>
        Glad you enjoyed this post by <strong>@{this.props.handle}</strong>. In order to
        send a gift to the publisher, you need to {this.props.isReadOnly ? 'connect a ' : 'fund your '} wallet.
      </p>
    </React.Fragment>
  );

  renderActiveHeader = () => (
    <React.Fragment>
      <h3>{this.props.givenEndorsement ? 'Gift more' : 'Send a gift'}</h3>

      <p style={{ margin: '1rem 0' }}>
        Glad you enjoyed this post by <strong>@{this.props.handle}</strong>. Help them
        publish their next post by gifting them a cup of coffee.
      </p>
    </React.Fragment>
  );

  renderExplainerDialog = () => (
    <Dialog active={this.state.isExplainerDialogActive} onBlur={this.onExplainerDialogClose}>
      <h3>Let’s talk about the magic of web3.</h3>
      <br />
      <p>
        Pheme is built on the <strong>Ethereum</strong> network, so that you can;
      </p>
      <br />
      <ul>
        <li>
          <p>Gift and receive gifts globally.</p>
        </li>
        <br />
        <li>
          <p>Have true ownership over your publications.</p>
        </li>
        <br />
        <li>
          <p>Access all your memories even if this website shuts down one day.</p>
        </li>
        <br />
      </ul>
      {this.props.isReadOnly ? (
        <p>
          You need a digital wallet capable of handling Ethereum transactions and we will help you
          find one.
        </p>
      ) : (
        <p>
          Your Ethereum wallet has no Ether in it and we will help you fund it.
        </p>
      )}
      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        <Button
          type="outline"
          text="Close"
          iconRight="cross lnr-075x"
          onClick={this.onExplainerDialogClose}
        />
        <Link to="/faq">
          <Button type="reveal" text="Learn more" />
        </Link>
      </div>
    </Dialog>
  );

  renderHowDialog = () => (
    <Dialog active={this.state.isHowDialogActive} onBlur={this.onHowDialogClose}>
      <h3>Fund your wallet.</h3>
      <br />
      <p>
        If you haven't done it before, getting your first Ether could be overwhelming.
        <br />
        <br />
        Below is your Ethereum address. Purchase some from an exchange and transfer it to this address. <Link to="/how-to" style={{ color: '#3333BF' }}>Learn more</Link>
      </p>
      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        <p>
          <strong>Your Ethereum Address</strong>
        </p>
      </div>
      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        <QRCode size={100} value={this.props.currentUserAddress} />
      </div>
      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        {/* TODO Make this a Copy button. */}
        <p><small><strong>{this.props.currentUserAddress}</strong></small></p>
      </div>
      <br/>

      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        <Button
          type="outline"
          text="Close"
          iconRight="cross lnr-075x"
          onClick={this.onHowDialogClose}
        />
      </div>
    </Dialog>
  );

  renderDisabledActions = () => (
    <div style={{ paddingBottom: '0.5rem' }} className="dialog__actions">
      {this.props.isReadOnly ? (
        <Link to={`/create-alias`} className={classnames('button')}>
          <span className="u-show-sm">Connect a wallet</span>
          <span className="u-hide-sm">Connect</span>
        </Link>
      ) : (
        <Button text="How?" onClick={this.onHowDialogOpen} />
      )}
      <Button text="Close" onClick={this.props.onCancel} type="white-bordered" />
      <Button type="reveal" text="Why?" onClick={this.onExplainerDialogOpen} />
    </div>
  );

  renderActiveActions = ({
    gasCost,
    status,
  }: {
    gasCost: ethers.utils.BigNumber;
    status: TaskEstimationStatus;
  }) => (
    <React.Fragment>
      <div className="dialog__actions">
        <Button text="Cancel" onClick={this.props.onCancel} type="white-bordered" />
        <Button
          text={this.props.givenEndorsement ? 'Gift more' : 'Gift'}
          onClick={this.onSubmit}
          state={buttonStateFromTaskEstimationStatus(status)}
        />
      </div>

      <div className="dialog__footer">
        <InfoBox
          action={'Sending this gift'}
          type="inline"
          gasCost={gasCost.toString()}
          value={this.state.amountInEther}
          serviceRate={EndorsementsService.getInstance().serviceRate}
          isEstimating={status === 'ESTIMATING'}
        >
          {!this.props.givenEndorsement && (
            <React.Fragment>
              {this.props.currentUserHandle ? (
                <p>
                  Your alias <strong>@{this.props.currentUserHandle}</strong> will show in the
                  gifters list attached to the post.
                </p>
              ) : (
                <p>
                  You are making an anonymous gift. If you want your username to show in the
                  gifters list attached to this post,{' '}
                  <Link to={`/create-alias`} target="_blank">
                    <strong>create an alias first</strong>
                  </Link>
                  {'.'}
                </p>
              )}
            </React.Fragment>
          )}
        </InfoBox>
      </div>
    </React.Fragment>
  );

  render() {
    const { isAmountValid, task, amount, key, rate } = this.state;
    const { isReadOnly, userHasNoBalance } = this.props;
    const isAmountSet = amount !== undefined;
    const isDisabled = isReadOnly || userHasNoBalance;

    return (
      <WithTaskEstimation
        task={task}
        render={({ gasCost, status }) => (
          <React.Fragment>
            {isDisabled ? this.renderDisabledHeader() : this.renderActiveHeader()}

            <div
              className="tip-boxes"
              style={isDisabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}
            >
              {TIP_LAYOUT.map((tipRow, tipRowIndex) => (
                <div className="tip-row" key={`tip-row-${tipRowIndex}`}>
                  {tipRow.map(tipKey => (
                    <TipBox
                      disabled={isDisabled || rate === undefined}
                      key={`tip-${tipKey}`}
                      amount={tipKey}
                      isActive={key === tipKey}
                      onChange={this.onTipChange}
                      size={
                        tipRow.includes(key) || (key === undefined && tipRowIndex === 0)
                          ? 'large'
                          : 'small'
                      }
                    />
                  ))}
                </div>
              ))}
            </div>

            {!isAmountValid && (
              <div className="dialog__feedback">
                <div className="message message--secondary message--multiline isActive">
                  <i className="lnr lnr-notification-circle" />
                  Whoops! Please enter a valid tip amount.
                </div>
              </div>
            )}

            {this.renderExplainerDialog()}

            {this.renderHowDialog()}

            {isDisabled && this.renderDisabledActions()}

            {isAmountSet && this.renderActiveActions({ gasCost, status })}
          </React.Fragment>
        )}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentUserHandle: state.user.handle,
  currentUserAddress: state.user.address,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onBuildEndorse: buildEndorse,
      onLoadPost: loadPost,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndorseModal);

import * as React from 'react';

import { HandleDetails } from 'stores/content';

import PostDisplay from 'components/post-display';
import PostFormEditStage from 'components/post-form/edit-stage';
import { FormValues } from 'components/post-form'

type Props = FormValues & {
  handleDetails: HandleDetails,
  handle: string,
  contentType: string,
};

export default function PostFormPreviewStage(props: Props) {
  return (
    <PostDisplay
      isCached
      address={undefined}
      isLoading={false}
      {...props}
    />
  );
}

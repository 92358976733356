import * as React from 'react';

import { ITask } from 'services/pheme';
import areEqual from 'fbjs/lib/areEqual';
import makeCancelable from 'lib/make-cancelable';

export type FormErrors<T> = Partial<{ [P in keyof T]?: string[] }>;

export type FormStatus = 'SAME' | 'PREPARING' | 'READY' | 'INVALID' | 'LOADING';
export type FormSnapshot<T = any, Y = any> = { status: FormStatus, values: T, preparation: Y, errors: FormErrors<T> };

type Props<T, Y> = {
  initialValues?: T,
  values: T,
  prepare?: (T) => Promise<Y>,
  validate?: (T) => FormErrors<T>,
  onChange?: (args: FormSnapshot<T, Y>) => void,
  render?: (args: FormSnapshot<T, Y>) => React.ReactNode,
};

type State<T, Y> = {
  status: FormStatus,
  preparation: Y,
  errors: FormErrors<T>,
  initialValues: T,
  currentValues: T,
};

export default class Form<T, Y> extends React.PureComponent<Props<T, Y>, State<T, Y>> {
  cancelValidation: () => void;
  cancelPreparation: () => void;
  isAvailable: boolean = true;

  static buttonState(status: FormStatus) {
    return {
      SAME: 'isDisabled',
      PREPARING: 'isLoading',
      READY: 'isReady',
      INVALID: 'isDisabled',
      LOADING: 'isLoading',
    }[status] || 'isLoading';
  }

  static statuses: { [key: string]: FormStatus } = {
    same: 'SAME',
    preparing: 'PREPARING',
    ready: 'READY',
    invalid: 'INVALID',
    isLoading: 'LOADING',
  };

  static defaultProps = {
    prepare: () => Promise.resolve(),
    validate: () => {},
  };

  constructor(props: Props<T, Y>) {
    super(props);

    this.state = {
      status: 'SAME',
      errors:  {},
      preparation: undefined,
      initialValues: props.initialValues || props.values,
      currentValues: props.values
    };
  }

  static getDerivedStateFromProps = (nextProps: Props<any, any>, state: State<any, any>) => {
    return { ...state, currentValues: nextProps.values };
  }

  componentWillUnmount() {
    const { cancelPreparation, cancelValidation } = this;
    this.isAvailable = false;

    if (cancelPreparation) cancelPreparation();
    if (cancelValidation) cancelValidation();
  }

  componentDidMount() {
    this.onChange();
    this.process()
  }

  componentDidUpdate(prevProps: Props<T, Y>, prevState: State<T, Y>) {
    if (!areEqual(this.state.currentValues, prevState.currentValues)) this.handleNewValues();
    if (this.state.status !== prevState.status) this.onChange();
  }

  private getFormSnapshot(): FormSnapshot<T, Y> {
    const { status, currentValues, errors, preparation } = this.state;
    return { status, errors, preparation, values: currentValues };
  }

  private onChange() {
    const { onChange } = this.props;
    if (onChange) onChange(this.getFormSnapshot());
  }

  private reset() {
    if (this.cancelPreparation) {
      this.cancelPreparation();
      this.cancelPreparation = undefined;
    }

    if (this.cancelValidation) {
      this.cancelValidation();
      this.cancelValidation = undefined;
    }
  }

  private handleNewValues = () => {
    if (!this.isAvailable) return;
    this.process();
  };

  private validate(): boolean {
    const { validate } = this.props;
    const { currentValues, initialValues } = this.state;

    if (areEqual(initialValues, currentValues)) {
      this.setState({ status: 'SAME', errors: {}, preparation: undefined });
      return false;
    }

    const errors = validate(currentValues) || {};

    if (Object.keys(errors).length) {
      this.setState({ status: 'INVALID', errors, preparation: undefined });
      return false;
    }

    return true;
  }

  private async process() {
    this.reset();

    const { prepare } = this.props;
    const { currentValues } = this.state;

    try {
      if (!this.validate()) return;
      this.setState({ status: 'PREPARING', errors: {}, preparation: undefined });

      const preparator = makeCancelable(prepare(currentValues));
      this.cancelPreparation = preparator.cancel;
      const preparation = await preparator.promise;

      this.setState({ status: 'READY', errors: {}, preparation });
    } catch (e) {
      if (!e.isCanceled) throw e;
    }
  }

  render() {
    const { render } = this.props;
    if (!render) return null
    return render(this.getFormSnapshot());
  }
}

type Bounds = { width?: number, height?: number };

export class ImageProcessor {
  private image: HTMLImageElement;

  private imageWidth: number;
  private imageHeight: number;
  private imageRatio: number;

  constructor(image: HTMLImageElement) {
    this.image = image;
    this.imageWidth = image.width;
    this.imageHeight = image.height;
    this.imageRatio = this.imageWidth / this.imageHeight;
  }

  fit({ width, height }: Bounds): Promise<Blob> {
    const { image, imageWidth, imageHeight, imageRatio } = this;

    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');

      let target: { width: number, height: number };

      if (width && !height) {
        target = { width, height: width / imageRatio };
      } else if (!width && height) {
        target = { width: height * imageRatio, height };
      } else if (width && height) {
        const targetHeight = width / imageRatio;
        const targetWidth = height * imageRatio;

        if (targetHeight <= height) {
          target = { width, height: width / imageRatio }
        } else if (targetWidth <= width) {
          target = { width: height * imageRatio, height };
        } else {
          reject();
        }
      } else {
        reject();
      }

      if (target.width > imageWidth || target.height > imageHeight) {
        resolve(null);
        return;
      }

      canvas.width = target.width;
      canvas.height = target.height;
      canvas.getContext('2d').drawImage(image, 0, 0, target.width, target.height);
      canvas.toBlob(resolve, 'image/jpeg');
    });
  }

  crop({ width, height }: Bounds): Promise<Blob> {
    const { image, imageWidth, imageHeight, imageRatio } = this;
    const targetRatio = width / height;

    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');

      let offset: { x: number, y: number } = { x: 0 , y: 0} ;
      let target: { width: number, height: number };

      const targetHeight = width / imageRatio;
      const targetWidth = height * imageRatio;

      if (targetHeight <= height) {
        target = { width: height * imageRatio, height };
        offset.x = (width - target.width) / 2;
      } else if (targetWidth <= width) {
        target = { width, height: width / imageRatio }
        offset.y = (height - target.height) / 2;
      } else {
        reject();
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, offset.x, offset.y, target.width, target.height);
      canvas.toBlob(resolve, 'image/jpeg');
    });
  }
}

export default class ImageProcessorService {
  static process(file: File): Promise<ImageProcessor> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = (event) => {
        const image = new Image();
        image.onload = () => resolve(new ImageProcessor(image))
        image.src = (event.target as any).result;
      }
      reader.readAsDataURL(file);
    });
  }
}

import * as React from 'react';
import { connect } from 'react-redux';

import { State as AppState } from 'stores';
import { UserStatus } from 'stores/user';

import StartStep from 'scenes/onboarding/generic/start';
import MissingWeb3Step from 'scenes/onboarding/generic/missing-web3';
import DoneStep from 'scenes/onboarding/generic/done';
import HasNoBalanceStepROPSTEN from 'scenes/onboarding/ropsten/has-no-balance';
import WrongNetworkStepROPSTEN from 'scenes/onboarding/ropsten/wrong-network';
import HasNoBalanceStep from 'scenes/onboarding/mainnet/has-no-balance';
import WrongNetworkStep from 'scenes/onboarding/mainnet/wrong-network';

type Props = {
  userStatus: UserStatus,
  children: React.ReactNode,
  hasOnboardingStarted: boolean,
};

type State = {
  hasOnboardingStarted: boolean,
  isDone: boolean,
};

export class RequiresAuthentication extends React.PureComponent<Props, State> {
  state = {
    hasOnboardingStarted: false,
    isDone: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.state,
      hasOnboardingStarted: props.hasOnboardingStarted,
      isDone: props.userStatus === 'DONE',
    };
  }

  onAcknowledge = () => {
    this.setState({ hasOnboardingStarted: true });
  }

  render() {
    const { hasOnboardingStarted, isDone } = this.state;
    const { userStatus, children } = this.props;

    if (isDone) {
      return children;
    } else if (!hasOnboardingStarted && userStatus === 'MISSING_WEB3') {
      return <StartStep status={userStatus} onClick={this.onAcknowledge} />;
    } else {
      switch (userStatus) {
        case 'WRONG_NETWORK':
          return <WrongNetworkStep />;
        case 'HAS_NO_BALANCE':
          return <HasNoBalanceStep />;
        case 'WRONG_NETWORK_ROPSTEN':
          return <WrongNetworkStepROPSTEN />;
        case 'HAS_NO_BALANCE_ROPSTEN':
          return <HasNoBalanceStepROPSTEN />;
        case 'DONE':
          return <DoneStep />;
        case 'BOOTING':
        case 'MISSING_WEB3':
        default:
          return <MissingWeb3Step />;
      }
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  userStatus: state.user.status,
  hasOnboardingStarted: state.router.location.hash === '#start',
});

export default connect(mapStateToProps)(RequiresAuthentication);

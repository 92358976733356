
let availableStorage: any;

const isFunctional = (storage) => {
  try {
    const key = 'test';
    storage.setItem(key, key);
    storage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

function getStorage(): Storage {
  if (availableStorage) return availableStorage;
  if (window.localStorage && isFunctional(window.localStorage)) {
    availableStorage = window.localStorage;
  } else {
    let data = {};

    availableStorage = {
      setItem: (key, value) => {
        data[key] = String(value);
        availableStorage.length = Object.keys(data).length;
      },
      getItem: (key) => data.hasOwnProperty(key) ? data[key] : undefined,
      removeItem: (key) => {
        delete data[key];
        availableStorage.length = Object.keys(data).length;
      },
      clear: () => {
        data = {};
        availableStorage.length = 0;
      },
      length: 0,
      key: (index: number) => Object.keys(data)[index],
    } as Storage;
  }

  return availableStorage;
}

export function set(key: string, value: any) {
  if (value === undefined) {
    getStorage().removeItem(key);
  } else {
    getStorage().setItem(key, JSON.stringify(value));
  }
}

export function get(key: string) {
  try {
    return JSON.parse(getStorage().getItem(key));
  } catch (e) {
    return;
  }
}
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { State as GlobalState } from 'stores';
import { listEndorsementsByContent, Container, Endorsement } from 'stores/endorsements';

type OwnProps = {
  handle: string;
  uuid: string;
  limit?: number;
  children: (props: {
    endorsements: Array<Endorsement>;
    totalCount: number;
    isLoading: boolean;
  }) => React.ReactNode;
};

type Props = OwnProps & {
  isLoading: boolean;
  totalCount: number;
  endorsements: Array<Endorsement>;
  listEndorsementsByContent: (handle: string, uuid: string, limit?: number) => any;
};

export class WithEndorsementsByContent extends React.PureComponent<Props> {
  componentDidMount() {
    const { handle, uuid, limit, listEndorsementsByContent } = this.props;
    listEndorsementsByContent(handle, uuid, limit);
  }

  componentDidUpdate(prevProps: Props) {
    const { handle, uuid, listEndorsementsByContent, limit } = this.props;
    if (prevProps.handle !== handle || prevProps.uuid !== uuid || prevProps.limit !== limit)
      listEndorsementsByContent(handle, uuid, limit);
  }

  render() {
    const { children, endorsements, totalCount, isLoading } = this.props;
    return children({ endorsements, totalCount, isLoading });
  }
}

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { handle, uuid } = props;
  // TODO: extract to a selector
  const key = `${handle}:${uuid}`;
  const lister = state.endorsements.listByContent[key];
  const counter = state.endorsements.countByContent[key];

  const isLoading = !lister || lister.isLoading;
  const endorsements = lister ? lister.value : [];
  const totalCount = counter ? counter.value : 0;

  return { isLoading, endorsements, totalCount };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      listEndorsementsByContent,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithEndorsementsByContent);

import * as ConfigServive from 'services/config';
import * as ethers from 'ethers';

import EndorsementsV1 from '@pheme-kit/ethereum/artifacts/abi/EndorsementsV1.json';
import { ITask, createTask, createTaskFromContractMethod } from '@pheme-kit/core/lib/task';

import Web3Service from 'services/web3';

export interface Endorsement {
  endorser: string;
  amount: ethers.utils.BigNumber;
  handle: string;
  uuid: string;
}

type CountMethod =
  | 'getEndorsementCountByContent'
  | 'getEndorsementCountByEndorser'
  | 'getEndorsementCountByHandle';

type ListMethod = 'getRecordIdByHandleAt' | 'getRecordIdByContentAt' | 'getRecordIdByEndorserAt';

const convertListMethodToCountMethod = (method: ListMethod): CountMethod => {
  switch (method) {
    case 'getRecordIdByHandleAt':
      return 'getEndorsementCountByHandle';
    case 'getRecordIdByContentAt':
      return 'getEndorsementCountByContent';
    case 'getRecordIdByEndorserAt':
      return 'getEndorsementCountByEndorser';
    default:
      throw new Error('Unknown list method');
  }
};

export default class EndorsementsService {
  static instance: EndorsementsService;

  static getInstance() {
    if (!this.instance) throw new Error('Not initialized yet.');
    return this.instance;
  }

  static async initialize(web3: Web3Service): Promise<EndorsementsService> {
    const networkId = `${process.env.NETWORK_ID}`;
    const {
      endorsements: { contractAddress },
    } = (ConfigServive.getState()[networkId] || {}) as any;
    if (!contractAddress) throw new Error('Registry contract not available.');

    const provider = web3.isReadOnly ? web3.provider : web3.provider.getSigner();
    const contract = new ethers.Contract(contractAddress, EndorsementsV1, provider);
    const serviceRate = (await contract.functions.calculateServiceFee(100)).toNumber() / 100;

    this.instance = new EndorsementsService(contract, serviceRate);

    return this.instance;
  }
  contract: ethers.Contract;
  readonly serviceRate: number;

  private constructor(contract: ethers.Contract, serviceRate: number) {
    this.contract = contract;
    this.serviceRate = serviceRate;
  }

  endorse(handle: string, uuid: string, value: ethers.utils.BigNumber) {
    const encodedHandle = ethers.utils.formatBytes32String(handle);
    return createTaskFromContractMethod(this.contract, 'endorse', [encodedHandle, uuid], { value });
  }

  loadEndorsementsBy = async (method: ListMethod, limit: number, ...params: any[]) => {
    const list = [];

    for (let i = 0; i < limit; i += 1) {
      const id = await this.contract[method](...[...params, i]);
      const [recordEndorser, recordHandle, recordUuid, recordAmount] = await Promise.all([
        this.contract.getEndorsementEndorser(id),
        this.contract.getEndorsementHandle(id),
        this.contract.getEndorsementUuid(id),
        this.contract.getEndorsementAmount(id),
      ]);

      list.push({
        endorser: recordEndorser,
        handle: ethers.utils.parseBytes32String(recordHandle),
        uuid: recordUuid,
        amount: recordAmount.toString(),
      });
    }

    return list;
  }

  loadAllEndorsementsBy = async (method: ListMethod, ...params: any[]) => {
    const count = await this.countEndorsementBy(convertListMethodToCountMethod(method), ...params);
    return this.loadEndorsementsBy(method, count, ...params);
  }

  countEndorsementBy = async (method: CountMethod, ...params: any[]) => {
    return this.contract[method](...params);
  }
}

import axios from 'axios';

type RateResponse = [{ current_price: number }];

const URL = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum';
const loader: Promise<{ data: RateResponse }> = axios.get(URL);

export default async function ethToUsd() {
  const { data } = await loader;
  return data[0].current_price;
}

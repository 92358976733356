import * as React from 'react';
import classnames from 'classnames';

import debounce from 'lib/debounce';

import './styles.scss';

export type TipMode = 'custom' | 'fixed';
export type TipKey = number | 'custom';

type Props = {
  amount: TipKey;
  disabled?: boolean;
  size?: string;
  isActive?: boolean;
  onChange?: (TipMode, number, TipKey) => any;
};

type State = {
  tipMode: TipMode;
  tipAmount: number;
  tipKey: TipKey;
};

export default class TipBox extends React.PureComponent<Props, State> {
  customInput: HTMLInputElement;

  state = { tipMode: 'fixed', tipAmount: 0 } as State;

  static getDerivedStateFromProps(props: Props, state: State) {
    const { amount } = props;

    const tipMode = amount === 'custom' ? 'custom' : 'fixed';
    const customAmount = props.isActive ? state.tipAmount : 0;
    const tipAmount = tipMode === 'custom' ? customAmount : amount;

    return { tipMode, tipAmount, tipKey: amount };
  }

  componentDidUpdate() {
    const { tipMode } = this.state;
    const { isActive } = this.props;
    if (tipMode === 'custom' && isActive && this.customInput) this.customInput.focus();
  }

  onChange = () => {
    const { onChange } = this.props;
    const { tipAmount, tipMode, tipKey } = this.state;

    if (onChange) onChange(tipMode, tipAmount, tipKey);
  };

  onCustomAmountUpdate = debounce((amount: number) => {
    this.setState({ tipAmount: amount }, this.onChange);
  }, 250);

  onCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    this.onCustomAmountUpdate(value);
  };

  render() {
    const { disabled, amount, size, isActive = false } = this.props;
    const { tipAmount } = this.state;

    return (
      <button
        onClick={this.onChange}
        disabled={disabled}
        className={classnames('tip-box', {
          ['tip-box--' + size]: size,
          ['tip-box--custom']: amount == 'custom',
          ['tip-box--active']: isActive,
        })}
      >
        {!amount ? (
          <span className="text">Cancel</span>
        ) : amount > 0 ? (
          <React.Fragment>
            <span>{amount}</span>{' '}
            <span className="currency">ETH</span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span className="text">Custom Amount</span>
            <br />
            {isActive && (
              <React.Fragment>
                <input
                  ref={customInput => {
                    this.customInput = customInput;
                  }}
                  type="number"
                  defaultValue={tipAmount > 0 ? tipAmount.toString() : ''}
                  onChange={this.onCustomAmountChange}
                />{' '}
                <span className="currency">ETH</span>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </button>
    );
  }
}

import * as React from 'react';
import Footer from 'components/footer';
import Section from 'components/section';
import Card from 'components/card';

export default function PageTermsScene() {
  return (
    <React.Fragment>

    	<Section type="static-page">

        <div className="nav-spacer"></div>

    		<div className="content">
      		<div>
      			<h1>Terms &amp; Conditions</h1>
      			<p>The small print.</p>
    			</div>
    			<div>
    				<i className="lnr lnr-3x lnr-document"></i>
    			</div>
  			</div>

    	</Section>

    	<Section>

      	<Card type="comfortable">
	      	<h3>Effective: June 29th, 2018</h3>

	      	<br/>
          
	      	<p>Aloha! Pheme is a decentralized publishing service powered by the Inter Planetary File System ("IPFS") and Ethereum Network ("Ethereum"). It allows you to publish written text, images, web links, information and other content ("User Content") to IPFS and store the location of your content on Ethereum. As it is a decentralized service, Pheme is not under control of any legal entity or private individual.</p>
          <p>dcntrlzd ("us", "we", "Company") has developed and will continue to develop Pheme but it does not operate nor control Pheme and/or content made available by its users. We only operate the web-interface located at URL https://aloha.pheme.app ("Service")</p>
          <p>Please read the following carefully before you access Pheme through this Service. These Terms of Service ("Terms") are a contract between you and dcntrlzd. By using Pheme through this Service, you signify that you have read, understood and agree to be bound by these Terms. If you don't agree to any of the Terms, do not use Pheme through this Service.</p>
          <p>Disputes about this contract or relating to the platform generally must be resolved by binding arbitration and on an individual basis only.</p>

          <br/>

          <h3>1. Privacy Policy</h3>
          <p>Pheme establishes trust through transparency. Any personal information you submit, any content you publish or any action you take using our Services will be stored on a public blockchain ("Ethereum") and will be visible to anyone with access to internet. Therefore the Service will never ask you to enter your name, e-mail address, phone number, physical address or any other personal information.</p>
          <p>Since Ethereum is an immutable ledger, you can not fully delete any content or information published from Pheme or fully edit any content or information published on Pheme.</p>
          <p><strong>If you do not agree to this public usage or lack of modifying & deleting, do not create an account or use any of our Services.</strong></p>
          <p>This Service does not collect any data besides your public Etherum Address when you are visiting it. You will not be tracked across the Internet. You will not be tracked within the Service.</p>
          <p>Please refer to our <a href='https://aloha.pheme.app/privacy'>Privacy Page</a> page for more details.</p>

          <br/>

          <h3>2. Eligibility</h3>
          <p>The Service is not targeted toward, nor intended for use by, anyone under the age of 18. You must be at least 18 years of age to access or use the Service.</p>

          <br/>

          <h3>3. User Content</h3>
          <p>Pheme does not claim ownership in any of the User Content you post. Any User Content that you make available on Pheme will belong to you, the User, or your licensors. We will not claim any ownership in your User Content and it is completely, 100% yours. By publishing you content through this Service, you grant us non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use your content for the purpose of its publication.</p>
          <p>You are responsible for the any content you post. This means you assume all risks related to it, including some else's reliance on its accuracy, or claims relating intellectual property or other legal rights. You are welcome to post content on Pheme that you've published elsewhere, as long as you have the rights you need to do so. By posting content through this Service, you represent that doing so does not conflict with any other agreement you have made.</p>
          <p>Be aware that Pheme is a decentralized service and that content published on Pheme is not under control of any legal entity or private individual. You can not fully delete any content published from Pheme or fully edit any content published on Pheme. When you remove content, your content is only delisted from the Service. When you edit content, the old version of your content is delisted from the Service and the new version is listed on the Service. Delisting makes it more difficult for your deleted or old content to be found for others. However, you understand that outdated or removed content persist without any time limitation and may still be available to others.</p>

          <br/>


          <h3>4. User Conduct</h3>
          <p>When accessing or using the Service, you agree that you will not commit any unlawful act, and that you are solely responsible for your conduct while using our Service.</p>
          <p>Without limiting the generality of the foregoing, you agree that you will not use our Service to publish following types of content, which are explicitly disallowed and will result in revocation of access to the Service and immediate delisting;</p>
          <p>
            i. Sexual depictions of children<br/>
        		ii. Content illegal in Germany and/or France, such as holocaust denial or Nazi symbolism<br/>
        		iii. Conduct promoting the ideology of National Socialism<br/>
        		iv. Racism or advocation of racism<br/>
        		v. Sexism or advocation of sexism<br/>
        		vi. Discrimination against gender and sexual minorities, or advocation thereof<br/>
        		vii. Xenophobic and/or violent nationalism
          </p>
          <p>Below actions will also result in revocation of access to the Service and immediate delisting;</p>
          <p>
            i. Violating or encouraging others to violate third party rights, including the infringement or misappropriation of intellectual property rights.<br/>
            ii. Publishing any User Content that is unlawful, defamatory, libelous, objectionable, profane, indecent, pornographic, harassing, threatening, hateful, or otherwise inappropriate.<br/>
            iii. Performing fraudulent activities, such as concealing that you are impersonating another person. Parody accounts are not okay. Be yourself, do not imitate someone else.
          </p>
          <p>We envision that updated version of the Service will contain self moderation functionalities enabling the community to keep the Service clean from any unlawful content and activities without any direct intervention from us.</p>

          <br/>


          <h3>5. Fees and Payments</h3>
          <p>You acknowledge that any action you take on Pheme will require you to pay a small amount of Ether ("ETH"). All payments you make will be collected by Ethereum Miners ("Burning Gas") in exchange to their computing power. The amount of ETH on your Ethereum Account remains always in your sole control.</p>
          <p>Pheme or dcntrlzd has no access to your Ethereum Account.</p>
          <p>Pheme or dcntrlzd does not collect any percentage of the payments. However we reserve the right to do so in future, and in such case any applicable fees will be displayed prior to you using any Service to which a fee applies.</p>
          <p>Below is the list of current available actions which will require you to burn gas:</p>
          <p>
            i. Creating an alias<br/>
            ii. Updating you alias profile<br/>
            iii. Publishing content<br/>
            iv. Editing ("delisting & re-listing") a publication<br/>
            v. Deleting ("delisting") a publication
          </p>
          <p>Please refer to section 3. User Content to better understand the concepts of delisting and re-listing.</p>

          <br/>


          <h3>6. Risks</h3>
          <p>You hereby acknowledge and agree that;</p>
          <p>
            i. You are accepting and using ETH, including in connection with the Ethereum Network, at your sole risk and expense<br/>
            ii. You have sufficient understanding of the risks, usage and complexity of cryptographic tokens such as ETH and software based on blockchain technology, such as Ethereum Network<br/>
            iii. Each of the dcntrlzd, its current and/or future subsidiaries, affiliates, directors, officers, employees, agents, representatives, and any other person or entity with any involvement in dcntrlzd shall have no responsibility or liability for the loss, transfer, misappropriation, theft, use or misuse of ETH or IPFS.
          </p>

          <br/>


          <h3>7. Disclaimer of Warranty</h3>
          <p>We provide the Service to you as is. You use it at your own risk and discretion. That means it does not come with any warranty. None express, none implied. No implied warranty of merchantability, fitness for a particular purpose, availability, security, title or non-infringement.</p>

          <br/>


          <h3>8. Limitation of Liability</h3>
          <p>We will not be liable to you for any damages that arise from your using the Service. This includes if the Service is hacked or unavailable. This includes all types of damages (exemplary, special, consequential, incidental or indirect) and it includes all kinds of legal claims, such as breach of warranty, tort, breach of contract or any other loss.</p>

          <br/>


          <h3>9. No Waiver</h3>
          <p>If we do not exercise a particular right under these Terms, that does not waive it.</p>

          <br/>


          <h3>10. Severability</h3>
          <p>If any provision of these terms is found invalid by a court of competent jurisdiction, you agree that the court should try to give effect to the parties' intentions as reflected in the provision and that other provisions of the Terms will remain in full effect.</p>

          <br/>


          <h3>11. Dispute Resolution</h3>
          <p>For any and all controversies, disputes, demands, claims, or causes of action between you and the dcntrlzd (including the interpretation and scope of section 6.iii) arising out of your acceptance or use of ETH, these Terms, or any other aspect of ETH, Ethereum Network or IPFS shall be subject to the exclusive jurisdiction of the courts of Brooklyn, New York. You agree that any suit arising from the Service must take place in a court located in Brooklyn, New York.</p>

      	</Card>

    	</Section>

      <Footer />
    </React.Fragment>
  );
};

import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import moment from 'moment';
import areEqual from 'fbjs/lib/areEqual';
import { ITask } from '@pheme-kit/core/lib/task';
import { IBlock } from '@pheme-kit/core';

import makeCancelable from 'lib/make-cancelable';
import PhemeService from 'services/pheme';
import * as LocalStorageService from 'services/local-storage';

import { State as AppState } from 'stores';
import {
  HandleDetails,
  Post,
  buildPublishPost,
  loadHandle,
  cachedHandleSelector,
} from 'stores/content';

import Section from 'components/section';
import Editor from 'components/editor';
import Field from 'components/field';
import Button from 'components/button';
import InfoBox from 'components/info-box';
import WithTaskEstimation from 'components/with-task-estimation';

import PostDisplay from 'components/post-display';
import Form, { FormSnapshot, FormErrors } from 'components/form';

import PostFormEditStage from './edit-stage';
import PostFormPreviewStage from './preview-stage';

import './styles.scss';

export type FormValues = Post;

type Stage = 'EDIT' | 'PREVIEW';

interface State {
  isSubmitting: boolean;
  task: ITask<[string, IBlock]>;
  stage: Stage;
  post: FormValues;
  errors: FormErrors<FormValues>;
  initialPost: FormValues;
}

type Props = FormValues & {
  handle: string;
  handleDetails: HandleDetails;
  contentType: string;
  goTo: typeof push;
  formTitle: string;
  actionVerb: string;
  actionDescription: string;
  draftKey?: string;
  prepare: (post: FormValues) => Promise<ITask<[string, IBlock]>>;
};

function getDraft(key: string): Partial<FormValues> {
  return LocalStorageService.get(key);
}

function setDraft(key: string, values: FormValues) {
  if (values === undefined) {
    LocalStorageService.set(key, values);
  } else {
    const draft = { ...values };
    delete draft.date;
    LocalStorageService.set(key, draft);
  }
}

export class PostForm extends React.PureComponent<Props, State> {

  static TYPE_ARTICLE = 'text/markdown';
  static TYPE_IMAGE = 'image';
  static stages: { [key: string]: Stage } = { edit: 'EDIT', preview: 'PREVIEW' };

  static defaultProps = {
    draftKey: 'postDraft',
    article: '',
    title: '',
    date: moment().format('YYYY-MM-DDTHH:mm'),
    coverImage: undefined,
  };

  static buildPostFromProps(props: Props): FormValues {
    return {
      article: props.article,
      title: props.title,
      coverImage: props.coverImage,
      date: props.date,
      type: props.contentType === "story" ? PostForm.TYPE_ARTICLE : PostForm.TYPE_IMAGE,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const initialPost = PostForm.buildPostFromProps(props);
    const draft = getDraft(props.draftKey) || {};
    const post = { ...initialPost, ...draft };

    return { ...state, post, initialPost };
  }
  cancelRedirect: () => void;

  state = {
    stage: PostForm.stages.edit,
    isSubmitting: false,
    task: undefined,
    errors: {} as FormErrors<FormValues>,
    post: undefined,
    initialPost: undefined,
  };

  componentWillUnmount() {
    if (this.cancelRedirect) this.cancelRedirect();
  }

  onFormPrepare = async () => {
    const { post } = this.state;
    const { prepare } = this.props;

    return prepare(post);
  }

  onSubmit = async () => {
    const { task } = this.state;
    if (!task) return;

    const { goTo, handle, draftKey } = this.props;

    this.setState({ isSubmitting: true });
    const { promise, cancel } = makeCancelable(
      task
        .execute()
        .then(([address, block]) => {
          setDraft(draftKey, undefined);
          goTo(`/@${handle}/${block.uuid}`);
        })
        .catch(() => {
          this.setState({ isSubmitting: false });
        })
    );

    this.cancelRedirect = cancel;
  }

  onChange = (post: FormValues) => {
    if (areEqual(this.state.post, post)) return;

    this.setState({ post });
    setDraft(this.props.draftKey, post);
  }

  onFormUpdate = (e: FormSnapshot) => {
    this.setState({ task: e.preparation, errors: e.errors });
  }

  onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};

    if (!values.title) errors.title = ['blank'];
    if (!values.article) errors.article = ['blank'];
    if (!values.date) errors.date = ['blank'];

    return errors;
  }

  onPreviewClick = () => {
    this.setState({ stage: PostForm.stages.preview }, () => window.scrollTo(0, 0));
  }

  onEditClick = () => {
    this.setState({ stage: PostForm.stages.edit }, () => window.scrollTo(0, 0));
  }

  render() {
    const { handleDetails, actionVerb, actionDescription } = this.props;
    const { stage, post, initialPost, isSubmitting, errors } = this.state;

    const stageProps = { ...this.props, ...post };
    const errorFields = Object.keys(errors);
    const errorMessage = errorFields.length
      ? `You must enter a ${errorFields.join(' & ')} before continuing.`
      : undefined;

    return (
      <div className="postForm">
        <div style={{ display: stage === PostForm.stages.edit ? 'block' : 'none' }}>
          <PostFormEditStage {...stageProps} errors={errors} onChange={this.onChange} />
        </div>

        <div style={{ display: stage === PostForm.stages.preview ? 'block' : 'none' }}>
          <PostFormPreviewStage {...stageProps} />
        </div>

        <Form
          values={post}
          initialValues={initialPost}
          prepare={this.onFormPrepare}
          validate={this.onFormValidate}
          onChange={this.onFormUpdate}
          render={({ status, preparation: task }) => (
            <div className="postForm-bottomPanel">
              <div style={{ position: 'relative', maxWidth: '46.76rem', margin: '0 auto' }}>
                <div className="postForm-bottomPanel-actions">
                  {stage === PostForm.stages.edit && (
                    <React.Fragment>
                      {errorMessage && (
                        <div className="postForm-bottomPanel-errors">{errorMessage}</div>
                      )}
                      <Button
                        state={['INVALID', 'SAME'].includes(status) ? 'isDisabled' : ''}
                        onClick={this.onPreviewClick}
                        text="Preview"
                      />
                    </React.Fragment>
                  )}
                  {stage === PostForm.stages.preview && (
                    <Button type="white" onClick={this.onEditClick} text="Edit" />
                  )}
                  {stage === PostForm.stages.preview && (
                    <Button
                      state={Form.buttonState(isSubmitting ? 'LOADING' : status)}
                      text={actionVerb}
                      onClick={this.onSubmit}
                      iconRight="cloud-sync lnr-15x"
                    />
                  )}
                </div>

                {status !== 'SAME' && stage === PostForm.stages.preview && (
                  <WithTaskEstimation
                    task={task}
                    render={({ gasCost }) => (
                      <InfoBox
                        isEstimating={status === 'PREPARING'}
                        type="docked"
                        action={actionDescription}
                        gasCost={gasCost}
                      />
                    )}
                  />
                )}
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo: push,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(PostForm);

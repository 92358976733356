import * as React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import './styles.scss';

import { State as AppState } from 'stores';

type Props = {
  isReadOnly: boolean,
  providerName: string,
}

export const Message = (props: Props) => {
  const { isReadOnly, providerName = '' } = props;
  return (
    <button className={classnames({ 'status-badge isActive': !isReadOnly, 'status-badge': isReadOnly })}>
      <span className="status-light"></span>
      <span className="status-descr">
        {isReadOnly ? 'Read-only ' : 'Connected ' }
        { providerName.length > 0 && (
          <React.Fragment>(via {providerName})</React.Fragment>
        )}
      </span>
    </button>
  );
};

const mapStateToProps = (state: AppState) => ({
  isReadOnly: state.app.isReadOnly,
  providerName: state.app.providerName,
});

export default connect(mapStateToProps)(Message);
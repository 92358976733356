import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HandleDetails, loadHandleByAddress, cachedHandleSelector } from 'stores/content';
import { getAnonymousIcon, getAnonymousName } from 'services/anonimity-generator';

type ProvidedProps = {
  displayName: string,
  icon: string,
  handle: string,
  isLoading: boolean,
  handleDetails: HandleDetails,
};

type Props = {
  address: string;
  handle: string;
  handleDetails: HandleDetails;
  loadHandleByAddress: (string) => any;
  children: (ProvidedProps) => React.ReactNode;
};

export class WithHandleByAddress extends React.PureComponent<Props> {
  componentDidMount() {
    const { address, loadHandleByAddress } = this.props;
    loadHandleByAddress(address);
  }

  componentDidUpdate(prevProps: Props) {
    const { address, loadHandleByAddress } = this.props;
    if (prevProps.address !== address) loadHandleByAddress(address);
  }

  render() {
    const { children, handle, handleDetails, address } = this.props;

    const displayName = handle ? `@${handle}` : (`Anonymous ` + getAnonymousName(address));
    const icon = getAnonymousIcon(address);
    const { isLoading = false } = handleDetails;

    return children({ handle, handleDetails, displayName, icon, isLoading });
  }
}

const mapStateToProps = (state, props) => {
  const handle = state.content.addressToHandleMap[props.address];
  const details = cachedHandleSelector(state.content, { handle });

  return {
    handle,
    handleDetails: details,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadHandleByAddress,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithHandleByAddress);

import * as React from 'react';
import classnames from 'classnames';

import { Link } from 'react-router-dom';
import Markdown from 'react-remarkable';

import { HandleDetails, PostDetails } from 'stores/content';

import StoredImage from 'components/stored-image';
import AbsoluteTimestamp from 'components/absolute-timestamp';
import Section from 'components/section';

type WithoutLoadingStates<T> = T & { isLoading?: boolean, isCached?: boolean };

export type Props = WithoutLoadingStates<PostDetails> & {
  handle: string,
  handleDetails: WithoutLoadingStates<HandleDetails>,
  children?: React.ReactNode,
  contentType: string,
};

export default function PostDisplay(props: Props) {
  const { coverImage, title, handle, date, children, article, contentType } = props;
  const isImage = contentType === "image";
  return (
    <React.Fragment>
      <section className={classnames("section section--post-header", {
        ['full']: isImage
      })}>

        { coverImage && (
          <div className={classnames('post-header-' + contentType)}>
            <StoredImage address={coverImage.large || coverImage.original} />
          </div>
        )}

        { !isImage &&
          <div className={classnames('post-header-story', { 'onImage': coverImage })}>
            <div className="row">
              <div className="col-xs-12 nav-spacer"></div>
              <div className={classnames('col-xs-12') }>
                <h1>{title}</h1>
              </div>
            </div>
          </div>
        }

      </section>

      <Section type="post-article">
        {children && <div className="post-article__top" children={children} />}

        {isImage && <h1>{title}</h1>}

        <div className="article">
          <Markdown options={{ breaks: true }} source={article} />
        </div>

        <div>
          {handle && (
            <Link to={'/@' + handle} className="author">
              <span style={{ opacity: 0.8 }}>@</span>{handle}
            </Link>
          )}

          {" | "}

          {date && <span className="date"><AbsoluteTimestamp date={date} /></span> }
        </div>

      </Section>
    </React.Fragment>
  );
}

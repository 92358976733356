import * as React from 'react';
import { Notification } from 'stores/transaction';
import classnames from 'classnames';
import './styles.scss';
import { Link } from 'react-router-dom';

import getTransactionMessage from 'lib/get-transation-message';


type Props = {
  onDisappear: () => any,
  notification: Notification,
}

type State = {
  isClosed: boolean,
}

const AUTO_DISAPPEAR_DURATION = 5000;

export default class MessageBarNotification extends React.PureComponent<Props, State> {
  autoDisappearTimeout: NodeJS.Timer;
  state = { isClosed: false };

  onClose = () => {
    if (this.state.isClosed) return;
    this.setState({ isClosed: true });
    setTimeout(this.props.onDisappear, 500);
  }

  componentDidMount = () => {
    this.autoDisappearTimeout = setTimeout(this.onClose, AUTO_DISAPPEAR_DURATION);
  }

  componentWillUnmount = () => {
    clearTimeout(this.autoDisappearTimeout);
  }

  getIconName = () => {
    const { transaction } = this.props.notification;
    return {
      'SUBMITTED': 'lnr-paper-plane',
      'PROCESSING': 'lnr-sync',
      'FAILED': 'lnr-warning',
      'REJECTED': 'lnr-cross',
      'PROCESSED': 'lnr-checkmark-circle',
    }[transaction.state];
  }

  getActionText = () => {
    const { transaction } = this.props.notification;
    return {
      'SUBMITTED': '',
      'FAILED': 'SEE DETAILS',
      'REJECTED': 'SEE DETAILS',
      'PROCESSED': 'VIEW POST',
    }[transaction.state];
  }

  render() {
    const { transaction } = this.props.notification;
    const icon = this.getIconName();
    const actionText = this.getActionText();
    const { isClosed } = this.state;

    const className = classnames('toast-message message', { 'isActive': !isClosed });

    return (
      <div className={className} onClick={this.onClose}>
        <i className={'lnr ' + icon}></i>
        <span className="text">
          {getTransactionMessage(transaction)}
          {(transaction.path && actionText) && (
            <Link to={transaction.path} className="link">{actionText}</Link>
          )}
        </span>
      </div>
    );
  }
}
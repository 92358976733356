import * as React from 'react';
import PhemeService from 'services/pheme';

export type FileInputStatus = 'IDLE' | 'READING' | 'UPLOADING' | 'READY';

type Props = {
  onFileReady?: (url: string, file?: File) => void,
  onStatusChange?: (status: FileInputStatus) => void,
};

type State = {
  status: FileInputStatus,
  file: File,
};

export default class FileInput extends React.PureComponent<Props> {
  state = { status: 'IDLE', file: undefined };

  private changeStatus(status: FileInputStatus) {
    const { onStatusChange } = this.props;
    this.setState({ status });
    if (onStatusChange) onStatusChange(status);
  }

  onFileReady = (url: string) => {
    const { onFileReady } = this.props;
    this.changeStatus('READY');
    if (onFileReady) onFileReady(url, this.state.file);
  }

  onFileUpload = async (reader: FileReader) => {
    this.changeStatus('UPLOADING');
    const data = Buffer.from(reader.result as ArrayBuffer);
    const url = await PhemeService.getInstance().storage.writeData(data);
    this.onFileReady(url);
  };

  onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.changeStatus('READING');
    event.stopPropagation();
    event.preventDefault();

    const fileList = event.target.files as FileList;
    const file = fileList[0];
    this.setState({ file });

    let reader = new FileReader();

    reader.onloadend = () => this.onFileUpload(reader);
    reader.readAsArrayBuffer(file);
  };

  render() {
    return <input type='file' className={`fileInput fileInput--${this.state.status.toLowerCase()}`} onChange={this.onFileSelect} />;
  }
}
import * as React from 'react';
import classnames from 'classnames';
import './styles.scss';

import SVGLoader from 'components/loading-icon';

type Props = {
  text?: string;
  iconLeft?: string;
  iconRight?: string;
  type?: string;
  size?: string;
  state?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const Button = ({ state, onClick, type, size, iconLeft, iconRight, text = '' }: Props) => (
  <button
    disabled={state === 'isLoading' || state === 'isDisabled'}
    onClick={onClick}
    className={classnames('button', {
      ['button--' + type]: type,
      ['button--' + size]: size,
      isLoading: state === 'isLoading',
    })}
  >
    {iconLeft && <i className={classnames('button__iconLeft', 'lnr', 'lnr-' + iconLeft)} />}

    <span className="text">{text || ''}</span>

    {iconRight && <i className={classnames('button__iconRight', 'lnr', 'lnr-' + iconRight)} />}

    {state === 'isLoading' && (
      <div className="button__overlay">
        <SVGLoader />
      </div>
    )}
  </button>
);

export default Button;

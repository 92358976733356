import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { replace, push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';
import parseUrl from 'pheme-url-parser';
import * as ethers from 'ethers';

import { State as AppState } from 'stores';
import {
  HandleDetails,
  PostDetails,
  loadPost,
  buildUnpublishPost,
  cachedHandleSelector,
  cachedPostSelector,
} from 'stores/content';

import { createGivenEndorsementSelector } from 'stores/user';

import PhemeService, { ITask } from 'services/pheme';
import { Endorsement } from 'services/endorsements';
import * as clipboard from 'services/clipboard';
import { setTitle, resetTitle } from 'services/document-title';

import Section from 'components/section';
import Avatar from 'components/avatar';
import WithHandleByAddress from 'components/with-handle-by-address';
import Button from 'components/button';
import Dropdown from 'components/dropdown';
import HashLabel from 'components/hash-label';
import Dialog from 'components/dialog';
import AbsoluteTimestamp from 'components/absolute-timestamp';
import Footer from 'components/footer';
import SceneLoader from 'components/scene-loader';
import Toggler from 'components/toggler';
import EndorseModal from 'components/endorse-modal';
import EndorsedByModal from 'components/endorsed-by-modal';
import WithEndorsementsByContent from 'components/with-endorsements-by-content';
import LoadingIcon from 'components/loading-icon';
import PostDisplay from 'components/post-display';

interface State {
  copyLinkActive?: boolean;
  copyHashActive?: boolean;
  hashDialogActive?: boolean;
  endorseModalActive?: boolean;
  endorsedByModalActive?: boolean;
}

type Props = RouteComponentProps<{ handle: string; uuid: string }> &
  PostDetails & {
    isOwner: boolean;
    handleDetails: HandleDetails;
    iOS: boolean;
    redirect: typeof replace;
    goTo: typeof push;
    onLoadPost: typeof loadPost;
    buildUnpublishPost: any; // (handle: string, uuid: string) => Promise<ITask>,
    givenEndorsement: Endorsement;
    contentType: string;
  };

export class PostsShowScene extends React.PureComponent<Props, State> {
  static defaultProps = {
    iOS: false,
  };

  state = {
    copyLinkActive: false,
    copyHashActive: false,
    hashDialogActive: false,
    endorseModalActive: false,
    endorsedByModalActive: false,
  };

  shareOptions = 'menubar=no,toolbar=no,resizable=yes,height=300,width=600,top=300,left=420';

  getHandle = () => this.props.match.params.handle;
  getUuid = () => this.props.match.params.uuid;
  getContentType = () => this.props.contentType;

  componentDidMount() {
    this.props.onLoadPost(this.getHandle(), this.getUuid());
    if (!this.props.isLoading) this.onArticleLoaded();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoading !== prevProps.isLoading) {
      this.onArticleLoaded();
    }
  }

  componentWillUnmount() {
    resetTitle();
  }

  onArticleLoaded = () => {
    const { title } = this.props;
    setTitle(title);
  }

  onUnpublishPost = async () => {
    const { buildUnpublishPost, redirect } = this.props;
    const task = await buildUnpublishPost(this.getHandle(), this.getUuid());

    await task.execute();
    redirect(`/@${this.getHandle()}`);
  }

  onEditPost = () => {
    this.props.goTo(`/@${this.getHandle()}/${this.getContentType()}/${this.getUuid()}/edit`);
  }

  // Tweet window
  onShareTweet = () => {
    const { title } = this.props;
    const url = `https://twitter.com/share?text=${encodeURIComponent(
      title
    )}&url=${encodeURIComponent(window.location.href)}`;
    window.open(url, '_blank', this.shareOptions);
  }

  // Facebook window
  onShareFbPost = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?&u=${encodeURIComponent(
      window.location.href
    )}`;
    window.open(url, '_blank', this.shareOptions);
  }

  // Telegram window
  onShareTelegram = () => {
    const { title } = this.props;
    const url = `https://telegram.me/share/url?url=${encodeURIComponent(
      window.location.href
    )}&text=${encodeURIComponent(title)}`;
    window.open(url, '_blank', this.shareOptions);
  }

  // Reddit window
  onShareReddit = () => {
    const { title } = this.props;
    const shareOptions =
      'menubar=no,toolbar=no,resizable=yes,height=540,width=600,top=150,left=420';
    const url = `https://www.reddit.com/submit?url=${encodeURIComponent(
      window.location.href
    )}&title=${encodeURIComponent(title)}`;
    window.open(url, '_blank', shareOptions);
  }

  // Copy Link
  onCopyLink = () => {
    const url = window.location.href;
    clipboard.copy(url);
    document.body.focus();
    this.setState({ copyLinkActive: true });
    setTimeout(
      function() {
        this.setState({ copyLinkActive: false });
      }.bind(this),
      1000
    );
  }

  // Copy IPFS Hash
  onCopyHash = () => {
    const { host: storageAddress } = parseUrl(this.props.address);
    const url = 'https://ipfs.io/ipfs/' + storageAddress;
    clipboard.copy(url);
    document.body.focus();
    this.setState({ copyHashActive: true });
    setTimeout(
      function() {
        this.setState({ copyHashActive: false });
      }.bind(this),
      1000
    );
  }

  // Open/Close hash info dialog
  onHashDialogClose = () => {
    this.setState({ hashDialogActive: false });
  }
  onHashDialogOpen = () => {
    this.setState({ hashDialogActive: true });
  }

  // Open/Close Endorse Modal
  onEndorseModalClose = () => {
    this.setState({ endorseModalActive: false });
  }
  onEndorseModalOpen = () => {
    this.setState({ endorsedByModalActive: false });
    this.setState({ endorseModalActive: true });
  }

  // Open/Close Endorser Modal
  onEndorsedByModalClose = () => {
    this.setState({ endorsedByModalActive: false });
  }
  onEndorsedByModalOpen = () => {
    this.setState({ endorsedByModalActive: true });
  }

  renderEndorserBadges = (limit: number) => (
    <WithEndorsementsByContent handle={this.getHandle()} uuid={this.getUuid()} limit={limit}>
      {({ endorsements, totalCount, isLoading }) =>
        isLoading ? (
          <div
            style={{
              paddingLeft: '0',
              textAlign: 'center',
            }}
            className="message message--small message--white isActive"
          >
            Getting gifts <LoadingIcon color="#CECED9" />
          </div>
        ) : totalCount > 0 ? (
          <React.Fragment>
            {
              <Dialog
                active={this.state.endorsedByModalActive}
                onBlur={this.onEndorsedByModalClose}
                isNarrow={true}
              >
                <WithEndorsementsByContent handle={this.getHandle()} uuid={this.getUuid()}>
                  {(completeList) => (
                    <EndorsedByModal
                      endorsements={completeList.endorsements}
                      onCancel={this.onEndorsedByModalClose}
                      onEndorseModalOpen={this.onEndorseModalOpen}
                      givenEndorsement={!!this.props.givenEndorsement}
                      isOwner={this.props.isOwner}
                    />
                  )}
                </WithEndorsementsByContent>
              </Dialog>
            }

            <div className="endorser-list" onClick={this.onEndorsedByModalOpen}>
              <span className="endorsed-by">Gifts by </span>

              {endorsements.slice(0, limit).map((endorsement, i) => (
                <WithHandleByAddress key={endorsement.endorser} address={endorsement.endorser}>
                  {({ handle, handleDetails, icon, displayName }) => (
                    <Avatar
                      size="small"
                      title={displayName}
                      placement="stacked"
                      icon={icon}
                      image={
                        handleDetails.profile.avatar ? handleDetails.profile.avatar.small : null
                      }
                    />
                  )}
                </WithHandleByAddress>
              ))}

              {totalCount > limit && (
                <span className="others">
                  and {totalCount - limit} other
                  {totalCount - limit !== 1 && <React.Fragment>s</React.Fragment>}
                </span>
              )}
            </div>
          </React.Fragment>
        ) : (
          <div /> // Shifts Endorse and Share buttons to the right.
        )
      }
    </WithEndorsementsByContent>
  )

  renderEndorserList = (limit: number) => (
    <WithEndorsementsByContent handle={this.getHandle()} uuid={this.getUuid()} limit={limit}>
      {({ endorsements, totalCount, isLoading }) =>
        isLoading ? (
          <div
            style={{
              padding: '0.5rem 0rem',
              backgroundColor: '#FFFFFF',
              color: '#CECED9',
            }}
            className="message isActive"
          >
            Getting gifts <LoadingIcon color="#CECED9" />
          </div>
        ) : totalCount > 0 ? (
          <React.Fragment>
            {endorsements.slice(0, limit).map((endorsement, i) => (
              <WithHandleByAddress key={endorsement.endorser} address={endorsement.endorser}>
                {({ handle, handleDetails, displayName, icon }) => (
                  <React.Fragment>
                    {handle ? (
                      <Link key={i} to={`/@${handle}`} className="endorser">
                        <Avatar
                          size="medium"
                          title={displayName}
                          placement="inline"
                          icon={icon}
                          image={
                            handleDetails.profile.avatar ? handleDetails.profile.avatar.small : null
                          }
                        />
                        <div className="endorser--handle">{displayName}</div>
                        <div className="endorser--amount">
                          {Number(ethers.utils.formatEther(endorsement.amount)).toFixed(4)}&nbsp;{'ETH'}
                        </div>
                      </Link>
                    ) : (
                      <div className="endorser">
                        <Avatar size="medium" title={displayName} icon={icon} placement="inline" />
                        <div className="endorser--handle">{displayName}</div>
                        <div className="endorser--amount">
                          {Number(ethers.utils.formatEther(endorsement.amount)).toFixed(4)}&nbsp;{'ETH'}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </WithHandleByAddress>
            ))}

            {totalCount > limit && (
              <div className="endorser others" onClick={this.onEndorsedByModalOpen}>
                <Avatar icon="ellipsis" size="medium" placement="inline" />
                <span className="and">
                  and {totalCount - (limit)} other
                  {totalCount - limit !== 1 && <React.Fragment>s</React.Fragment>}
                </span>
              </div>
            )}
          </React.Fragment>
        ) : (
          <div className="empty">This post has not received any gifts yet.</div>
        )
      }
    </WithEndorsementsByContent>
  )

  render() {
    const { article, isLoading, isOwner, iOS, date, givenEndorsement, isReadOnly, userHasNoBalance } = this.props;
    const {
      copyLinkActive,
      copyHashActive,
      hashDialogActive,
      endorseModalActive,
      endorsedByModalActive,
    } = this.state;

    if (!article && isLoading) return <SceneLoader />;

    const handleHash = this.props.handleDetails.owner;
    const handleProfile = this.props.handleDetails.profile;
    const { protocol: storageProtocol, host: storageAddress } = parseUrl(this.props.address);

    if (!article) return null;

    return (
      <React.Fragment>
        <PostDisplay {...this.props as any} handle={this.getHandle()}>
          <Dialog active={endorseModalActive} onBlur={this.onEndorseModalClose}>
            <EndorseModal
              uuid={this.getUuid()}
              handle={this.getHandle()}
              onCancel={this.onEndorseModalClose}
              givenEndorsement={givenEndorsement}
              isReadOnly={isReadOnly}
              userHasNoBalance = {userHasNoBalance}
            />
          </Dialog>

          {this.renderEndorserBadges(3)}

          <div className={classnames('article-actions', { 'article-actions--owner': isOwner })}>
            {isOwner ? (
              <React.Fragment>
                <Button
                  type="outline"
                  size="small button--to-circle-mobile"
                  text="Unpublish"
                  iconRight="trash"
                  onClick={this.onUnpublishPost}
                />
                <Button
                  type="outline"
                  size="small button--to-circle-mobile"
                  text="Edit"
                  iconRight="pencil"
                  onClick={this.onEditPost}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {givenEndorsement ? (
                  <Button text="Gift more" size="small button--to-circle-mobile" type="white-bordered" iconLeft="gift" onClick={this.onEndorseModalOpen} />
                ) : (
                  <Button text="Gift" size="small button--to-circle-mobile" iconLeft="gift" onClick={this.onEndorseModalOpen} />
                )}
              </React.Fragment>
            )}
            <Toggler>
              {({ onToggle, isActive }) => (
                <div
                  className={classnames('user-badge hasDropdown hasDropdown--no-caret', { isActive })}
                  style={{ marginLeft: '0.5rem' }}
                >
                  <Button type="reveal" size="small button--to-circle-mobile" text="Share" iconLeft="share2" onClick={onToggle} />
                  <Dropdown>
                    <li>
                      <button className="link" onClick={this.onShareTweet}>
                        <i className="lnr lnr-egg" /> Tweet
                      </button>
                    </li>
                    <li>
                      <button className="link" onClick={this.onShareFbPost}>
                        <i className="lnr lnr-surveillance" /> Facebook
                      </button>
                    </li>
                    <li>
                      <button className="link" onClick={this.onShareTelegram}>
                        <i className="lnr lnr-paper-plane" /> Telegram
                      </button>
                    </li>
                    <li>
                      <button className="link" onClick={this.onShareReddit}>
                        <i className="lnr lnr-bubble-dots" /> Reddit
                      </button>
                    </li>
                    {!iOS && (
                      <li>
                        <button className="link" onClick={this.onCopyLink}>
                          <i className={copyLinkActive ? 'lnr lnr-check' : 'lnr lnr-link'} />{' '}
                          {copyLinkActive ? 'Link copied' : 'Copy link\u00A0\u00A0\u00A0\u00A0'}
                        </button>
                      </li>
                    )}
                  </Dropdown>
                </div>
              )}
            </Toggler>
          </div>
        </PostDisplay>

        <Section type="post-meta">
          <div className="droplet">
            <i className="lnr lnr-drop lnr-2x" />
          </div>

          {!isOwner && (
            <div className="endorse-box">
              {givenEndorsement ? (
                <React.Fragment>
                  <div>
                    <h3>Want to gift more?</h3>
                    <p>
                      You've already sent a gift to the publisher for this post, but you can always give more!
                    </p>
                  </div>
                  <div className="endorse-button">
                    <Button iconLeft="gift" text="Gift more" type="outline" onClick={this.onEndorseModalOpen} />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div>
                    <h3>Enjoyed what you saw?</h3>
                    <p>
                      Send a gift to support the publisher and thereby all of independent publishing!
                    </p>
                  </div>
                  <div className="endorse-button">
                    <Button iconLeft="gift" text="Gift" onClick={this.onEndorseModalOpen} />
                  </div>
                </React.Fragment>
              )}
            </div>
          )}

          <div className="row row--nopadding row--meta">
            <div className="col-xs-12 col-sm-4 col-md-3">
              <label className="title">PUBLISHED BY</label>
            </div>
            <div className="col-xs-12 col-sm-8 col-md-9">
              <Link to={`/@${this.getHandle()}`} className="author-details">
                {handleProfile.avatar && (
                  <Avatar placement="inline" size="large" image={handleProfile.avatar.small} />
                )}
                <div>
                  <div className="author-handle">@{this.getHandle()}</div>
                  <div className="timestamp">
                    <AbsoluteTimestamp date={date} />
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="row row--nopadding row--meta">
            <div className="col-xs-12 col-sm-4 col-md-3">
              <label className="title">GIFTS BY</label>
            </div>
            <div className="col-xs-12 col-sm-8 col-md-9">
              <div className="endorsers">{this.renderEndorserList(3)}</div>
            </div>
          </div>

          <div className="row row--nopadding row--meta">
            <div className="col-xs-12 col-sm-4 col-md-3">
              <label className="title">SHARE</label>
            </div>
            <div className="col-xs-12 col-sm-8 col-md-9">
              <div className="actions">
                <Button type="outline" size="small" onClick={this.onShareTweet} text="Tweet" />
                <Button type="outline" size="small" onClick={this.onShareFbPost} text="Facebook" />
                <Button
                  type="outline"
                  size="small"
                  onClick={this.onShareTelegram}
                  text="Telegram"
                />
                <Button type="outline" size="small" onClick={this.onShareReddit} text="Reddit" />
                {!iOS && (
                  <Button
                    type="outline"
                    size="small"
                    onClick={this.onCopyLink}
                    text={copyLinkActive ? 'Link copied' : 'Copy link'}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="row row--nopadding row--meta">
            <div className="col-xs-12 col-sm-4 col-md-3">
              <label className="title">PERMALOCATION</label>
            </div>
            <div className="col-xs-12 col-sm-8 col-md-9">
              <HashLabel
                address={storageAddress}
                classes="light"
                type={storageProtocol}
                isExpandable={true}
              />
              {!iOS && (
                <button className="link" onClick={this.onCopyHash}>
                  {copyHashActive && <small>Copied </small>}
                  <i className={copyHashActive ? 'lnr lnr-check' : 'lnr lnr-link'} />
                </button>
              )}
            </div>
          </div>
        </Section>

        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => {
  const { handle, uuid } = props.match.params;

  const handleDetails: HandleDetails = cachedHandleSelector(state.content, props.match.params);
  const postDetails: PostDetails = cachedPostSelector(state.content, props.match.params);
  const contentType = postDetails.type === "text/markdown" ? "story" : "image"
  const isOwner = handleDetails && handleDetails.owner === state.user.address;
  const iOS = state.user.platform === 'IOS';
  const givenEndorsement = createGivenEndorsementSelector(handle, uuid)(state);
  const isReadOnly = state.app.isReadOnly;
  const userHasNoBalance = state.user.balance === 0;

  return {
    contentType,
    givenEndorsement,
    iOS,
    isOwner,
    isReadOnly,
    handleDetails,
    userHasNoBalance,
    ...postDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      redirect: replace,
      goTo: push,
      onLoadPost: loadPost,
      buildUnpublishPost,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostsShowScene);

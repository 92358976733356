import * as React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { State as AppState } from 'stores';
import { toggleWelcomeMessage, showWelcomeMessageSelector } from 'stores/user';
import { loadLatestPosts, PostSnippet } from 'stores/content';

import { Link } from 'react-router-dom';
import Section from 'components/section';
import Button from 'components/button';
import Card from 'components/card';
import List from 'components/list';
import Footer from 'components/footer';
import StoredImage from 'components/stored-image';
import RelativeTimestamp from 'components/relative-timestamp';
import PostCard from 'components/post-card';
import LoadingIcon from 'components/loading-icon';

type Props = {
  userHandle: string;
  latestPosts: PostSnippet[];
  loadLatestPosts: typeof loadLatestPosts;
  isLoadingLatestPosts: boolean;
  toggleWelcomeMessage: typeof toggleWelcomeMessage;
};

type State = {
  postCount: number;
  showWelcomeMessage: boolean;
};

const POSTS_IN_PAGE = 20;

export class LandingScene extends React.PureComponent<Props, State> {
  state = {
    postCount: POSTS_IN_PAGE,
    showWelcomeMessage: this.props.userHandle ? false : true
  };

  componentDidMount() {
    this.props.loadLatestPosts();
  }

  onWelcomeClose = () => {
    this.setState({ showWelcomeMessage: false });
  };

  onLoadMore = () => {
    const { postCount } = this.state;
    this.setState({ postCount: postCount + POSTS_IN_PAGE });
  };

  render() {
    const { latestPosts, isLoadingLatestPosts, userHandle } = this.props;
    const { showWelcomeMessage } = this.state;
    const icons = ['feather', 'toilet-paper', 'magic-wand', 'pencil-ruler', 'heart'];
    const randomIcon = icons[Math.round(Math.random() * 4)];

    const { postCount } = this.state;
    const showLoadMore = latestPosts.length > postCount;
    const paginatedPosts = latestPosts.slice(0, postCount);

    return (
      <React.Fragment>
        <header className={classnames('header--welcome', { isActive: showWelcomeMessage })}>
          {showWelcomeMessage && <div className="nav-spacer" />}

          <div className="header--welcome__content">
            <div className="row" style={{ maxWidth: '48rem' }}>
              <div className="col-xs-12">
                <h1>A platform built for your long-term memories.</h1>
                <br />
                <h3>
                  Store your most valuable memories in a fashionable way, truly own them and occasionally receive gifts.
                </h3>
                <br />
                <br />
                <Button onClick={this.onWelcomeClose} type="white-bordered" text="Explore" />
                <Link to={`/create-alias`} className="button">
                  <span className="u-show-sm">Post your story</span>
                  <span className="u-hide-sm">Post</span>
                </Link>
              </div>
            </div>
          </div>
        </header>

        <main>
          <div className="row" style={{ maxWidth: '48rem', padding: '1.5rem 0.5rem 0 0.5rem', }}>
            <div
              className="col-xs-12"
              style={{
                textAlign: 'center',
                transition: 'all 0.4s ease-in-out',
                height: isLoadingLatestPosts ? '2.5rem' : '0',
                overflow: 'hidden',
              }}
            >
              {isLoadingLatestPosts && (
                <div className="processing message message--small message--shining isActive">
                  Checking for latest posts <LoadingIcon color="#CECED9" />
                </div>
              )}
            </div>

            {paginatedPosts.map(snippet => (
              <div
                className={classnames('col-xs-12', 'col-sm-6')}
                key={snippet.uuid}
                style={{ display: 'flex' }}
              >
                <PostCard snippet={snippet} />
              </div>
            ))}

            {showLoadMore && (
              <div
                className="col-xs-12"
                style={{ display: 'flex', justifyContent: 'center', marginBottom: '3rem' }}
              >
                <button onClick={this.onLoadMore} className="button button--transp">
                  Load more
                </button>
              </div>
            )}

            {!isLoadingLatestPosts && latestPosts.length === 0 && (
              <React.Fragment>
                <div
                  className={classnames('col-xs-12')}
                  style={{ textAlign: 'center', padding: '3rem 0 1rem', opacity: 0.25 }}
                >
                  <i className={classnames('lnr', ['lnr-' + randomIcon], 'lnr-3x')} />
                  <h3>Pheme has no stories yet...</h3>
                </div>

                <div
                  className={classnames('col-xs-12')}
                  style={{ display: 'flex', justifyContent: 'center', paddingBottom: '3rem' }}
                >
                  {userHandle && (
                    <Link to={`/@${userHandle}/story/new`}>
                      <Button type="outline" text="...Be the first" iconLeft="register" />
                    </Link>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </main>

        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  latestPosts: state.content.latestPosts, // TODO: implement pagination / 'load more' / infinite scroll mechanism
  isLoadingLatestPosts: state.content.isLoadingLatestPosts,
  userHandle: state.user.handle,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadLatestPosts,
      toggleWelcomeMessage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingScene);

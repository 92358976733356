import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { AllHtmlEntities as Entities } from 'html-entities';

import { PostSnippet as PostSnippet } from 'stores/content';
import Card from 'components/card';
import StoredImage from 'components/stored-image';
import Avatar from 'components/avatar';
import RelativeTimestamp from 'components/relative-timestamp';

import WithHandleByAddress from 'components/with-handle-by-address';
import WithEndorsementsByContent from 'components/with-endorsements-by-content';

export default function PostCard({ snippet }: { snippet: PostSnippet }) {
  return (
    <React.Fragment>
      { snippet.type !== "text/markdown" ? (
        <React.Fragment>
          <Card type="post-list">
            <Link to={`/@${snippet.handle}/${snippet.uuid}`}>

              <figure className="post-image">
                  <StoredImage address={snippet.coverImage.small || snippet.coverImage.original} />
              </figure>

              <div className="card__meta">
                <small className="alias alias__onImage">@{snippet.handle}</small>
                <small className="date date__onImage"><RelativeTimestamp date={snippet.date} /></small>
              </div>

            </Link>
          </Card>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Card type="post-list">
            <Link to={`/@${snippet.handle}/${snippet.uuid}`}>

              { snippet.coverImage && (
                <figure className="cover-image">
                  <StoredImage address={snippet.coverImage.small || snippet.coverImage.original} />
                </figure>
              )}

              <div className="card__content" style={{ marginTop: snippet.coverImage ? 'none' : 'auto'}}>
                <h3 className="title">{snippet.title}</h3>
                <p className="snippet">{Entities.decode(snippet.excerpt)}</p>
              </div>

              <div className="card__meta">
                {snippet.handleDetails.profile.avatar && (
                  <Avatar size="small" placement="inline" image={snippet.handleDetails.profile.avatar.small} />
                )}
                <small className="alias">@{snippet.handle}</small>
                <small className="date"><RelativeTimestamp date={snippet.date} /></small>
              </div>


              <WithEndorsementsByContent handle={snippet.handle} uuid={snippet.uuid} limit={3}>{({ endorsements, totalCount }) => (
                totalCount > 0 ? (
                  <div className="card__endorsers">
                    <span style={{ marginRight: '0.5rem' }}>Gifts by</span>

                    {endorsements.slice(0, 3).map((endorsement, i) => (
                      <WithHandleByAddress key={endorsement.endorser} address={endorsement.endorser}>{({ handle, handleDetails, icon, displayName }) => (
                        <Avatar
                          size="tiny"
                          title={displayName}
                          placement="stacked"
                          icon={icon}
                          image={handleDetails.profile.avatar ? handleDetails.profile.avatar.small : null}
                        />
                      )}</WithHandleByAddress>
                    ))}

                    {totalCount > 3 &&
                      <span style={{ marginLeft: '1rem' }}>and {totalCount - 3} others</span>
                    }
                  </div>
                ) : null
              )}</WithEndorsementsByContent>

            </Link>
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import './styles.scss';

import { State as AppState } from 'stores';
import { latestNotificationSelector, dismissNotification, Notification } from 'stores/transaction';

import ToasterNotification from 'components/toaster-notification';

type Props = {
  latestNotification: Notification,
  onDismissNotification: (Notification) => any,
}

export function Toaster(props: Props) {
  const { latestNotification, onDismissNotification } = props;
  const toasterClassName = classnames('toaster');

  return (
    <div className={toasterClassName}>
      {latestNotification && <ToasterNotification
        notification={latestNotification}
        onDisappear={() => onDismissNotification(latestNotification)}
      />}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  latestNotification: latestNotificationSelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onDismissNotification: dismissNotification
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Toaster);
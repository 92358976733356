import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import PhemeService from 'services/pheme';
import sanitizeHandle from 'lib/sanitize-handle';

import Field from 'components/field';

const HANDLE_QUERY_THROTTLE_DURATION = 500;

type Props = {
  placeholder?: string,
  goTo: typeof push
};

type State = {
  isQuerying: boolean,
  handleOwner: string,
  query: string,
};

export class HandleQueryInput extends React.PureComponent<Props, State> {
  handleQueryTimeout: NodeJS.Timer;
  input: HTMLInputElement;

  static defaultProps = {
    placeholder: 'Search or create alias...',
  };

  state = {
    isQuerying: false,
    query: '',
    handleOwner: '',
  };

  onHandleQuery = async (query: string) => {
    const handleOwner = await PhemeService.getInstance().registry.getOwner(query).execute().catch(() => '');
    if (this.state.query !== query) return;
    this.setState({ query, handleOwner, isQuerying: false });
  }

  onReset = () => {
    if (this.input) this.input.value = '';
    this.setState({ query: '', isQuerying: false });
  }

  onKeyUp = (e: React.FormEvent<HTMLInputElement>) => {
    const query = sanitizeHandle(e.currentTarget.value);
    if (this.state.query === query) return;
    if (query.length > 32) {
      this.setState({ query, isQuerying: false });
      return;
    }
    this.setState({ query, isQuerying: true });
    clearTimeout(this.handleQueryTimeout);
    this.handleQueryTimeout = setTimeout(async () => {
      this.onHandleQuery(query);
    }, HANDLE_QUERY_THROTTLE_DURATION);
  }

  render() {
    const { isQuerying, handleOwner, query } = this.state;
    const { goTo, placeholder } = this.props;
    const isQueryTooLong = query.length > 32;

    return (
      <Field iconLeft="magnifier">
        <input ref={(input) => { this.input = input }} className="field" type="text" onKeyUp={this.onKeyUp} placeholder={placeholder} />

        {isQuerying && <div className="field-dropdown"><span style={{ opacity: 0.75 }}>Searching...</span></div>}
        {!isQuerying && query && (
          <div className="field-dropdown">
            {isQueryTooLong && (
              <span>Aliases can not be longer than 32 characters.</span>
            )}
            {!isQueryTooLong && !handleOwner && (
              <Link to={'/create-alias?handle=' + query} onClick={this.onReset}>
                <strong>@{query}</strong> is available! &nbsp;<i className="lnr lnr-chevron-right lnr-075x"></i>
              </Link>
            )}
            {!isQueryTooLong && handleOwner && (
              <Link to={`@${query}`} onClick={this.onReset}>
                @<strong>{query}</strong> &nbsp;<i className="lnr lnr-chevron-right lnr-075x"></i>
              </Link>
            )}
          </div>
        )}
      </Field>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  goTo: push
}, dispatch);

export default connect(null, mapDispatchToProps)(HandleQueryInput);

import * as React from 'react';
import classnames from 'classnames';
import './styles.scss';

type Props = {
  address: string,
  classes?: string,
  type?: string,
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void,
  isExpandable: boolean,
};

type State = {
  expanded: boolean,
};

const stripHexPrefix = (input: string) => input.replace('0x','');

export default class HashLabel extends React.PureComponent<Props> {
  state = { expanded: false };

  onClick = () => {
    const { isExpandable } = this.props;
    if (!isExpandable) return;
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { address, classes, type, isExpandable } = this.props
    const { expanded } = this.state;

    const hash = stripHexPrefix(address);

    const nStartChar = 4;
    const nEndChar = 4;
    const prefix = (type && type == 'ipfs') ? 'ipfs' : '0x';
    const hashStart = hash.substr(0, nStartChar);
    const hashEnd = stripHexPrefix(hash).substr(hash.length - nEndChar);
    const className = classnames({
      'hash-button': isExpandable,
      'hash-label': !isExpandable,
      ['hash-button--' + classes]: classes,
      'isExpanded': expanded
    });

    return (
      <span className={className} title={ address } onClick={this.onClick}>
        <span className="prefix">{prefix}</span>
        <span className="hash hash--short">
          {hashStart}<span className="hash__dots">&middot;&middot;&middot;</span>{hashEnd}
        </span>
        {isExpandable && <span className="hash hash--full">{hash}</span>}
      </span>
    );

  }
}

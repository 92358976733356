import * as ethers from 'ethers';

const INFURA_PROVIDERS = {
  1: 'https://mainnet.infura.io/v3/7852600e4ce04cc1a3f507338a13ac72',
  3: 'https://ropsten.infura.io/v3/87cc8e2857f24ce6b54f8285bcfea920',
};

const LOCAL_PROVIDER_ADDRESS = 'http://localhost:8545';

export type ProviderName =
  | 'metamask'
  | 'trust'
  | 'toshi'
  | 'mist'
  | 'parity'
  | 'cipher'
  | 'infura'
  | 'localhost';

export type Web3Status =
  | 'BOOTING'
  | 'FAILED'
  | 'NOT_AVAILABLE'
  | 'WRONG_NETWORK'
  | 'WRONG_NETWORK_ROPSTEN'
  | 'READY'
  | 'READY_ROPSTEN';

const getFallbackProviderAddress = () =>
  INFURA_PROVIDERS[process.env.NETWORK_ID] || LOCAL_PROVIDER_ADDRESS;

const embeddedWeb3ProviderLoader = new Promise((resolve, reject) => {
  // Wait for loading completion to avoid race conditions with web3 injection timing.
  window.addEventListener('load', async () => {
    const { web3, ethereum } = window as any;

    // Modern dapp browsers
    if (ethereum) {
      await ethereum.enable();
      resolve(ethereum);
    }
    // Legacy dapp browsers
    else if (typeof web3 !== 'undefined') {
      // Return Mist/MetaMask's provider.
      console.log('Injected web3 detected.');
      resolve(web3.currentProvider);
    } else {
      resolve(null);
    }
  });
});

export function getProviderName(provider: ethers.ethers.providers.JsonRpcProvider): ProviderName {
  const container = window as any;

  const embeddedProvider = (provider as any)._web3Provider;
  const isEmbeddedProvider = !!embeddedProvider;

  let url: URL;
  try {
    url = new URL(provider.connection.url);
  } catch {
    // do nothing
  }

  if (typeof container.SOFA !== 'undefined') return 'toshi';

  if (isEmbeddedProvider && embeddedProvider.isMetaMask) return 'metamask';

  if (isEmbeddedProvider && embeddedProvider.isTrust) return 'trust';

  if (isEmbeddedProvider && embeddedProvider.constructor.name === 'EthereumProvider') return 'mist';

  if (isEmbeddedProvider && embeddedProvider.constructor.name === 'Web3FrameProvider')
    return 'parity';

  if (isEmbeddedProvider && embeddedProvider.provider.constructor.name === 'CipherProvider')
    return 'cipher';

  if (url && url.host.indexOf('infura') !== -1) return 'infura';

  if (url && url.host.indexOf('localhost') !== -1) return 'localhost';

  return undefined;
}

export default class Web3Service {
  static instance: Web3Service;

  readonly embeddedProvider: ethers.ethers.providers.JsonRpcProvider;
  readonly fallbackProvider: ethers.ethers.providers.JsonRpcProvider;

  readonly status: Web3Status;

  private isListening;

  static getInstance() {
    if (!this.instance) throw new Error('Web3 service not initialized yet.');
    return this.instance;
  }

  static async initialize(): Promise<Web3Service> {
    let status: Web3Status = 'NOT_AVAILABLE';

    const embeddedWeb3Provider = await embeddedWeb3ProviderLoader;
    const embeddedProvider =
      embeddedWeb3Provider && new ethers.providers.Web3Provider(embeddedWeb3Provider);

    const fallbackProvider = new ethers.providers.JsonRpcProvider(getFallbackProviderAddress());

    if (embeddedProvider) {
      const network = await embeddedProvider.getNetwork();
      const networkId = network.chainId;
      const [account] = await embeddedProvider.listAccounts();
      const isOnWrongNetwork = networkId.toString() !== process.env.NETWORK_ID;
      const isRopsten = process.env.NETWORK_ID !== "1";

      if (isOnWrongNetwork) {
        if (isRopsten){
          status = 'WRONG_NETWORK_ROPSTEN';
        } else {
          status = 'WRONG_NETWORK';
        }
      } else {
        if (isRopsten){
          status = 'READY_ROPSTEN';
        } else {
          status = 'READY';
        }
      }
    }

    this.instance = new Web3Service({ embeddedProvider, fallbackProvider, status });
    return this.instance;
  }

  private constructor({
    embeddedProvider,
    fallbackProvider,
    status,
  }: {
    embeddedProvider: ethers.ethers.providers.JsonRpcProvider;
    fallbackProvider: ethers.ethers.providers.JsonRpcProvider;
    status: Web3Status;
  }) {
    this.status = status;
    this.embeddedProvider = embeddedProvider;
    this.fallbackProvider = fallbackProvider;
  }

  get providerName(): string {
    const provider = this.embeddedProvider || this.fallbackProvider;
    return getProviderName(provider);
  }

  get provider(): ethers.ethers.providers.JsonRpcProvider {
    switch (this.status) {
      case 'READY':
      case 'READY_ROPSTEN':
        return this.embeddedProvider;
      default:
        return this.fallbackProvider;
    }
  }

  get isOnWrongNetwork(): boolean {
    return (this.status === 'WRONG_NETWORK' || this.status === 'WRONG_NETWORK_ROPSTEN');
  }

  get isReadOnly(): boolean {
    return (this.status !== 'READY' && this.status !== 'READY_ROPSTEN');
  }

  getNetworkId = (): Promise<number> => this.provider.getNetwork().then(network => network.chainId);
}

import './styles/styles.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import createStore, { State } from './stores';

import App from './components/app';

import * as url from 'url';
Object.assign(url, { URL: window.URL });

const history = createBrowserHistory();
const store = createStore(history);
const { platform } = store.getState().user;

const wrapper = document.getElementById('app');
wrapper.className = `app--${platform.toLowerCase()}`;

ReactDOM.render((
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
), wrapper);

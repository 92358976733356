import thunk, { ThunkAction, ThunkMiddleware } from 'redux-thunk';
import { History } from 'history';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { compose, createStore, combineReducers, applyMiddleware, AnyAction } from 'redux';

// Import Ducks
import userReducer, { State as UserState } from './user'
import appReducer, { initialize, State as AppState } from './app';
import contentReducer, { State as ContentState, syncMiddleware as contentSyncMiddleware } from './content';
import transactionReducer, { State as TransactionState } from './transaction';
import endorsementsReducer, { State as EndorsementsState } from './endorsements';


export type State = {
  user: UserState,
  app: AppState,
  router: RouterState,
  content: ContentState,
  transaction: TransactionState,
  endorsements: EndorsementsState,
};

export type Action<R = any> = ThunkAction<R, State, any, AnyAction>;

export default function (history: History) {
  const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: composeEnhancers = compose } = (window as any);

  const reducers = {
    user: userReducer,
    app: appReducer,
    content: contentReducer,
    router: connectRouter(history),
    transaction: transactionReducer,
    endorsements: endorsementsReducer
  };

  // Build the middleware for intercepting and dispatching navigation actions
  const store = createStore(
    combineReducers(reducers),
    composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<State, any>, routerMiddleware(history), contentSyncMiddleware)),
  );

  // dispatch here is a regular dispatch instead of a thunk dispatch so we pass the action as any
  store.dispatch(initialize() as any);

  return store;
}

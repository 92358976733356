import axios from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { State as AppState } from 'stores';
import * as clipboard from 'services/clipboard';
import * as localStorage from 'services/local-storage';

import Button from 'components/button';
import OnboardingMenu from 'components/onboarding-menu';
import SVGBadgeAppStore from 'components/svg-badge-app-store';
import SVGBadgeGooglePlay from 'components/svg-badge-google-play';


type FaucetTx = {
  txHash: string,
  requestedAt: number,
};

type Props = { userAddress: string };
type State = { faucetTx?: FaucetTx };

type FaucetButtonProps = { address: string, onUpdate?: (status: FaucetTx) => void };
type FaucetButtonStatus = 'IDLE' | 'BUSY' | 'DONE' | 'FAILED';
type FaucetButtonState = { status: FaucetButtonStatus };

type FaucetTxInfoProps = { faucetTx: FaucetTx };
type FaucetTxInfoState = { level: number }

class FaucetTxInfo extends React.PureComponent<FaucetTxInfoProps, FaucetTxInfoState> {
  state = { level: 1};
  counter: NodeJS.Timer;

  componentDidMount() {
    this.startLevelCounter();
  }

  componentWillUnmount() {
    clearTimeout(this.counter);
  }

  startLevelCounter() {
    this.counter = setTimeout(() => {
      this.setState({ level: 2 });
      this.counter = setTimeout(() => {
        this.setState({ level: 3 });
      }, 70000);
    }, 30000);
  }

  render() {
    const { txHash } = this.props.faucetTx;
    const { level } = this.state;

    switch (level) {
      case 1:
        return <p>Your request is being processed. Now take a deep breath and count to 30...</p>;
      case 2:
        return <p>...Done counting and still seeing this message? Try counting again, but slower...</p>;
      default:
        return <p>...Still here? Try refreshing this page or <a target="_blank" href={`https://ropsten.etherscan.io/tx/${txHash}`}>viewing your request on Etherscan</a></p>;
    }
  }
}

class FaucetButton extends React.PureComponent<FaucetButtonProps, FaucetButtonState> {
  static API_URL = 'https://us-central1-pristine-bay-190220.cloudfunctions.net/faucetRequest';

  state = {
    status: 'IDLE' as FaucetButtonStatus,
  };

  onClick = async () => {
    const { address, onUpdate } = this.props;
    this.setState({ status: 'BUSY' });

    try {
      const { data } = await axios.post(FaucetButton.API_URL, { address });

      if (onUpdate) onUpdate({
        txHash: data.txhash,
        requestedAt: Date.now()
      });

      this.setState({ status: 'DONE' });
    } catch (e) {
      console.error(e);
      this.setState({ status: 'FAILED' });
    }
  }

  render() {
    const { status } = this.state;

    switch (status) {
      case 'FAILED':
        return <p>Oops — There was an issue while requesting your ether. Please <a href="https://faucet.metamask.io/" target="_blank">visit the Metamask Ropsten Faucet</a> and try requesting from there directly.</p>;
      default:
        return <Button state={status === 'BUSY' && 'isLoading'} text="Send me 1 Test Ether!" type="white" onClick={this.onClick} />;
    }
  }
}

export class HasNoBalanceStepROPSTEN extends React.PureComponent<Props, State>{
  constructor(props: Props) {
    super(props);
    this.state = { faucetTx: localStorage.get(this.faucetTxStorageKey()) };
  }

  faucetTxStorageKey = () => `ropstenFaucet:${this.props.userAddress}`;

  setFaucetTx = (faucetTx: FaucetTx) => {
    localStorage.set(this.faucetTxStorageKey(), faucetTx);
    this.setState({ faucetTx });
  }

  render() {
    const { userAddress } = this.props;
    const { faucetTx } = this.state;
    const { txHash = '' } = faucetTx || {};

    return (
      <div className="onboarding">

        <OnboardingMenu active={3} />

        <div className="onboarding--message">

          <p className="centre"><big>
            <strong>Get test credits</strong><br/>
            <span className="subtle">Getting test credits is super easy. Just click here and request a test Ether:</span>
          </big></p>

          <br/>
          {faucetTx ? (
            <div className="centre">
              <Button state={status = 'isLoading'} type="white" text="Send me 1 Test Ether" />
              <div style={{ marginTop: '0.5rem' }}>
                <FaucetTxInfo faucetTx={faucetTx} />
              </div>
            </div>
          ) : (
            <FaucetButton address={userAddress} onUpdate={this.setFaucetTx} />
          )}
        </div>

      </div>
    );
  }
};

const mapStateToProps = (state: AppState): Partial<Props> => ({
  userAddress: state.user.address
});

export default connect(mapStateToProps)(HasNoBalanceStepROPSTEN);

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import * as ethers from 'ethers';

import Button from 'components/button';
import StoredImage from 'components/stored-image';
import Avatar from 'components/avatar';
import WithHandleByAddress from 'components/with-handle-by-address';

import { Endorsement } from 'stores/endorsements';

type Props = {
  endorsements: Array<Endorsement>;
  onCancel?: () => void;
  onEndorseModalOpen?: () => void;
  givenEndorsement: boolean;
  isOwner: boolean;
};

const renderEndorsmentAmount = (endorsement: Endorsement) => (
  <div className="amount">
    {Number(ethers.utils.formatEther(endorsement.amount)).toFixed(4)}&nbsp;{'ETH'}
  </div>
);

const EndorsedByModal = ({
  endorsements,
  onCancel,
  onEndorseModalOpen,
  givenEndorsement,
  isOwner,
}: Props) => (
  <React.Fragment>
    <h3 style={{ marginBottom: '1rem' }}>Gifts By</h3>

    <div className="dialog__scrollbox">
      <div className="endorsers-list">
        {endorsements.map((endorsement, i) => (
          <WithHandleByAddress key={endorsement.endorser} address={endorsement.endorser}>
            {({ handle, handleDetails, icon, displayName }) =>
              handle ? (
                <Link key={i} to={`/@${handle}`} className="endorser">
                  <Avatar
                    placement="inline"
                    title={displayName}
                    icon={icon}
                    image={
                      handleDetails.profile.avatar ? handleDetails.profile.avatar.small : undefined
                    }
                  />
                  <div className="endorser-handle">{displayName}</div>
                  {renderEndorsmentAmount(endorsement)}
                </Link>
              ) : (
                <div className="endorser">
                  <Avatar placement="inline" title={displayName} icon={icon} />
                  <div className="endorser-handle">{displayName}</div>
                  {renderEndorsmentAmount(endorsement)}
                </div>
              )
            }
          </WithHandleByAddress>
        ))}
      </div>
    </div>

    <div
      className="dialog__footer"
      style={{ paddingTop: '1rem', paddingBottom: '1rem', textAlign: 'center' }}
    >
      <Button text="Close" onClick={onCancel} type="white-bordered" />
      {!isOwner && (
        <Button
          text={givenEndorsement ? 'Gift More' : 'Gift'}
          iconLeft="gift"
          onClick={onEndorseModalOpen}
        />
      )}
    </div>
  </React.Fragment>
);

export default EndorsedByModal;

import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import LOGO_URL from 'static/images/pheme-monogram.svg';

import StatusBadge from 'components/status-badge';
import Dropdown from 'components/dropdown';
import HandleQueryInput from 'components/handle-query-input';
import Avatar from 'components/avatar';
import Button from 'components/button';

import { State as AppState } from 'stores';
import { cachedHandleSelector, HandleAvatar } from 'stores/content';
import { showWelcomeMessageSelector } from 'stores/user';

import './styles.scss';


type Props = {
  isOnLandingPage: boolean,
  userAvatar: HandleAvatar,
  userHandle: string,
  showWelcomeMessage: boolean
};

export function Header(props: Props) {
  const { userHandle, userAvatar, showWelcomeMessage, isOnLandingPage } = props;

  return (
    <nav>
        <div className="row">
          <div className={classnames('col-xs-7', 'col-sm-5', 'col-sm-offset-1', 'col-lg-4', 'col-lg-offset-2', 'u-flex-start', 'u-flex-align-middle')} style={{ position: 'relative' }}>
            <Link to="/" className="logo">
              <img src={LOGO_URL} alt="Pheme" title="Pheme" />
            </Link>
            <StatusBadge />
            <HandleQueryInput />
          </div>
          <div className={classnames('col-xs-5', 'col-sm-5', 'col-lg-4', 'nav__actions', 'u-flex-end', 'u-flex-align-middle')}>
            { userHandle ? (
              <React.Fragment>
                <div className="user-badge hasDropdown">
                  <Link to={`/@${userHandle}`}>
                    {userAvatar ? (
                      <Avatar size="tiny" placement="inline" image={userAvatar.small} />
                    ) : <i className="lnr lnr-user"/> }
                    <span className="username">@{userHandle}</span>
                  </Link>
                  <Dropdown>
                    <li>
                      <Link to={`/@${userHandle}`}><i className="lnr lnr-user"></i> View handle</Link>
                    </li>
                    <li>
                      <Link to={`/@${userHandle}/edit`}><i className="lnr lnr-cog"></i> Edit handle info</Link>
                    </li>
                    <li>
                      <Link to={`/@${userHandle}/story/new`} className="button button--small button--outline"><i className="lnr lnr-register"></i> New story</Link>
                    </li>
                    <li>
                      <Link to={`/@${userHandle}/image/new`} className="button button--small button--outline"><i className="lnr lnr-camera2"></i> New image</Link>
                    </li>
                  </Dropdown>
                </div>
                {isOnLandingPage && (
                  <React.Fragment>
                    <Link to={`/@${userHandle}/story/new`}><Button text="" type="circle" iconLeft="register lnr-13x"/></Link>
                    <Link to={`/@${userHandle}/image/new`} style={{ marginLeft: '0.25rem' }}><Button text="" type="circle" iconLeft="camera2 lnr-13x"/></Link>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <Link to={`/create-alias`} className="button">
                <span className="u-show-sm">Sign Up | Login</span>
                <span className="u-hide-sm">Connect</span>
              </Link>
            ) }
          </div>
        </div>
    </nav>
  );
}

const mapStateToProps = (state: AppState) => {
  const { handle } = state.user;
  const handleDetails = cachedHandleSelector(state.content, { handle });

  return ({
    isOnLandingPage: state.router.location.pathname === '/',
    userHandle: state.user.handle,
    userAvatar: handleDetails.profile.avatar,
    showWelcomeMessage: showWelcomeMessageSelector(state),
  });
}

export default connect(mapStateToProps)(Header);

type MessageDefinition = {
  pending: string,
  failed: string,
  completed: string,
};

const MESSAGES: { [key: string]: MessageDefinition } = {
  REGISTER_HANDLE: {
    pending: 'Registering your alias...',
    failed: 'There was a problem while registering your alias.',
    completed: 'You have successfully registered your alias.',
  },
  UPDATE_PROFILE: {
    pending: 'Updating your profile...',
    failed: 'There was a problem while updating your profile.',
    completed: 'Your profile has been updated.',
  },
  PUBLISH_POST: {
    pending: 'Publishing your post...',
    failed: 'There was a problem while publishing your post.',
    completed: 'Your post has been published.',
  },
  UPDATE_POST: {
    pending: 'Updating your post...',
    failed: 'There was a problem while updating your post.',
    completed: 'Your post has been updated.',
  },
  UNPUBLISH_POST: {
    pending: 'Unpublishing your post...',
    failed: 'There was a problem while unpublishing your post.',
    completed: 'Your post has been unpublished.',
  },
  ENDORSE_CONTENT: {
    pending: 'Sending gift...',
    failed: 'There was a problem while gifting.',
    completed: 'You have successfully sent your gift.',
  },
};

export default MESSAGES;

import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { push, replace } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { parse } from 'qs';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { State as AppState } from 'stores';
import { buildClaimHandle } from 'stores/content';
import makeCancelable from 'lib/make-cancelable';
import PhemeService, { ITask } from 'services/pheme';
import { setTitle, resetTitle } from 'services/document-title';

import Section from 'components/section';
import Button from 'components/button';
import Field from 'components/field';
import HashLabel from 'components/hash-label';
import InfoBox from 'components/info-box';
import Footer from 'components/footer';
import HandleQueryInput from 'components/handle-query-input';
import WithTaskEstimation from 'components/with-task-estimation';
import Dialog from 'components/dialog';

import RequiresAuthentication from 'components/requires-authentication';

type Props = RouteComponentProps<any> & {
  isReadOnly: boolean;
  userAddress: string;
  userHandle: string;
  userBalance: number;
  goTo: typeof push;
  redirect: typeof replace;
  buildClaimHandle: (handle: string) => any;
};

type ClaimState =
  | 'INPUT'
  | 'REVIEW'
  | 'UNAVAILABLE'
  | 'ALREADY_REGISTERED'
  | 'TOO_LONG'
  | 'INSUFFICIENT_FUNDS';
interface State {
  claimState: ClaimState;
  handle: string;
  priceEstimation: number;
  task: ITask;
  isExplainerDialogActive: boolean;
  isWaiting: boolean;
}

export class HandlesNewScene extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps = (nextProps: Props, prevState: State) => {
    const { handle } = parse(nextProps.location.search.substring(1));

    const { userHandle, isReadOnly, userBalance } = nextProps;

    const claimState: ClaimState = userHandle
      ? 'ALREADY_REGISTERED'
      : isReadOnly
      ? 'UNAVAILABLE'
      : userBalance === 0
      ? 'INSUFFICIENT_FUNDS'
      : handle
      ? 'REVIEW'
      : 'INPUT';

    return { ...prevState, handle, claimState };
  }
  input: HTMLInputElement;
  cancelRedirect: () => void;

  state = {
    claimState: 'INPUT' as ClaimState,
    task: undefined,
    handle: '',
    priceEstimation: 0,
    isExplainerDialogActive: false,
    isWaiting: false,
  };

  onSubmit = async (event) => {
    const { task, handle } = this.state;
    if (!task) return;

    this.setState({ isWaiting: true });
    const { promise, cancel } = makeCancelable(task.execute());
    promise.then(() => this.props.redirect(`/@${handle}`));
    this.cancelRedirect = cancel;
  }

  onExplainerDialogClose = () => {
    this.setState({ isExplainerDialogActive: false });
  };

  onExplainerDialogOpen = () => {
    this.setState({ isExplainerDialogActive: true });
  };

  componentDidMount() {
    this.onHandleChange();
    setTitle('Create your alias');
  }

  componentWillUnmount() {
    resetTitle();
    const { cancelRedirect } = this;
    if (cancelRedirect) cancelRedirect();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.handle === prevState.handle) return;
    this.onHandleChange();
  }

  async onHandleChange() {
    const { handle } = this.state;
    const { buildClaimHandle } = this.props;

    if (!handle) {
      this.setState({ task: undefined });
      return;
    }

    if (handle.length > 32) {
      this.setState({ task: undefined, claimState: 'TOO_LONG' });
      return;
    }

    const task = await buildClaimHandle(handle);
    this.setState({ task });
  }

  render() {
    const { claimState, handle, priceEstimation, task, isWaiting } = this.state;
    const { userAddress, userHandle } = this.props;

    return (
      <RequiresAuthentication>
        <React.Fragment>
          <Section type="hero">
            <div className="nav-spacer" />

            {claimState === 'TOO_LONG' && (
              <React.Fragment>
                <h2>Sadly aliases can not be longer than 32 characters.</h2>
                <p>You can try to pick a shorter one!</p>
                <br />
                <div style={{ display: 'inline-block', maxWidth: '24rem' }}>
                  <HandleQueryInput placeholder="Type it here!" />
                </div>
              </React.Fragment>
            )}

            {claimState === 'ALREADY_REGISTERED' && (
              <React.Fragment>
                <h2>...you already have an alias though.</h2>
                <br />
                <p>
                  You already registered the alias @<strong>{userHandle}</strong>; at this point you
                  can only register one alias per wallet address. Please switch your account in your
                  wallet extension to register another alias.
                </p>
                <div style={{ display: 'flex', marginTop: '2rem', justifyContent: 'center' }}>
                  <Button
                    size="small"
                    type="white-outline"
                    text="Go to your alias"
                    onClick={() => this.props.goTo(`/@${userHandle}`)}
                  />
                </div>
              </React.Fragment>
            )}

            {/* DEPRECATED - TODO Remove */}
            {claimState === 'UNAVAILABLE' && (
              <React.Fragment>
                <h1>You're 1 step away from creating an alias...</h1>
                <p>
                  You are currently in <strong>Read-only</strong> mode; in order to create an alias
                  you need to have an active connection to web3. Don't worry, this is a very simple
                  process:
                </p>
                <div
                  style={{ display: 'flex', marginTop: '2rem', justifyContent: 'center' }}
                  title="How to connect to web3"
                >
                  <Link to={`/how-to`} className="button button--white">
                    How to connect to web3
                  </Link>
                </div>
              </React.Fragment>
            )}

            {/* DEPRECATED - TODO Remove */}
            {claimState === 'INSUFFICIENT_FUNDS' && (
              <React.Fragment>
                <h1>You're almost there!</h1>
                <p>
                  You currently don't have sufficient funds in your account. Don't worry, funding
                  your account is a very simple process:
                </p>
                <div
                  style={{ display: 'flex', marginTop: '2rem', justifyContent: 'center' }}
                  title="How to fund your account"
                >
                  <Link to={`/how-to`} className="button button--white">
                    How to fund your account
                  </Link>
                </div>
              </React.Fragment>
            )}

            {claimState === 'INPUT' && (
              <React.Fragment>
                <h1>Create your alias</h1>
                <p>You will publish under this name</p>
                <br />
                <div style={{ display: 'inline-block', maxWidth: '24rem' }}>
                  <HandleQueryInput placeholder="Type it here!" />
                </div>
              </React.Fragment>
            )}

            {claimState === 'REVIEW' && (
              <React.Fragment>
                <h1>Create your alias</h1>
                <p>
                  The alias <strong>{handle}</strong> is available
                </p>
                <br />

                <Field iconLeft="user" label="ALIAS">
                  <input type="text" value={handle} className="field--fullwidth" readOnly />
                  <div style={{ position: 'absolute', right: '0.5rem', top: '1.5rem' }}>
                    <Button
                      type="outline"
                      text="Edit"
                      size="small"
                      onClick={() => this.props.goTo(`/create-alias?handle=`)}
                    />
                  </div>
                </Field>

                <WithTaskEstimation
                  task={task}
                  render={({ gasCost, status }) => (
                    <React.Fragment>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          margin: '3rem 1.5rem -3.25rem 0',
                        }}
                      >
                        <Button
                          state={status === 'ESTIMATING' || isWaiting ? 'isLoading' : null}
                          text="REGISTER"
                          type="white"
                          iconRight="cloud-sync lnr-15x"
                          onClick={this.onSubmit}
                        />
                      </div>
                      <InfoBox
                        action="Registering your alias"
                        isEstimating={status === 'ESTIMATING'}
                        gasCost={gasCost}
                        onDark={true}
                      >
                        <div style={{ marginBottom: '0.25rem' }}>
                          Current wallet: <HashLabel address={userAddress} isExpandable={true} />
                        </div>
                        <div>
                          <p style={{ opacity: '0.5' }}>
                            <small>
                              By registering, you agree to Pheme's{' '}
                              <Link style={{ color: 'white', fontWeight: 'bold' }} to="/terms">Terms of Service</Link>
                              {' '}and{' '}
                              <Link style={{ color: 'white', fontWeight: 'bold' }} to="/privacy">Privacy Policy</Link>.
                            </small>
                          </p>
                        </div>
                      </InfoBox>
                    </React.Fragment>
                  )}
                />
              </React.Fragment>
            )}
          </Section>

          <Footer />
        </React.Fragment>
      </RequiresAuthentication>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isReadOnly: state.app.isReadOnly,
  userAddress: state.user.address,
  userHandle: state.user.handle,
  userBalance: state.user.balance,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo: push,
      redirect: replace,
      buildClaimHandle,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HandlesNewScene);

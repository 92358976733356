import * as React from 'react';
import { connect } from 'react-redux';
import * as clipboard from 'services/clipboard';

import { UserPlatform } from 'stores/user';

type Props = {
  canCopy: boolean,
  children?: React.ReactNode,
  url?: string,
}

type State = {
  copyLinkActive: boolean,
}

export class CopyLinks extends React.PureComponent<Props, State> {
  state = { copyLinkActive: false };

  // Copy Link
  onCopyLink = () => {
    const { canCopy, url } = this.props;
    if (!canCopy) return;

    const urlToCopy = url || window.location.href.replace('http://', 'https://');

    clipboard.copy(urlToCopy);
    document.body.focus();
    this.setState({ copyLinkActive: true });
    setTimeout(() => {
      this.setState({ copyLinkActive: false });
    }, 1000);
  }

  render() {
    const { children, canCopy } = this.props;
    const { copyLinkActive } = this.state;

    return (
      <strong className="copy-address" onClick={this.onCopyLink}>
        {children}
        {canCopy && (
          <React.Fragment>
            &nbsp;<i className={ copyLinkActive ? 'lnr lnr-check' : 'lnr lnr-copy' }></i>
          </React.Fragment>
        )}
      </strong>
    );
  }
}

const mapStateToProps = state => ({ canCopy: state.user.platform !== 'IOS' });

export default connect(mapStateToProps)(CopyLinks);

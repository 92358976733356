import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { State as AppState } from 'stores';
import { UserPlatform } from 'stores/user';

import CopyLink from 'components/copy-link';
import OnboardingMenu from 'components/onboarding-menu';
import SVGBadgeAppStore from 'components/svg-badge-app-store';
import SVGBadgeGooglePlay from 'components/svg-badge-google-play';
import SVGIconDapps from 'components/svg-icon-dapps';

type Props = {
  platform: UserPlatform,
};

const currentUrlWithoutProtocol = () => window.location.href.replace(/^[^\/]+\/\//, '');
const currentHostname = () => window.location.hostname;

const CoinbaseWalletInstructions = ({ platform }: Props) => {
  return (
    <React.Fragment>
      <p className="centre"><big>
        Install a web browser with a built-in Ethereum wallet such as <br /> <br />
        {platform === 'IOS' ? (
          <React.Fragment>
            <a className="button button--white" href="itms-appss://apps.apple.com/us/app/coinbase-wallet/id1278383455?mt=8">Coinbase Wallet</a>
            <br /><br />
            <a className="button button--white" href="itms-appss://apps.apple.com/us/app/opera-touch-web-browser/id1411869974?mt=8">Opera Touch</a>
            <br /><br />
            <a className="button button--white" href="itms-appss://apps.apple.com/us/app/trust-crypto-bitcoin-wallet/id1288339409?mt=8">Trust Wallet</a>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <a className="button button--white" href="https://play.google.com/store/apps/details?id=org.toshi&hl=en_US">Coinbase Wallet</a>
            <br /><br />
            <a className="button button--white" href="https://play.google.com/store/apps/details?id=com.opera.touch&hl=en_US">Opera Touch</a>
            <br /><br />
            <a className="button button--white" href="https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp&hl=en_US">Trust Wallet</a>
          </React.Fragment>
        )}<br /> <br />
        Follow its setup instructions, and visit <CopyLink>pheme.app</CopyLink> using it.
      </big></p>
    </React.Fragment>
  );
};

const MetamaskInstructions = ({ platform }: Props) => {
  return (
    <React.Fragment>
      <p className="centre"><big>
        Download <strong>Metamask Wallet</strong> and refresh this page after following its setup instructions.<br/>
      </big></p>

      <div style={{ margin: '1rem 0' }}>
        {platform === 'CHROME' && (
          <a className="button button--white" href="https://chrome.google.com/webstore/detail/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank" style={{ color: '#F79220' }}>Download Metamask</a>
        )}

        {platform === 'FIREFOX' && (
          <a className="button button--white" href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/" target="_blank" style={{ color: '#F79220' }}>Download Metamask</a>
        )}

        {platform === 'OPERA' && (
          <a className="button button--white" href="https://addons.opera.com/en/extensions/details/metamask/" target="_blank" style={{ color: '#F79220' }}>Download Metamask</a>
        )}
      </div>

    </React.Fragment>
  );
};

const DesktopInstructions = ({ platform }: Props) => {
  return (
    <React.Fragment>
      <p><big>
        In order to Pheme, we recommend using Brave browser…
      </big></p>

      <div style={{ margin: '1rem 0' }}>
        <a className="button button--white" href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" style={{ color: '#F79220' }}>Get the Firefox browser</a><br/>
      </div>

      <p><big>
        …with the MetaMask extension installed…
      </big></p>

      <div style={{ margin: '1rem 0' }}>
        <a className="button button--white" href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/" target="_blank" style={{ color: '#F79220' }}>Get the MetaMask extension</a>
      </div>

      <p><big>
        …and then visiting <CopyLink>aloha.pheme.app</CopyLink>
      </big></p>
    </React.Fragment>
  );
};

const UnknownInstructions = ({ platform }: Props) => {
  return (
    <React.Fragment>
      <p><big>
        We couldn't detect your operating system or browser…
      </big></p>
    </React.Fragment>
  );
};

export class MissingWeb3Step extends React.PureComponent<Props>{

  renderInstructions() {
    const { platform } = this.props;

    switch (platform) {
      case 'IOS':
      case 'ANDROID':
        return <CoinbaseWalletInstructions {...this.props} />;
      case 'CHROME':
      case 'FIREFOX':
      case 'OPERA':
        return <MetamaskInstructions {...this.props} />;
      case 'UNKNOWN_DESKTOP':
        return <DesktopInstructions {...this.props} />;
      default:
        return <UnknownInstructions {...this.props} />
    }
  }

  render() {
    return (
      <div className="onboarding">

        <OnboardingMenu active={1} />

        <div className="onboarding--message">
          {this.renderInstructions()}
        </div>

      </div>
    );
  }
};

const mapStateToProps = (state: AppState): Partial<Props> => ({
  platform: state.user.platform
})

export default connect(mapStateToProps)(MissingWeb3Step);

import UAParser from 'ua-parser-js';
import * as ethers from 'ethers';
import { State as AppState, Action } from 'stores';
import * as LocalStorageService from 'services/local-storage';

import Web3Service, { Web3Status } from 'services/web3';
import PhemeService from 'services/pheme';
import EndorsementsService, { Endorsement } from 'services/endorsements';

export type UserPlatform =
  | 'IOS'
  | 'ANDROID'
  | 'CHROME'
  | 'FIREFOX'
  | 'OPERA'
  | 'UNKNOWN_MOBILE'
  | 'UNKNOWN_DESKTOP'
  | 'UNKNOWN';
export type UserStatus =
  | 'BOOTING'
  | 'MISSING_WEB3'
  | 'WRONG_NETWORK'
  | 'WRONG_NETWORK_ROPSTEN'
  | 'HAS_NO_BALANCE'
  | 'HAS_NO_BALANCE_ROPSTEN'
  | 'DONE';

const WELCOME_MESSAGE_DISMISSED_KEY = 'welcomeMessageDismissed';
const USER_TOGGLE_WELCOME_MESSAGE = 'user/toggleWelcomeMessage';
const USER_UPDATE = 'user/update';

export type UserUpdate = {
  userAddress: string;
  userHandle: string;
  userBalance: number;
};

const getUserPlatform = (): UserPlatform => {
  const userAgent = new UAParser(navigator.userAgent);
  const { name: osName } = userAgent.getOS();
  const { type: deviceType } = userAgent.getDevice();
  const { name: browserName } = userAgent.getBrowser();

  switch (osName) {
    case 'iOS':
      return 'IOS';
    case 'Android':
      return 'ANDROID';
    default:
      switch (deviceType) {
        case undefined:
          switch (browserName) {
            case 'Chromium':
            case 'Chrome':
              return 'CHROME';
            case 'Firefox':
              return 'FIREFOX';
            case 'Opera':
              return 'OPERA';
            default:
              return 'UNKNOWN_DESKTOP';
          }
        case 'mobile':
        case 'tablet':
          return 'UNKNOWN_MOBILE';
        default:
          return 'UNKNOWN';
      }
  }
};

export type State = {
  platform: UserPlatform;
  address: string;
  handle: string;
  balance: number;
  status: UserStatus;
  hasDismissedWelcomeMessage: boolean;
  givenEndorsements: Array<Endorsement>;
  receivedEndorsements: Array<Endorsement>;
};

function getDefaultState(): State {
  return {
    address: '',
    handle: '',
    platform: getUserPlatform(),
    balance: 0,
    status: 'BOOTING',
    hasDismissedWelcomeMessage: !!LocalStorageService.get(WELCOME_MESSAGE_DISMISSED_KEY),
    givenEndorsements: [],
    receivedEndorsements: [],
  };
}

function convertWeb3StatusToUserStatus(
  web3Status: Web3Status,
  userBalance: number = 0
): UserStatus {
  switch (web3Status) {
    case 'NOT_AVAILABLE':
      return 'MISSING_WEB3';
    case 'WRONG_NETWORK':
      return 'WRONG_NETWORK';
    case 'WRONG_NETWORK_ROPSTEN':
      return 'WRONG_NETWORK_ROPSTEN'
    case 'FAILED':
      return 'BOOTING';
    case 'READY':
      return userBalance === 0 ? 'HAS_NO_BALANCE' : 'DONE';
    case 'READY_ROPSTEN':
      return userBalance === 0 ? 'HAS_NO_BALANCE_ROPSTEN' : 'DONE';
    default:
      return 'BOOTING';
  }
}

export default function reducer(state: State = getDefaultState(), action: any): State {
  switch (action.type) {
    case USER_TOGGLE_WELCOME_MESSAGE:
      LocalStorageService.set(WELCOME_MESSAGE_DISMISSED_KEY, !state.hasDismissedWelcomeMessage);
      return { ...state, hasDismissedWelcomeMessage: !state.hasDismissedWelcomeMessage };
    case USER_UPDATE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export const toggleWelcomeMessage = () => ({ type: USER_TOGGLE_WELCOME_MESSAGE });

export const showWelcomeMessageSelector = (state: AppState) =>
  !state.user.hasDismissedWelcomeMessage || !state.user.handle;

export const createGivenEndorsementSelector = (handle: string, uuid: string) => (state: AppState) =>
  state.user.givenEndorsements.find(
    endorsement => endorsement.uuid === uuid && endorsement.handle === handle
  );

export const syncUserChanges = (): Action => async (dispatch, getState) => {
  const web3 = Web3Service.getInstance();
  const pheme = PhemeService.getInstance();

  const [userAddress = ''] = web3.embeddedProvider
    ? await web3.embeddedProvider.listAccounts()
    : [];

  const [userHandle, rawUserBalance] = await (Promise.all([
    web3.isReadOnly
      ? ''
      : pheme.registry
          .getHandleByOwner(userAddress)
          .execute()
          .catch(() => ''),
    web3.isReadOnly ? ethers.utils.bigNumberify(0) : web3.provider.getBalance(userAddress),
  ] as Array<any>) as Promise<[string, ethers.utils.BigNumber, string, number]>);

  let givenEndorsements: Array<Endorsement> = [];
  let receivedEndorsements: Array<Endorsement> = [];

  const endorsements = EndorsementsService.getInstance();
  if (userHandle)
    receivedEndorsements = await endorsements.loadAllEndorsementsBy(
      'getRecordIdByHandleAt',
      ethers.utils.formatBytes32String(userHandle)
    );
  if (userAddress)
    givenEndorsements = await endorsements.loadAllEndorsementsBy(
      'getRecordIdByEndorserAt',
      userAddress
    );

  const userBalance = Number(ethers.utils.formatEther(rawUserBalance));
  const status = convertWeb3StatusToUserStatus(web3.status, userBalance);

  return dispatch({
    type: USER_UPDATE,
    payload: {
      status,
      balance: userBalance,
      address: userAddress,
      handle: userHandle,
      givenEndorsements,
      receivedEndorsements,
    },
  });
};

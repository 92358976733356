import * as ethers from 'ethers';
import { State as AppState, Action } from 'stores';
import EndorsementsService from 'services/endorsements';
import { trackTaskTransaction } from 'stores/transaction';

export interface Endorsement {
  endorser: string;
  handle: string;
  uuid: string;
  amount: ethers.utils.BigNumber;
}

export interface Container<T> {
  blockNumber: number;
  isLoading: boolean;
  value: T;
}

export type ListContainer<T> = Container<T> & {
  limit: number;
};

interface ContainerMap<T> { [key: string]: Container<T>; }
interface ListContainerMap<T> { [key: string]: ListContainer<T>; }

const RESET_BY_CONTENT = 'endorsements/resetByContent';

const COUNT_BY_CONTENT_BEGIN = 'endorsements/countByContentBegin';
const COUNT_BY_CONTENT_END = 'endorsements/countByContentEnd';

const LIST_BY_CONTENT_BEGIN = 'endorsements/listByContentBegin';
const LIST_BY_CONTENT_END = 'endorsements/listByContentEnd';

const CACHE_LIFETIME_IN_BLOCKS = 10;

export interface State {
  countByContent: ContainerMap<number>;
  listByContent: ListContainerMap<Endorsement[]>;

  countByEndorser: ContainerMap<number>;
  listByEndorser: ListContainerMap<Endorsement[]>;

  countByEndorsee: ContainerMap<number>;
  listByEndorsee: ListContainerMap<Endorsement[]>;
}

const createContainer = <T>(blockNumber: number, initialValue: T = undefined): Container<T> => ({
  blockNumber,
  isLoading: true,
  value: initialValue,
});

const fillContainer = <T>(container: Container<T>, value: T): Container<T> => ({
  ...container,
  value,
  isLoading: false,
});

const counters = {};
const listers = {};

const isContainerValid = <T>(container: Container<T>, currentBlock: number) => {
  const isPresent = !!container;
  const isLoading = container && container.isLoading;
  const isOutdated = isPresent && currentBlock - container.blockNumber > CACHE_LIFETIME_IN_BLOCKS;
  return isPresent && !isLoading && !isOutdated;
};

export const countEndorsementsByContent = (handle: string, uuid: string): Action => async (
  dispatch,
  getState
) => {
  const { app, endorsements } = getState();
  const key = `${handle}:${uuid}`;
  const currentContainer = endorsements.countByContent[key];

  if (isContainerValid(currentContainer, app.blockNumber)) return currentContainer;
  if (counters[key]) return counters[key];

  const container = createContainer(app.blockNumber, 0);
  const encodedHandle = ethers.utils.formatBytes32String(handle);

  counters[key] = (async () => {
    await dispatch({ type: COUNT_BY_CONTENT_BEGIN, payload: { key, container } });
    const count = await EndorsementsService.getInstance().contract.getEndorsementCountByContent(
      encodedHandle,
      uuid
    );

    const loadedContainer = fillContainer(container, count.toNumber());
    await dispatch({ type: COUNT_BY_CONTENT_END, payload: { key, container: loadedContainer } });
    return loadedContainer;
  })();

  return counters[key];
};

export const listEndorsementsByContent = (
  handle: string,
  uuid: string,
  limit: number = undefined
): Action => async (dispatch, getState) => {
  const { value: count }: Container<number> = await dispatch(
    countEndorsementsByContent(handle, uuid)
  );
  const listLength = limit ? Math.min(count, limit) : count;

  const { app, endorsements } = getState();
  const key = `${handle}:${uuid}`;
  const currentContainer = endorsements.listByContent[key];

  const isLimitOk = !!currentContainer && (currentContainer.limit ? currentContainer.limit >= limit : !limit);

  if (isLimitOk && isContainerValid(currentContainer, app.blockNumber)) return currentContainer;
  if (isLimitOk && listers[key]) return listers[key];

  const container: ListContainer<Endorsement[]> = {
    ...createContainer(app.blockNumber, []),
    limit,
  };
  const encodedHandle = ethers.utils.formatBytes32String(handle);

  listers[key] = (async () => {
    await dispatch({ type: LIST_BY_CONTENT_BEGIN, payload: { key, container } });
    const list: Endorsement[] = await EndorsementsService.getInstance().loadEndorsementsBy(
      'getRecordIdByContentAt',
      listLength,
      encodedHandle,
      uuid
    );

    const loadedContainer = fillContainer(container, list);
    await dispatch({ type: LIST_BY_CONTENT_END, payload: { key, container: loadedContainer } });
    return loadedContainer;
  })();

  return listers[key];
};

export const refreshEndorsementsByContent = (handle: string, uuid: string): Action => async (
  dispatch,
  getState,
) => {
  const key = `${handle}:${uuid}`;
  const container = getState().endorsements.listByContent[key];

  delete counters[key];
  delete listers[key];
  await dispatch({ type: RESET_BY_CONTENT, payload: { key } });
  if (!container) return;
  await dispatch(listEndorsementsByContent(handle, uuid, container.limit));
}

export const buildEndorse = (
  handle: string,
  uuid: string,
  value: ethers.utils.BigNumber
): Action => async (dispatch, getState) =>
  dispatch(
    trackTaskTransaction(EndorsementsService.getInstance().endorse(handle, uuid, value), {
      type: 'ENDORSE_CONTENT',
      path: `/@${handle}/${uuid}`,
    })
  );

const getDefaultState = (): State => ({
  countByContent: {},
  listByContent: {},
  countByEndorser: {},
  listByEndorser: {},
  countByEndorsee: {},
  listByEndorsee: {},
});

export default function reducer(state: State = getDefaultState(), action: any): State {
  switch (action.type) {
    case RESET_BY_CONTENT:
       return {
        ...state,
        countByContent: {
          ...state.countByContent,
          [action.payload.key]: undefined,
        },
        listByContent: {
          ...state.listByContent,
          [action.payload.key]: undefined,
        },
      };
    case COUNT_BY_CONTENT_BEGIN:
    case COUNT_BY_CONTENT_END:
      return {
        ...state,
        countByContent: {
          ...state.countByContent,
          [action.payload.key]: action.payload.container,
        },
      };
    case LIST_BY_CONTENT_BEGIN:
    case LIST_BY_CONTENT_END:
      return {
        ...state,
        listByContent: {
          ...state.listByContent,
          [action.payload.key]: action.payload.container,
        },
      };
    default:
      return state;
  }
}

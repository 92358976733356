import axios from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';

import { State as AppState } from 'stores';
import * as clipboard from 'services/clipboard';
import * as localStorage from 'services/local-storage';

import Button from 'components/button';
import OnboardingMenu from 'components/onboarding-menu';
import SVGBadgeAppStore from 'components/svg-badge-app-store';
import SVGBadgeGooglePlay from 'components/svg-badge-google-play';
import Dialog from 'components/dialog';

type Props = { userAddress: string };
type State = {
  isWhyDialogActive: boolean;
  isHowDialogActive: boolean;
};

export class HasNoBalanceStep extends React.PureComponent<Props, State>{

  state = {
    isWhyDialogActive: false,
    isHowDialogActive: false
  }

  onWhyDialogClose = () => {
    this.setState({ isWhyDialogActive: false });
  };

  onWhyDialogOpen = () => {
    this.setState({ isWhyDialogActive: true });
  };

  onHowDialogClose = () => {
    this.setState({ isHowDialogActive: false });
  };

  onHowDialogOpen = () => {
    this.setState({ isHowDialogActive: true });
  };

  renderWhyDialog = () => (
    <Dialog active={this.state.isWhyDialogActive} onBlur={this.onWhyDialogClose}>
      <h3>Decentralization has a cost.</h3>
      <br />
      <p>
        Pheme is built on the <strong>Ethereum</strong> blockchain. A fee is required to incentivize the people securing the network.
      </p>
      <br/>
      <ul>
        <li>
          Registering will require a one time payment of ~<strong>0.0010 ETH</strong>
        </li>
        <br/>
        <li>
          Publishing will always require a payment of ~<strong>0.0005 ETH</strong>
        </li>
      </ul>
      <br/>
      <p>These fees will be collected by the Ethereum Network and not by Pheme. </p>

      <br/>
      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        <Button
          type="outline"
          text="Close"
          iconRight="cross lnr-075x"
          onClick={this.onWhyDialogClose}
        />
        <Link to="/faq">
          <Button type="reveal" text="Learn more" />
        </Link>
      </div>
    </Dialog>
  )

  renderHowDialog = () => (
    <Dialog active={this.state.isHowDialogActive} onBlur={this.onHowDialogClose}>
      <h3>Fund your wallet.</h3>
      <br />
      <p>
      If you haven't done it before, getting your first Ether could be overwhelming.
      Below is your Ethereum address. You can either;
      </p>
      <br/>
      <ul>
        <li>
          <p>Ask a friend to transfer some to you.</p>
        </li>
        <br />
        <li>
          <p>Purchase some from an exchange and transfer yourself. <Link to="/how-to" style={{ color: '#3333BF' }}>Learn more</Link></p>
        </li>
      </ul>
      <br />
      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        <p>
          <strong>Your Ethereum Address</strong>
        </p>
      </div>
      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        <QRCode size={100} value={this.props.userAddress} />
      </div>
      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        {/* TODO Make this a Copy button. */}
        <p><small><strong>{this.props.userAddress}</strong></small></p>
      </div>
      <br/>

      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
        <Button
          type="outline"
          text="Close"
          iconRight="cross lnr-075x"
          onClick={this.onHowDialogClose}
        />
      </div>
    </Dialog>
  )

  render() {
    const { userAddress } = this.props;

    return (
      <div className="onboarding">

        <OnboardingMenu active={3} />

        <div className="onboarding--message">

          <p className="centre"><big>
            {/* TODO Register + Publish costs ~ 300,000 gas. This is the price when gas is 5 Gwei. Could be nice if we can connect to ethgasstation.info's API and give a real time conversion */}
            Fund your account with at least <strong>0.0015 ETH</strong> to register and to publish your first story.
          </big></p>

          <br/>

          <div style={{display:'flex'}} className="centre">
            <Button type="white" text="How?" onClick={this.onHowDialogOpen}/>
            <Button type="white-outline" text="Why?" onClick={this.onWhyDialogOpen}/>
          </div>

          {this.renderWhyDialog()}
          {this.renderHowDialog()}

        </div>

      </div>
    );
  }
};

const mapStateToProps = (state: AppState): Partial<Props> => ({
  userAddress: state.user.address
});

export default connect(mapStateToProps)(HasNoBalanceStep);

import * as ethers from 'ethers';

function avatarIndexForAddress(address){
  const addressAsNumber = ethers.utils.bigNumberify(address);
  return addressAsNumber.mod(icons.length).toNumber();
}

export function getAnonymousIcon(address){
  const i = avatarIndexForAddress(address)
  return icons[i].lnr;
}

export function getAnonymousName(address){
  const i = avatarIndexForAddress(address)
  return icons[i].title;
}

const icons =  [
  {
    lnr: "couch",
    title: "Couch"
  },
  {
    lnr: "paint-roller",
    title: "Paint Roller"
  },
  {
    lnr: "bucket",
    title: "Bucket"
  },
  {
    lnr: "magnet",
    title: "Magnet"
  },
  {
    lnr: "umbrella",
    title: "Umbrella"
  },
  {
    lnr: "shovel",
    title: "Shovel"
  },
  {
    lnr: "joystick",
    title: "Joystick"
  },
  {
    lnr: "binoculars",
    title: "Binoculars"
  },
  {
    lnr: "floppy-disk",
    title: "Floppy Disk"
  },
  {
    lnr: "trophy",
    title: "Trophy"
  },
  {
    lnr: "trumpet",
    title: "Trumpet"
  },
  {
    lnr: "socks",
    title: "Sock"
  },
  {
    lnr: "bow-tie",
    title: "Bow Tie"
  },
  {
    lnr: "pants",
    title: "Pants"
  },
  {
    lnr: "road-sign",
    title: "Road Sign"
  },
  {
    lnr: "lampshade",
    title: "Lampshade"
  },
  {
    lnr: "chip-x64",
    title: "Chip"
  },
  {
    lnr: "beaker",
    title: "Beaker"
  },
  {
    lnr: "bandage",
    title: "Bandage"
  },
  {
    lnr: "construction-cone",
    title: "Construction Cone"
  },
  {
    lnr: "teapot",
    title: "Teapot"
  },
  {
    lnr: "sausage",
    title: "Sausage"
  },
  {
    lnr: "cactus",
    title: "Cactus"
  },
  {
    lnr: "paw",
    title: "Paw"
  }
];

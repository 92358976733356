import * as React from 'react';
import * as ethers from 'ethers';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import './styles.scss';

import Dialog from 'components/dialog';
import Button from 'components/button';

interface Props {
  action?: string;
  gasCost?: string;
  children?: React.ReactNode;
  onDark?: boolean;
  type?: string;
  isEstimating?: boolean;
  value?: number;
  serviceRate?: number;
}

interface State {
  dialogActive: boolean;
}

export default class InfoBox extends React.PureComponent<Props, State> {
  static defaultProps = {
    onDark: false,
    value: 0,
    serviceRate: 0,
  };

  state = {
    dialogActive: false,
  };

  onDialogClose = () => {
    this.setState({ dialogActive: false });
  }

  onDialogOpen = () => {
    this.setState({ dialogActive: true });
  }

  formatEthers = (value: number, precision = 4) => {
    const min = 1 / Math.pow(10, precision);
    if (value < min) {
      return `Less than ${min}`;
    } else {
      return value.toFixed(precision);
    }
  }

  render() {
    const { action, gasCost, children, isEstimating, onDark, value, serviceRate } = this.props;
    const { dialogActive } = this.state;
    const whyButtonType = onDark === true ? 'white-outline' : 'outline';

    const minerFee = Number(ethers.utils.formatEther(gasCost));
    const totalCost = minerFee + value;
    const serviceFee = value * serviceRate;
    const sentValue = value - serviceFee;

    return (
      <div
        className={classnames('info-box', {
          ['info-box--' + this.props.type]: this.props.type,
          'info-box--isEstimating': isEstimating,
        })}
      >
        <i className="lnr lnr-cloud-sync lnr-2x" style={{ marginBottom: '0.5rem' }} />
        <div style={{ flex: 1 }}>
          <p className="info-box__message-calculating">Hang on, we're crunching some numbers...</p>
          <p className="info-box__message-ready">
            {action} is estimated to cost{' '}
            <strong>
              {this.formatEthers(totalCost)} ETH
            </strong>
            . It might take up to a minute to process
            your request.
          </p>
          {children}
        </div>
        <div style={{ alignSelf: 'flex-end' }}>
          <Button size="small" type={whyButtonType} text="Why?" onClick={this.onDialogOpen} />
        </div>

        <Dialog active={dialogActive} onBlur={this.onDialogClose}>
          <h3>Let’s talk about the magic of web3.</h3>
          <br />
          <p>
            Your action will be verified and securely stored on the <strong>Ethereum</strong>{' '}
            blockchain. It is estimated that;
          </p>
          <ul>
            {value > 0 && (
              <React.Fragment>
                {sentValue > 0 ? (
                  <li>
                    <p>
                      <strong>
                        {this.formatEthers(sentValue)} ETH
                      </strong>{' '}
                      will be gifted to the publisher.
                    </p>
                  </li>
                ) : null}
                {serviceFee > 0 ? (
                  <li>
                    <p>
                      <strong>
                        {this.formatEthers(serviceFee)} ETH
                      </strong>{' '}
                      will be collected by Pheme.
                    </p>
                  </li>
                ) : null}
              </React.Fragment>
            )}
            <li>
              <p>
                <strong>
                  {this.formatEthers(minerFee)} ETH
                </strong>{' '}
                will be sent to the people
                securing the network.
              </p>
            </li>
          </ul>
          <p>
            This network receives thousands of requests per second! Your request will be processed
            as soon as possible. (estimated <strong>15-60</strong> seconds)
          </p>
          <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
            <Button
              type="outline"
              text="Close"
              iconRight="cross lnr-075x"
              onClick={this.onDialogClose}
            />
            <Link to="/faq">
              <Button type="reveal" text="Learn more" />
            </Link>
          </div>
        </Dialog>
      </div>
    );
  }
}

import * as React from 'react';
import classnames from 'classnames';

type State = { isActive: boolean };
type Props = {
  children: (props: { isActive?: boolean, onToggle?: any }) => React.ReactNode,
  deactivateOnBlur?: boolean
};

export default class Toggler extends React.PureComponent<Props, State> {
  static defaultProps = {
    deactivateOnBlur: true,
    style: {},
  };

  wrapper: HTMLDivElement;
  state = { isActive: false };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (!this.props.deactivateOnBlur) return;
    if (this.wrapper && !this.wrapper.contains(event.target)) {
      this.setState({ isActive: false });
    }
  }

  onToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  }

  render() {
    const { onToggle } = this;
    const { children } = this.props;
    const { isActive } = this.state;

    return <div ref={(wrapper) => this.wrapper = wrapper}>
      {children({ isActive, onToggle })}
    </div>;
  }
}
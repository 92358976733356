import * as React from 'react';
import classnames from 'classnames';
import './styles.scss';

type Props = {
  children: React.ReactNode,
}

export const Dropdown = (props: Props) => {
  return (
    <div className="dropdown">
      <ul>
      	{props.children}
      </ul>
    </div>
  );
};

export default Dropdown;

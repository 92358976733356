export default function debounce(func: Function, wait: number) {
  let timeout;
  const reset = () => clearTimeout(timeout);

  function debouncer(...args) {
    return new Promise((resolve) => {
      reset();
      const callback = () => resolve(func(...args));
      timeout = setTimeout(callback, wait);
    })
  }

  return debouncer;
}

import * as React from 'react';

export const SVGIconDapps = () => {

  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 24 24" width="20px" height="20px" fill="currentColor" style={{ verticalAlign: 'middle' }}>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group" fill="#D8D8D8">
                <rect id="Rectangle" x="0" y="0" width="10" height="10" rx="2"></rect>
                <rect id="Rectangle" x="12" y="0" width="10" height="10" rx="2"></rect>
                <rect id="Rectangle" x="0" y="12" width="10" height="10" rx="2"></rect>
                <rect id="Rectangle" x="12" y="12" width="10" height="10" rx="2"></rect>
            </g>
        </g>
    </svg>
  );
};

export default SVGIconDapps;

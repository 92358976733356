import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';

import { State as AppState } from 'stores';
import { HandleDetails, PostDetails, loadPost, buildUnpublishPost, cachedHandleSelector, cachedPostSelector } from 'stores/content';
import { ITask } from 'services/pheme';
import * as clipboard from 'services/clipboard';

import StoredImage from 'components/stored-image';
import Button from 'components/button';
import Dropdown from 'components/dropdown';
import RelativeTimestamp from 'components/relative-timestamp';
import Footer from 'components/footer';
import SceneLoader from 'components/scene-loader'
import PostDisplay from 'components/post-display';

import StartStep from './generic/start';
import MissingWeb3Step from './generic/missing-web3';
import DoneStep from './generic/done';
import HasNoBalanceStepROPSTEN from './ropsten/has-no-balance';
import WrongNetworkStepROPSTEN from './ropsten/wrong-network';
import HasNoBalanceStep from './mainnet/has-no-balance';
import WrongNetworkStep from './mainnet/wrong-network';

import './styles.scss';

type Props = RouteComponentProps<{ step: string }> & { step: string };

export function OnboardingScene({ step }: Props) {
  switch (step.toUpperCase()) {
    case 'MISSING_WEB3':
      return <MissingWeb3Step />;
    case 'WRONG_NETWORK':
      return <WrongNetworkStep />;
    case 'WRONG_NETWORK_ROPSTEN':
      return <WrongNetworkStepROPSTEN />;
    case 'HAS_NO_BALANCE':
      return <HasNoBalanceStep />;
    case 'HAS_NO_BALANCE_ROPSTEN':
      return <HasNoBalanceStepROPSTEN />;
    case 'DONE':
      return <DoneStep />;
    case 'START':
      return <StartStep />;
    case 'BOOTING':
      return <div>BOOTING</div>;
  }
}

const mapStateToProps = (state: AppState, props: Props) => {
  const { step = state.user.status } = props.match.params;
  return { step };
}

export default connect(mapStateToProps)(OnboardingScene);

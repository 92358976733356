export function copy(text: string): Promise<void> {
  const { clipboard } = navigator as any;
  if (clipboard) return clipboard.writeText(text);

  return new Promise((resolve, reject) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      if(document.execCommand('copy')) {
        resolve();
      } else {
        reject();
      }
    } catch (err) {
      reject();
    }

    document.body.removeChild(textArea);
  });
}
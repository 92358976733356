import * as React from 'react';

import PhemeService from 'services/pheme';
import ImageProcessorService, { ImageProcessor } from 'services/image-processor';
import FileInput, { FileInputStatus } from 'components/file-input';

import StoredImage from 'components/stored-image';

type ImageInputStatus = FileInputStatus | 'PROCESSING' | 'PROCESSED';

type ImageVersionDefinition = {
  method: 'FIT' | 'CROP',
  width?: number,
  height?: number,
};

export type Versions = { [name: string]: ImageVersionDefinition };

export type Image = { original: string, [name: string]: string };

type Props = {
  onImageReady?: (Image) => void,
  onStatusChange?: (status: ImageInputStatus) => void,
  versions: Versions,
};

type State = {
  status: ImageInputStatus,
  processor: ImageProcessor,
  image: Image,
};

function readFile(file: Blob | File): Promise<Buffer> {
  return new Promise(resolve => {
    const fileReader = new FileReader();

    fileReader.onloadend = (event) => resolve(Buffer.from((event.target as any).result));

    fileReader.readAsArrayBuffer(file);
  });
}

export default class ImageInput extends React.PureComponent<Props, State> {
  state = { status: 'IDLE' as ImageInputStatus, processor: undefined, image: undefined };

  static defaultProps = { versions: {} };

  private changeStatus(status: ImageInputStatus) {
    const { onStatusChange } = this.props;
    this.setState({ status });
    if (onStatusChange) onStatusChange(status);
  }

  onFileReady = async (url: string, file: File) => {
    const { onImageReady, versions } = this.props;
    this.setState({ status: 'PROCESSING' });
    const processor = await ImageProcessorService.process(file);

    const versionNames = Object.keys(versions);

    const results = await Promise.all(versionNames.map(async versionName => {
      const versionDefinition = versions[versionName];
      const { width, height, method } = versionDefinition;
      const options = { width, height };
      const data = await (method === 'CROP' ? processor.crop(options) : processor.fit(options));

      if (!data) return url;
      return PhemeService.getInstance().storage.writeData(await readFile(data));
    }));

    const image: Image = results.reduce((acc, result, index) => {
      const name = versionNames[index];
      return { ...acc, [name]: result };
    }, { original: url });

    if (onImageReady) onImageReady(image);
    this.setState({ status: 'PROCESSED', image });
  }

  onFileStatusChange = (status: FileInputStatus) => {
    this.changeStatus(status);
  }

  render() {
    return (
      <div className={`imageInput imageInput--${this.state.status.toLowerCase()}`}>
        <FileInput
          onStatusChange={this.onFileStatusChange}
          onFileReady={this.onFileReady}
        />
      </div>
    );
  }
}
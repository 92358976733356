import * as React from 'react';

import StoredImage from 'components/stored-image';
import ImageInput, { Image, Versions } from 'components/image-input';
import LoadingIcon from 'components/loading-icon';
import classnames from 'classnames';

type Props = {
  onReady?: (image: Image) => void,
  onRemove?: () => void,
  label?: string,
  versions: Versions,
  image: Image,
  isImagePost?: boolean,
};

type State = {
  image: Image;
};

export default class ImageUploader extends React.PureComponent<Props, State> {
  state = { image: undefined };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    return { ...prevState, image: nextProps.image };
  }

  onReady = (image: Image) => {
    const { onReady } = this.props;
    this.setState({ image });
    if (onReady) onReady(image);
  };

  onRemove = () => {
    const { onRemove } = this.props;
    this.setState({ image: undefined });
    if (onRemove) onRemove();
  };

  render() {
    const { versions, label, isImagePost } = this.props;
    const { image } = this.state;

    return (
      <React.Fragment>
        <ImageInput versions={versions} onImageReady={this.onReady}/>
        <div className={classnames('indicator', {
          full: isImagePost 
        })}>
          {image ? (
            <React.Fragment>
              <div className="preview-image"><StoredImage address={image.large || image.original} /></div>
              <div className="controls controls--overlay">
                <button className="button button--faded-white" style={{ pointerEvents: 'none' }}>Change</button>
                <button onClick={this.onRemove} className="button button--square button--faded-white">
                  <i className="lnr lnr-cross lnr-15x"></i>
                </button>
              </div>
            </React.Fragment>
          ) : (
            <div className="controls controls--empty">
              <button className="button button--outline" style={{ pointerEvents: 'none' }}>{label || 'Add image'}</button>
            </div>
          )}
          <div className="uploading message message--small message--white isActive">
            Uploading&nbsp;&nbsp;<LoadingIcon />
          </div>
          <div className="processing message message--small message--white isActive">
            Processing&nbsp;&nbsp;<LoadingIcon />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

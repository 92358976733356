import * as ConfigServive from 'services/config';
import * as ethers from 'ethers';

import Pheme, { IBlock } from '@pheme-kit/core/lib';
import PhemeRegistry from '@pheme-kit/core/lib/registry';
export { ITask, modifyTask } from '@pheme-kit/core/lib/task';
import PhemeStorageIpfs from '@pheme-kit/storage-ipfs';

import Web3Service from 'services/web3';

export default class PhemeService {
  static instance: Pheme<PhemeRegistry>;
  static registry: PhemeRegistry;

  static getInstance() {
    if (!this.instance) throw new Error('Not initialized yet.');
    return this.instance;
  }

  static getRegistry() {
    if (!this.registry) throw new Error('Not initialized yet.');
    return this.registry;
  }

  static async initialize(
    web3: Web3Service,
    ipfsRpcUrl: string,
    ipfsGatewayUrl: string
  ): Promise<Pheme<PhemeRegistry>> {
    const networkId = `${process.env.NETWORK_ID}`;
    const { registryAddress } = (ConfigServive.getState()[networkId] || {}) as any;

    if (!registryAddress) throw new Error('Registry contract not available.');
    const contractProvider = web3.isReadOnly ? web3.provider : web3.provider.getSigner();

    this.registry = PhemeRegistry.attach(registryAddress, contractProvider);
    this.instance = new Pheme(this.registry, {
      ipfs: new PhemeStorageIpfs(ipfsRpcUrl, ipfsGatewayUrl),
    });

    return this.instance;
  }
}

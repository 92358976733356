import * as React from 'react';
import makeCancelable from 'lib/make-cancelable';
import { ITask } from '@pheme-kit/core/lib/task';
import * as ethers from 'ethers';

export type TaskEstimationStatus = 'IDLE' | 'ESTIMATING' | 'READY';
interface State { gasCost: ethers.utils.BigNumber; status: TaskEstimationStatus }
interface Props { task: ITask<any>; render: (State) => React.ReactNode }

export default class WithTaskEstimation extends React.PureComponent<Props, State> {
  state = {
    gasCost: ethers.constants.Zero,
    status: 'IDLE' as TaskEstimationStatus,
  };

  resetEstimation: () => void;

  componentDidMount() {
    this.onTaskChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.task !== this.props.task) this.onTaskChange();
  }

  componentWillUnmount() {
    const { resetEstimation } = this;
    if (resetEstimation) resetEstimation();
  }

  async onTaskChange() {
    const { task } = this.props;
    if (!task) return;
    this.setState({ status: 'ESTIMATING' });
    const { promise, cancel } = makeCancelable(task.estimate());
    this.resetEstimation = cancel;
    const gasCost = await promise;
    if (this.props.task === task) this.setState({ gasCost, status: 'READY' });
  }

  render() {
    const { render } = this.props;
    return render(this.state);
  }
}

import * as React from 'react';
import classnames from 'classnames';

import { withRouter, Route, Switch, RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { State as AppState } from 'stores';

import LandingScene from 'scenes/landing';
import OnboardingScene from 'scenes/onboarding';

import NotFoundScene from 'scenes/not-found';

import HandlesNewScene from 'scenes/handles/new';
import HandlesShowScene from 'scenes/handles/show';
import HandlesEditScene from 'scenes/handles/edit';
import PostsShowScene from 'scenes/posts/show';
import PostsNewScene from 'scenes/posts/new';
import PostsEditScene from 'scenes/posts/edit';

import PageFaqScene from 'scenes/pages/faq';
import PageHowToScene from 'scenes/pages/how-to';
import PageTermsScene from 'scenes/pages/terms';
import PagePrivacyScene from 'scenes/pages/privacy';

import SVGLoader from 'components/loading-icon';
import MessageBar from 'components/message-bar';
import Toaster from 'components/toaster';
import Header from 'components/header';
import Section from 'components/section';
import PageStateMessage from 'components/page-state-message';

type Props = RouteComponentProps & {
  status: string,
  isReadOnly: boolean,
};

const IsBooting = (props: Props) => (
  <Section type="loader">
    <SVGLoader />
    <br/><br/>
    <div className="message isActive">
      Pheme is booting...
    </div>
  </Section>
);

class IsReady extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <PageStateMessage {...this.props} />

        <Switch>
          <Route exact path="/" component={LandingScene} />

          <Route exact path="/create-alias" component={HandlesNewScene} />
          <Route exact path="/@:handle" component={HandlesShowScene} />
          <Route exact path="/@:handle/edit" component={HandlesEditScene} />
          <Route path="/@:handle/:contentType/new" component={PostsNewScene} />
          <Route path="/@:handle/:contentType/:uuid/edit" component={PostsEditScene} />
          <Route path="/@:handle/:uuid" component={PostsShowScene} />

          <Route exact path="/faq" component={PageFaqScene} />
          <Route exact path="/how-to" component={PageHowToScene} />
          <Route exact path="/terms" component={PageTermsScene} />
          <Route exact path="/privacy" component={PagePrivacyScene} />

          <Route exact path="/onboarding" component={OnboardingScene} />
          <Route exact path="/onboarding/:step" component={OnboardingScene} />

          <Route component={NotFoundScene} />
        </Switch>

        {!this.props.isReadOnly && (
          <React.Fragment>
            <MessageBar />
            <Toaster />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

const IsFailed = (props: Props) => (
  <Section type="loader">
    <i className="lnr lnr-warning lnr-2x"></i>
    <br/><br/>
    <div className="message isActive">
      Pheme initalisation failed.
    </div>
  </Section>
);

const App = (props: Props) => {
  const { status } = props;;
  switch (status) {
    case 'BOOTING':
      return <IsBooting {...props} />;
    case 'READY':
    case 'READY_ROPSTEN':
    case 'NOT_AVAILABLE':
    case 'WRONG_NETWORK':
    case 'WRONG_NETWORK_ROPSTEN':
      return <IsReady {...props} />;
    default:
      return <IsFailed {...props} />;
  }
};

const mapStateToProps = (state: AppState) => ({
  status: state.app.status,
  isReadOnly: state.app.isReadOnly,
  location: state.router.location,
});

export default withRouter(connect(mapStateToProps)(App));

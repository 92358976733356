import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { replace } from 'connected-react-router';

import PhemeService, { ITask, modifyTask } from 'services/pheme';
import { setTitle, resetTitle } from 'services/document-title';

import { State as AppState } from 'stores';
import {
  HandleDetails,
  PostDetails,
  buildReplacePost,
  loadPost,
  cachedHandleSelector,
  cachedPostSelector,
} from 'stores/content';

import PostForm, { FormValues } from 'components/post-form';
import SceneLoader from 'components/scene-loader';

type Props = RouteComponentProps<{ handle: string; uuid: string }> &
  PostDetails & {
    handle: string;
    uuid: string;
    contentType: string;
    isNotAuthorized: boolean;
    isLoading: boolean;
    handleDetails: HandleDetails;
    redirect: typeof replace;
    onLoadPost: typeof loadPost;
    buildReplacePost: (handle: string, uuid: string, post: any) => any;
  };

interface State {}

export class PostsEditScene extends React.PureComponent<Props, State> {
  cancelRedirect: () => void;

  componentDidMount() {
    this.onLoad();
    if (this.props.isNotAuthorized) {
      this.onIntrusion();
      return;
    }
    setTitle('Edit post');
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.isNotAuthorized) {
      this.onIntrusion();
      return;
    }

    if (prevProps.match.params.handle !== this.props.handle) {
      this.onLoad();
    }
  }

  componentWillUnmount() {
    resetTitle();
  }

  onIntrusion() {
    this.props.redirect(`/@${this.props.handle}`);
  }

  onLoad() {
    this.props.onLoadPost(this.props.handle, this.props.uuid);
  }

  onFormPrepare = async (post: FormValues) => {
    const { buildReplacePost, handle, uuid } = this.props;

    const baseTask = await buildReplacePost(handle, uuid, post);

    const task = modifyTask(baseTask, {
      execute: () =>
        baseTask
          .execute()
          .then(([pointer, blocks]) => [pointer, blocks.find((block) => block.uuid === uuid)]),
    });

    return task.estimate().then(() => task);
  }

  render() {
    const { isLoading } = this.props;
    if (isLoading) return <SceneLoader />;
    return (
      <PostForm
        draftKey={`postDraft:${this.props.uuid}`}
        formTitle="Editing your post"
        actionDescription="Editing your post"
        actionVerb="Republish"
        prepare={this.onFormPrepare}
        {...this.props as any}
      />
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => {
  const { handle, uuid, contentType } = props.match.params;

  const handleDetails: HandleDetails = cachedHandleSelector(state.content, props.match.params);
  const postDetails: PostDetails = cachedPostSelector(state.content, props.match.params);

  const isOwner = handleDetails && handleDetails.owner === state.user.address;
  const isNotAuthorized = handleDetails.isCached && postDetails.isCached && !isOwner;

  const isLoading =
    (!handleDetails.isCached && handleDetails.isLoading) ||
    (!postDetails.isCached && postDetails.isLoading);

  return {
    handle,
    uuid,
    contentType,
    isNotAuthorized,
    handleDetails,
    ...postDetails,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildReplacePost,
      onLoadPost: loadPost,
      redirect: replace,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostsEditScene);

import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Section from 'components/section';
import Button from 'components/button';


export default function NotFoundScene() {
  const icons = ['fire', 'toilet-paper', 'broom', 'battery-alert', 'signal-blocked', 'cord'];
  const randomIcon = icons[ (Math.round(Math.random() * 4)) ];

  return (
    <Section type="hero">

      <div className="nav-spacer"></div>

      <i style={{ opacity: 0.7 }} className={'lnr lnr-4x lnr-' + randomIcon}></i>

      <br /><br />

      <h1>Shucks... </h1>
      <p style={{ opacity: 0.7 }}><big>We couldn't find the thing you seem to have been looking for.</big></p>

      <br />

      <Link to="/"><Button type="white" text="Move along now" /></Link>

    </Section>
  );
};
